
import React from "react";
import {Col, Row, Form, Button, Container, InputGroup} from '@themesberg/react-bootstrap';
import {Header} from "../components/Header";
import {useFormik} from "formik";
import * as yup from "yup";
import AsyncAction from "../../AsyncAction";
import classNames from "classnames";
import {connect} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope} from "@fortawesome/free-solid-svg-icons";
import {UserResendVerificationToken} from "../../store/Login";

const validationSchema = yup.object().shape({
    email: yup.string().required("This field is required"),
});

function ResendToken (props) {
    // console.log(location)
    const {UserResendVerificationToken} = props
    //using useFormik
    const formik = useFormik({
        initialValues: {
            email: "",
        },
        validationSchema,
    });
    return (
        <main>
            <Header/>
            <section className="vh-lg-100 mt-lg-0 bg-soft d-flex align-items-center">
                <Container>
                    <Row className="justify-content-center">

                        <Col xs={12} className="d-flex align-items-center justify-content-center">
                            <div className="signin-inner my-3 my-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                                <div className="alert alert-danger" role="alert">
                                    Activation link has been Expired please fill the form below to resend again
                                </div>
                                <h3>Resend Activation Link</h3>
                                <br/>
                                {/*<Form>*/}
                                <div className="mb-4">
                                    <Form.Label htmlFor="email">Your Email</Form.Label>
                                    <InputGroup id="email">
                                        <InputGroup>
                                            <InputGroup.Text>
                                                <FontAwesomeIcon icon={faEnvelope}/>
                                            </InputGroup.Text>
                                            <input
                                                type="email"
                                                className="form-control mb-0"
                                                placeholder="Enter email"
                                                autoComplete="off"
                                                id="email"
                                                name="email"
                                                value={formik.values.email}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        </InputGroup>
                                        {formik.touched.email && formik.errors.email ? (
                                            <div className="text-danger">
                                                {formik.errors.email}
                                            </div>
                                        ) : null}
                                    </InputGroup>
                                </div>
                                <AsyncAction
                                    action={() => UserResendVerificationToken(formik.values.email)}
                                    render={({run, loading}) => (
                                        <Button
                                            type="submit"
                                            data-toggle="tooltip"
                                            data-placement="right"
                                            title="login"
                                            onClick={run}
                                            disabled={!(formik.isValid && formik.dirty)}
                                            className={classNames("w-100", {
                                                "btn-loading": loading,
                                            })}
                                        >
                                           Resend Link
                                        </Button>
                                    )}
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </main>
    );
};
const mapDispatchToProps = {
    UserResendVerificationToken
};

export default connect(() => ({}), mapDispatchToProps)(ResendToken);
