import Axios from "axios";
import {EDIT_STAFF_DATA, STAFF_DATA} from "./staffActionTypes";
import {toast} from "react-toastify";
import {userToken} from "../Login";

export function AddStaff(value) {
    const data = {
        name: value.name,
        username: value.role,
        email: value.email,
        role: value.role,
        is_verified: true,
        is_staff: true,
        password: value.password,
    };
    return async (dispatch) =>
        Axios.post(window.$name + "/auth/create-staff/", data)
            .then(function (response) {
                if (response.data) {
                    toast.success("Staff registration successfully");
                    dispatch(FetchStaffs())
                }
            })
            .catch(function (error) {
                if (error.response.data.errors) {
                    if (error.response.data.errors.name) {
                        toast.error("Full name - " + " " + error.response.data.errors.name[0]);
                    } else if (error.response.data.errors.email) {
                        toast.error("Email - " + " " + error.response.data.errors.email[0]);
                    } else if (error.response.data.errors.password) {
                        toast.error("Password - " + " " + error.response.data.errors.password[0]);
                    } else {
                        toast.error(error.response);
                    }
                } else {
                    toast.error(error.response.data.detail);
                }
            });
}


export function FetchStaffs() {
    return async (dispatch) =>
        Axios.get(window.$name + '/auth/user/list/', {})
            .then(function (response) {
                // console.log("DATA", response.data)
                dispatch({type: STAFF_DATA, payload: response.data});
                // console.log(response.data);
            })
            .catch(function (error) {
                if (error.response) {
                    toast.error(
                        `"${error.response.data}" Error Status  "${error.response.status}" `
                    );
                } else if (error.request) {
                    toast.error(`"${error.request}" `);
                } else {
                    toast.error(`"${error.message}" `);
                }
            });
}

export function EditStaff(value) {
    // console.log(value)
    const data = {
        name: value.name,
        username: value.role,
        email: value.email,
        id: userToken().userId,
        image_url:'',
    };
    return async (dispatch) =>
        Axios.put(window.$name + `/auth/user/${value.id}`, data, {
            headers: {}
        }, {})
            .then(function (response) {
                console.log(response.data)
                if (response.status === 200) {
                    dispatch(FetchStaffs())
                    toast.success("staff Editing ,successfully");
                    dispatch({type: EDIT_STAFF_DATA, payload: response.data})
                }
            })
            .catch(function (error) {
                if (error.response) {
                    toast.error("Something is wrong,Please contact us");
                }
            });
}

export function DeleteStaff(id) {

    return async (dispatch) =>
        Axios.delete(window.$name + `/subscription/staff/detail/${id}`, {
            headers: {},
        }, {})
            .then(function (response) {
                if (response.status === 204) {
                    toast.success("staff Deleting ,successfully");
                    dispatch(FetchStaffs())
                }
            })
            .catch(function (error) {
                if (error.response) {
                    toast.error("Something is wrong,Please contact us");
                }
            });
}