import React, {useEffect, useState} from "react";
import {AddEducationProfile, DeleteEducation} from "../../../store/eductionProfile";
import {connect, useDispatch, useSelector} from "react-redux";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Grid from "@mui/material/Grid";
import StarIcon from "@mui/icons-material/StarBorder";
import Typography from "@mui/material/Typography";
import GlobalStyles from "@mui/material/GlobalStyles";
import Container from "@mui/material/Container";
import {Breadcrumb, Button, Modal} from "@themesberg/react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBook, faHome, faLock} from "@fortawesome/free-solid-svg-icons";
import {FetchStaffs} from "../../../store/staff";
import {FetchPlans} from "../../../store/plan/planActions";
import planReducer from "../../../store/plan/planReducer";
import AsyncAction from "../../../AsyncAction";
import {Logout, userToken} from "../../../store/Login";
import Axios from "axios";
import {toast} from "react-toastify";
import {useHistory} from "react-router-dom";
import {ApplyEvents} from "../../../store/MyEvents";
import Moment from "moment";
import {decode as base64_decode} from "base-64";


function MemberPlan(props) {
    const dispatch = useDispatch();
    // const {ChangePlan} = props;
    useEffect(() => {
        dispatch(FetchPlans());
    }, [dispatch]);
    const [showModel, setShowModel] = useState(false);
    const [role, setRole] = useState(false);
    const handleClose = () => setShowModel(false);
    function updatePlan(value) {
        setRole(value);
        setShowModel(true)
    }
    const history = useHistory();
    const planData = useSelector((state) => state.plan);
    const rolesData = useSelector((state) => state.permission[0]);
    console.log("role id",rolesData);
    console.log("plan id",planData);
    const current = new Date();
    const date = current;
    const formated_date = Moment(date).toISOString()
    const redirectUrl = window.$nameweb + history.location.pathname;
    function ChangePlan(data) {
        const billData = {
            item_id: data.id,
            type: "Plan - "+ data.name,
            amount: data.price,
            status: "Not paid",
            created_by: userToken().userId,
            timestamp:formated_date,
            redirectUrl:redirectUrl,
            key:''
        }
        Axios.post(window.$name + `/payment/create/bill/${userToken().userId}`, billData, {
            headers: {}
        }, {})
            .then(function (response) {
                if (response.status === 201) {
                    window.location.href=base64_decode(response.data[1].data[0].payment_gateway_url);
                }
            })
            .catch(function (error) {
                if (error.response) {
                  if (error.response.status ===401){
                        toast.error(error.response.data);
                    }
                  else {
                      toast.error("Something is wrong,Please contact us");
                  }
                }
            });
    }

    // console.log(planData)
    const tiers = [
        {
            title: "Free",
            price: "0",
            description: [
                "8 books included",
                "1 accounts",
                "Not downloadable",
                "No recover",
            ],
            buttonText: "Sign up for free",
            buttonVariant: "outlined",
        },
        {
            title: "Pro",
            subheader: "Most popular",
            price: "15",
            description: [
                "20 books included",
                "3 accounts",
                "Not downloadable",
                "Recover",
            ],
            buttonText: "Get started",
            buttonVariant: "contained",
        },
        {
            title: "Enterprise",
            price: "30",
            description: [
                "50+ books included",
                "2 accounts",
                "Downloadable",
                "Recover",
            ],
            buttonText: "Contact us",
            buttonVariant: "outlined",
        },
    ];

    return (
        <div>
            <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="d-block mb-4 mb-xl-0">
                    <div>
                        <h4>
                            <FontAwesomeIcon icon={faBook} className="icon icon-xs text-dark w-30"/>
                            Select Plan
                        </h4>
                        <p className="container-fluid">You can Switch any time</p>
                    </div>
                </div>

            </div>
            <div>
                <Grid container spacing={5} alignItems="flex-end">
                    {planData.map((tier) => (
                        // Enterprise card is full width at sm breakpoint
                        <Grid
                            item
                            key={tier.name}
                            xs={12}
                            sm={tier.name === "Trial" ? 12 : 6}
                            md={4}
                        >
                            <Card>
                                <CardHeader
                                    title={tier.name}
                                    subheader=""
                                    titleTypographyProps={{align: "center"}}
                                    action={tier.name === "Practising (3+ years)" ? <StarIcon/> : null}
                                    subheaderTypographyProps={{
                                        align: "center",
                                    }}
                                    sx={{
                                        backgroundColor: (theme) =>
                                            theme.palette.mode === "light"
                                                ? theme.palette.grey[200]
                                                : theme.palette.grey[700],
                                    }}
                                />
                                <CardContent>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "baseline",
                                            mb: 2,
                                        }}
                                    >
                                        <Typography
                                            component="h3"
                                            variant="h4"
                                            color="text.primary"
                                        >
                                            TZS{tier.price}
                                        </Typography>
                                        <Typography variant="h6" color="text.secondary">
                                            /year
                                        </Typography>
                                    </Box>
                                    <ol type="number" className="font-base">
                                        {tier.features.split(',').map((address) => (
                                            <li className="">
                                                {address}
                                            </li>
                                        ))}
                                    </ol>
                                </CardContent>
                                <div className="bg-warning">
                                    {
                                        rolesData.id === tier.role_id ?
                                            <Button className="btn-block " variant="success">
                                                Active Plan
                                            </Button>
                                            :
                                            <Button onClick={() => updatePlan(tier)} className="btn-block"
                                                    variant="warning">
                                                Switch Plan
                                            </Button>
                                    }

                                </div>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </div>
            {/* <!-- Modal confirm logout --> */}
            <Modal
                show={showModel}
                onHide={handleClose}
            >
                <Modal.Body>
                    Are you sure want to change plan this will generate bill. ?
                </Modal.Body>
                <Modal.Footer>
                    <Button size="sm" variant="danger" onClick={handleClose}>
                        Close
                    </Button>
                    {/*<AsyncAction*/}
                    <Button
                        size="sm" variant="success"
                        type="submit"
                        onClick={() => ChangePlan(role)}
                    >
                        Accept
                    </Button>
                    {/*)}*/}
                    {/*/>*/}
                </Modal.Footer>
            </Modal>
        </div>
    )
}

const mapDispatchToProps = {
    AddEducationProfile, DeleteEducation
};

export default connect(() => ({}), mapDispatchToProps)(MemberPlan);
