import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope, faUnlockAlt} from "@fortawesome/free-solid-svg-icons";
import {Button, Card, Col, Container, Form, FormCheck, InputGroup, Row} from '@themesberg/react-bootstrap';
import {Link, useHistory,} from 'react-router-dom';

import {Routes} from "../../routes";
import {Header} from "../components/Header";
import * as yup from "yup";
import {useFormik} from "formik";
import {connect} from "react-redux";
import AsyncAction from "../../AsyncAction";
import classNames from "classnames";
import {CreateAccont} from "../../store/CreaterAccount";
// validation
const validationSchema = yup.object().shape({
    email: yup
        .string()
        .required("This field is required"),

    username: yup
        .string()
        .required("This field is required"),

    name: yup
        .string()
        .required("This field is required"),

    password: yup
        .string()
        .required("This field is required"),
    confirm_password: yup
        .string()
        .required("Please confirm your password")
        .when("password", {
            is: (password) =>
                password && password.length > 0 ? true : false,
            then: yup
                .string()
                .oneOf([yup.ref(" password")], "Password doesn't match"),
        }),
});

function Register(props) {
    const history = useHistory();
    const {CreateAccont} = props
    //using useFormik
    const formik = useFormik({
        initialValues: {
            name: "",
            username: "NA",
            email: "",
            password: ""
        },
        validationSchema,
    });


    return (
        <main>
            <Header></Header>
            <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
                <Container>
                    <Row className="justify-content-center form-bg-image">
                        <Col xs={12} className="d-flex align-items-center justify-content-center">
                            <div
                                className="mb-4 mb-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                                <div className="text-center text-md-center mb-4 mt-md-0">
                                    <h3 className="mb-0">Create an account</h3>
                                </div>
                                <Form.Group id="name" className="mb-4">
                                    <Form.Label>Full Name</Form.Label>
                                    <InputGroup>
                                        <InputGroup.Text>
                                            <FontAwesomeIcon icon={faEnvelope}/>
                                        </InputGroup.Text>
                                        <input
                                            type="text"
                                            className="form-control mb-0"
                                            placeholder="Enter Full name"
                                            id="name"
                                            name="name"
                                            value={formik.values.name}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                    </InputGroup>
                                    {formik.touched.name && formik.errors.name ? (
                                        <div className="text-danger">
                                            {formik.errors.name}
                                        </div>
                                    ) : null}
                                </Form.Group>
                                {/*<Form.Group id="username" className="mb-4">*/}
                                {/*    <Form.Label>Username</Form.Label>*/}
                                {/*    <InputGroup>*/}
                                {/*        <InputGroup.Text>*/}
                                {/*            <FontAwesomeIcon icon={faEnvelope}/>*/}
                                {/*        </InputGroup.Text>*/}
                                {/*        <input*/}
                                {/*            type="text"*/}
                                {/*            className="form-control mb-0"*/}
                                {/*            placeholder="username"*/}
                                {/*            id="username"*/}
                                {/*            name="username"*/}
                                {/*            value={formik.values.username}*/}
                                {/*            onChange={formik.handleChange}*/}
                                {/*            onBlur={formik.handleBlur}*/}
                                {/*        />*/}
                                {/*    </InputGroup>*/}
                                {/*    {formik.touched.username && formik.errors.username ? (*/}
                                {/*        <div className="text-danger">*/}
                                {/*            {formik.errors.username}*/}
                                {/*        </div>*/}
                                {/*    ) : null}*/}
                                {/*</Form.Group>*/}
                                <Form.Group id="email" className="mb-4">
                                    <Form.Label>Your Email</Form.Label>
                                    <InputGroup>
                                        <InputGroup.Text>
                                            <FontAwesomeIcon icon={faEnvelope}/>
                                        </InputGroup.Text>
                                        <input
                                            type="email"
                                            className="form-control mb-0"
                                            placeholder="Enter email"
                                            autoComplete="off"
                                            id="email"
                                            name="email"
                                            value={formik.values.email}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                    </InputGroup>
                                    {formik.touched.email && formik.errors.email ? (
                                        <div className="text-danger">
                                            {formik.errors.email}
                                        </div>
                                    ) : null}
                                </Form.Group>
                                <Form.Group id="password" className="mb-4">
                                    <Form.Label>Your Password</Form.Label>
                                    <InputGroup>
                                        <InputGroup.Text>
                                            <FontAwesomeIcon icon={faUnlockAlt}/>
                                        </InputGroup.Text>
                                        <input
                                            type="password"
                                            className="form-control mb-0"
                                            placeholder="Password"
                                            id="password"
                                            name="password"
                                            value={formik.values.password}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                    </InputGroup>
                                    {formik.touched.password && formik.errors.password ? (
                                        <div className="text-danger">
                                            {formik.errors.password}
                                        </div>
                                    ) : null}
                                </Form.Group>
                                <Form.Group id="confirm_password" className="mb-4">
                                    <Form.Label>Confirm Password</Form.Label>
                                    <InputGroup>
                                        <InputGroup.Text>
                                            <FontAwesomeIcon icon={faUnlockAlt}/>
                                        </InputGroup.Text>
                                        <input
                                            type="password"
                                            className="form-control mb-0"
                                            placeholder="Confirm password"
                                            id="confirm_password"
                                            name="confirm_password"
                                            value={formik.values.confirm_password}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                    </InputGroup>
                                    {formik.touched.confirm_password && formik.errors.confirm_password ? (
                                        <div className="text-danger">
                                            {formik.errors.confirm_password}
                                        </div>
                                    ) : null}
                                </Form.Group>
                                <AsyncAction
                                    action={() => CreateAccont(formik.values, history)}
                                    render={({run, loading}) => (
                                        <Button
                                            type="submit"
                                            data-toggle="tooltip"
                                            data-placement="right"
                                            title="sign-in"
                                            variant="primary"
                                            onClick={run}
                                            disabled={!(formik.isValid && formik.dirty)}
                                            className={classNames("w-100", {
                                                "btn-loading": loading,
                                            })}
                                        >
                                            Sign up
                                        </Button>
                                    )}
                                />
                                <div className="d-flex mt-4">
                                   <span className="fw-normal">
                                    Already have an account?
                                     <Card.Link as={Link} to={Routes.Signin.path} className="fw-bold">
                                        {` Login here `}
                                          </Card.Link>
                                      </span>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </main>
    );
}

const mapDispatchToProps = {
    CreateAccont
};

export default connect(() => ({}), mapDispatchToProps)(Register);



