const Relations = [
    {name: "Father"}, {name: "Mother"}, {name: "Brother"}, {name: "Sister"},
    {name: "Uncle"}, {name: "Aunt"}, {name: "Grand daughter"}, {name: "Grand mother"}, {name: "Grand father"},
    {name: "Niece"}, {name: "Daughter"}, {name: "Son"}, {name: "Wife"}, {name: "Husband"},
    {name: "Sister"}, {name: "Friend"}, {name: "Other"}
]
const Gender = [
    {name: "Male"}, {name: "Female"}
]


export {
    Relations,
    Gender
};