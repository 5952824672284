import {EDIT_TRAINING_DATA, TRAINING_DATA,APPLY_TRAINING_DATA} from "./trainingActionTypes";

export default function trainingReducer(state = [], action) {
    switch (action.type) {
        case TRAINING_DATA:
            return action.payload;
        case EDIT_TRAINING_DATA:
            return action.payload;
        default:
            return state;
    }
}
