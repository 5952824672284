import {combineReducers} from "redux";
// reducers
import LoginReducer from "./Login";
import userProfilesReducer from "./personalProfile";
import educationProfileReducer from "./eductionProfile";
import kinReducer from "./Kin";
import workReducer from "./workProfile";
import userReducer from "./AccountProfiles";
import rolesReducer from "./roles";
import planReducer from "./plan/planReducer";
import staffReducer from "./staff";
import permissionReducer from "./permissions";
import LoaderReducer from "./loader/LoaderReducer";
import receiptReducer from "./Receipt";
import websiteReducer from "./website";
import eventReducer from "./Events";
import trainingReducer from "./Training";
import mytrainingReducer from "./MyTraining";
import billReducer from "./Bills";
import myEventReducer from "./MyEvents";
import subscriptionReducer from "./subscription";
import dashboardReducer from "./dashboard";
import speakerReducer from "./Speaker";
import claimPointsReducer from "./claimPoints";
import clePointsReducer from "./ClePoints";
import approvalProfilesReducer from "./ApprovalProfile";
import clePointsTotalReducer from "./cleTotal";
import billReceiptReducer from "./BillReceipt";
import billStatusReducer from "./BillStatus";
import cashReducer from "./CashPayment";
import emailReducer from "./Email";

export default combineReducers({
    receipt: receiptReducer,
    website: websiteReducer,
    event: eventReducer,
    training: trainingReducer,
    Mytraining: mytrainingReducer,
    MyEvent: myEventReducer,
    Bill: billReducer,
    auth: LoginReducer,
    isLoading: LoaderReducer,
    userData: userReducer,
    personalProfile: userProfilesReducer,
    educationProfile: educationProfileReducer,
    NextKin: kinReducer,
    WorkProfile: workReducer,
    roles: rolesReducer,
    plan: planReducer,
    subscription: subscriptionReducer,
    staff: staffReducer,
    permission: permissionReducer,
    statistics: dashboardReducer,
    speakers: speakerReducer,
    points: claimPointsReducer,
    clePoints: clePointsReducer,
    approvalRequest: approvalProfilesReducer,
    cleTotal: clePointsTotalReducer,
    billReceipt: billReceiptReducer,
    billStatus: billStatusReducer,
    cashPayment: cashReducer,
    email:emailReducer
});
