import React, {useEffect, useState} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCog, faHome, faSearch } from '@fortawesome/free-solid-svg-icons';
import {
    Col,
    Row,
    Form,
    Button,
    ButtonGroup,
    Breadcrumb,
    InputGroup,
    Dropdown,
    Card,
    Table
} from '@themesberg/react-bootstrap';
import {Logout} from "../../../store/Login";
import {FetchPersonalProfiles} from "../../../store/personalProfile";
import {connect, useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import Moment from "moment";
import {FetchSpeaker, FetchSpeakerById} from "../../../store/Speaker";
import SpeakerTable from "./speakerTable";


function SpeakersAssigned() {
    const dispatch =useDispatch();

    useEffect(() => {
        dispatch(FetchSpeakerById());

    }, [dispatch]);

    const speakerData = useSelector((state) => state.speakers);

    return (
       <>
       <SpeakerTable speakerData={speakerData} title="Event speaker" />
       </>
    );
};

const mapDispatchToProps = {
    Logout, FetchPersonalProfiles
};
export default connect(() => ({}), mapDispatchToProps)(SpeakersAssigned);





