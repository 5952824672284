import React, {useEffect, useState, Fragment} from "react"
import {
    Breadcrumb,
    Button,
    ButtonGroup,
    Card,
    Col,
    Form,
    InputGroup,
    Modal,
    Row,
    Table
} from "@themesberg/react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faAddressBook,
    faCalendarAlt,
    faEdit,
    faHome, faPeopleCarry,
    faPrint,
    faSearch,
    faTrash, faUser, faUsers
} from "@fortawesome/free-solid-svg-icons";
import {connect, useDispatch, useSelector} from "react-redux";
import AsyncAction from "../../../AsyncAction";
import {DeleteStaff, FetchStaffs} from "../../../store/staff";
import AddStaffForm from "./AddStaffForm";
import Profile1 from "../../../assets/img/logo/logo3.png";
import Moment from "moment";
import {Link} from "react-router-dom";

function Staff(props) {
    const {DeleteStaff} = props
    const dispatch = useDispatch()
    const [Show, setShow] = useState(false);
    const [Value, setId] = useState(0);
    const handleClose = () => setShow(false);
    const handleShow = (value) => {
        if (Show) {
            setShow(false);
        } else {
            setShow(true);
            setId(value)

        }
    };
    useEffect(() => {
        dispatch(FetchStaffs());

    }, [dispatch]);

    const StaffData = useSelector((state) => state.staff);


    //Search Filter Data
    const [searchQuery, setSearchQuery] = useState("")

    function search() {
        return StaffData.filter(row => row.name.toLowerCase().indexOf(searchQuery) > -1)

    }

    return (
        <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <Breadcrumb className="d-none d-md-inline-block"
                            listProps={{className: "breadcrumb-dark breadcrumb-transparent"}}>
                    <Breadcrumb.Item><FontAwesomeIcon icon={faHome}/></Breadcrumb.Item>
                    <Breadcrumb.Item>Settings</Breadcrumb.Item>
                    <Breadcrumb.Item active>Staff</Breadcrumb.Item>
                </Breadcrumb>
                <div className="btn-toolbar mb-2 mb-md-0">
                    <ButtonGroup>
                        <Button size="sm" variant="dark" onClick={() => handleShow(0)}>
                            Add Staffs
                        </Button>
                        <Button variant="outline-primary" size="sm">Excel</Button>
                    </ButtonGroup>
                </div>
            </div>

            <div className="table-settings mb-4">
                <Row className="justify-content-between align-items-center">
                    <Col xs={6} md={6} xl={4} className="ps-md-0">
                        <h4>
                            <FontAwesomeIcon icon={faUser} className="icon icon-xs text-dark w-30"/>
                            Member Staff
                        </h4>
                    </Col>
                    <Col xs={6} md={6} lg={3} xl={4}>
                        <InputGroup>
                            <InputGroup.Text>
                                <FontAwesomeIcon icon={faSearch}/>
                            </InputGroup.Text>
                            <Form.Control
                                type="text"
                                placeholder="Search"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />
                        </InputGroup>
                    </Col>
                </Row>
            </div>

            <Card border="light" className="shadow-sm mb-4">
                <Card.Body className="pb-0">
                    <Table responsive className="table-centered  table-nowrap rounded mb-0">
                        <thead className="thead-light">
                        <tr>
                            <th className="border-0">#</th>
                            <th className="border-0">PHOTO</th>
                            <th className="border-0">FULL NAME</th>
                            <th className="border-0">EMAIL</th>
                            <th className="border-0">TYPE</th>
                            <th className="border-0"> STATUS</th>
                            <th className="border-0"> ROLE(PERMISSION FOR THIS STAFF)</th>
                            <th className="border-0">CREATED AT</th>
                            <th className="border-0 text-center">ACTION</th>
                        </tr>
                        </thead>
                        <tbody>
                        {search(StaffData).map((post, key) => (
                            <tr>
                                <td>
                                    {key + 1}
                                </td>
                                <td>  {post.image_url != null ?

                                    <Card.Img src={post.image_url} alt="Neil Portrait"/>
                                    :
                                    <img src={Profile1} alt="avatar"/>

                                }</td>
                                <td className="text-uppercase">
                                    <Link to={`/member/bills/` + post.id + `/` + post.name}
                                          className="text-blue fw-bold">
                                        {post.name}
                                    </Link>
                                </td>

                                <td> {post.email}</td>
                                <td>
                                    {post.is_staff ?
                                        <span className="badge-md badge bg-dark">Staff</span>
                                        :
                                        <span className="badge-md badge bg-primary">Account</span>
                                    }
                                </td>
                                <td>
                                    {post.is_verified ?
                                        <span className="badge-md badge bg-success">Activated</span>
                                        :
                                        <span className="badge-md badge bg-warning">Not Activated</span>
                                    }
                                </td>
                                <td>{post.username}</td>
                                <td> {Moment(post.created_at).format('DD-MM-YYYY')}</td>
                                <td>
                                    <Button size="sm" onClick={() => handleShow(post)} variant="outline-primary">
                                        <FontAwesomeIcon icon={faEdit}
                                                         className="icon icon-xs text-dark w-30"/>Edit</Button>

                                    &nbsp;&nbsp;
                                    <AsyncAction
                                        action={() => DeleteStaff(post.id)}
                                        render={({run, loading}) => (
                                            <Button
                                                size="sm"
                                                variant="outline-danger"
                                                type="submit"
                                                data-toggle="tooltip"
                                                data-placement="right"
                                                title="sign-in"
                                                onClick={run}
                                                // disabled={!(formik.isValid && formik.dirty)}
                                            > <FontAwesomeIcon icon={faTrash} className="icon icon-xs text-dark w-30"/>

                                                Delete
                                            </Button>
                                        )}
                                    />
                                </td>

                            </tr>
                        ))}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>

            {/*model for adding education */}
            <Modal show={Show} onHide={handleClose}>
                <AddStaffForm id={Value.id} data={Value} handleShow={handleShow}/>
            </Modal>
            {/*end here modal for adding education*/}
        </>
    )

}

const mapDispatchToProps =

    {
        FetchStaffs, DeleteStaff
    };

export default connect(() => ({}), mapDispatchToProps)(Staff);

