import Axios from "axios";
import {SUBSCRIPTION_DATA} from "./subscriptionActionTypes";
import {toast} from "react-toastify";
import {userToken} from "../Login";

export function FetchSubscription() {
    return async (dispatch) =>
        Axios.get(window.$name + `/subscription/history/${userToken().userId}`, {})
            .then(function (response) {
                // dispatch(AddPlan());
                dispatch({type: SUBSCRIPTION_DATA, payload: response.data});
                // console.log(response.data);
            })
            .catch(function (error) {
                if (error.response) {
                    toast.error(
                        `"${error.response.data}" Error Status  "${error.response.status}" `
                    );
                } else if (error.request) {
                    toast.error(`"${error.request}" `);
                } else {
                    toast.error(`"${error.message}" `);
                }
            });
}