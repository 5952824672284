import Axios from "axios";
import {KIN_DATA} from "./kinActionTypes";
import {toast} from "react-toastify";
import {userToken} from "../Login";

export function AddKin(value) {
    const data = {
        user_id: userToken().userId,
        name: value.name,
        email: value.email,
        relation: value.relation,
        Phone:value.Phone,
        address:value.address,
    };
    return async (dispatch) =>
        Axios.post(window.$name + `/profile/datf/${userToken().userId}`, data, {
            // headers: {
            //     Authentication: `Bearer ${userToken().accessToken}`,
            // },
        })
            .then(function (response) {
                if (response.status === 201) {
                    toast.success("Your Next of kin details have been added,successfully");
                    dispatch(FetchKin())
                }
            })
            .catch(function (error) {
                if (error.response) {
                    toast.error("Something is wrong,Please contact us");
                }
            });
}

export function FetchKin() {
    return async (dispatch) =>
        Axios.get(window.$name + `/profile/datf/${userToken().userId}`, {
        })
            .then(function (response) {
                // console.log("DATA",response.data)
                dispatch({type: KIN_DATA, payload: response.data});
                // console.log(response.data);
            })
            .catch(function (error) {
                if (error.response) {
                    toast.error(
                        `"${error.response.data}" Error Status  "${error.response.status}" `
                    );
                } else if (error.request) {
                    toast.error(`"${error.request}" `);
                } else {
                    toast.error(`"${error.message}" `);
                }
            });
}

export function EditKin(value,id) {
    // console.log(value)
    const data = {
        user_id: userToken().userId,
        name: value.name,
        email: value.email,
        relation: value.relation,
        Phone:value.Phone,
        address:value.address,
        // gender:value.gender,
    };
    return async (dispatch) =>
        Axios.put(window.$name +`/profile/datf/information/${id}`, data,{
            headers:{}
        },{})
            .then(function (response) {
                // console.log(response.data)
                if (response.status === 200) {
                    dispatch(FetchKin())
                    toast.success("Next of Kin Editing ,successfully");
                    // dispatch({type: EDIT_EDUCATION_PROFILES_DATA, payload: response.data})
                }
            })
            .catch(function (error) {
                if (error.response) {
                    toast.error("Something is wrong,Please contact us");
                }
            });
}

export function DeleteKin(id) {

    return async (dispatch) =>
        Axios.delete(window.$name + `/profile/datf/information/${id}`,{
            headers: {},
        },{})
            .then(function (response) {
                if (response.status === 204) {
                    toast.success(" Next Kin Deleting ,successfully");
                    dispatch(FetchKin())
                }
            })
            .catch(function (error) {
                if (error.response) {
                    toast.error("Something is wrong,Please contact us");
                }
            });
}