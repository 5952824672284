import {APPLY_EVENT_DATA} from "./MyEventActionTypes";

export default function myEventReducer(state = [], action) {
    switch (action.type) {
        case APPLY_EVENT_DATA:
            return action.payload;
        default:
            return state;
    }
}
