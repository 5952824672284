import React, {useEffect, useState} from "react"
import {Breadcrumb, Button, Card, Modal, Table} from "@themesberg/react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAddressBook, faEdit, faHome, faTrash} from "@fortawesome/free-solid-svg-icons";
import {connect, useDispatch, useSelector} from "react-redux";
import AddPlanForm from "./planAddForm";
import {DeletePlan, FetchPlans} from "../../../store/plan/planActions";
import AsyncAction from "../../../AsyncAction";

function Plan(props) {
    const {DeletePlan}=props
    const dispatch=useDispatch()
    const [Show, setShow] = useState(false);
    const [Value, setId] = useState(0);
    const handleClose = () => setShow(false);
    const handleShow = (value) => {
        if (Show) {
            setShow(false);
        } else {
            setShow(true);
            setId(value)

        }
    };
    useEffect(() => {
        dispatch(FetchPlans());

    }, [dispatch]);

    const planData = useSelector((state) => state.plan);
    const PageTrafficTable = () => {

        return (
            <Card border="light" className="shadow-sm ">
                <Card.Body className="pb-0">
                    <Table responsive className="table-centered text-center  table-nowrap rounded mb-0">
                        <thead className="thead-light">
                        <tr>
                            <th className="border-0">#</th>
                            <th className="border-0">Name (Basic, Standard, and Premium ) </th>
                            <th className="border-0">Description</th>
                            <th className="border-0">Role(Permission for this plan)</th>
                            <th className="border-0">Plan Id</th>
                            <th className="border-0">Price</th>
                            <th className="border-0 text-center">Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {planData.map((index,key)=>(
                            <tr className="text-center">
                            <td>
                            <Card.Link href="#" className="text-primary fw-bold">{key + 1}</Card.Link>
                            </td>
                            <td>{index.name}</td>
                            <td>{index.description}</td>
                            <td>{index.features}</td>
                            <td>{index.plan_id}</td>
                            <td>{index.price}</td>
                            <td className="text-center">
                            <Button size="sm" onClick={() => handleShow(index)} variant="outline-primary">
                            <FontAwesomeIcon icon={faEdit}  className="icon icon-xs text-dark w-30"/>Edit</Button>
                            &nbsp;&nbsp;
                                <AsyncAction
                                    action={() => DeletePlan(index.id)}
                                    render={({run, loading}) => (
                                        <Button
                                            size="sm"
                                            variant="outline-danger"
                                            type="submit"
                                            data-toggle="tooltip"
                                            data-placement="right"
                                            title="sign-in"
                                            onClick={run}
                                            // disabled={!(formik.isValid && formik.dirty)}
                                        >  <FontAwesomeIcon icon={faTrash} className="icon icon-xs text-dark w-30"/>

                                            Delete
                                        </Button>
                                    )}
                                />
                            </td>
                            </tr>
                            ))}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        );
    };

    return (
        <>
            <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="d-block mb-4 mb-xl-0">
                    <div>
                        <h4> <FontAwesomeIcon icon={faAddressBook} className="icon icon-xs text-dark w-30"/> Member Plan</h4>
                        <div className="container-fluid">
                        <Button size="sm" variant="outline-dark"  onClick={() => handleShow(0)}>
                            Add Plans
                        </Button>
                        &nbsp;&nbsp;
                        <Button size="sm" variant="outline-danger">
                            Bulk Delete
                        </Button>
                        </div>
                    </div>
                </div>

            </div>
            <PageTrafficTable/>

            {/*model for adding education */}
            <Modal show={Show} onHide={handleClose}>
                <AddPlanForm id={Value.id} data={Value} handleShow={handleShow}/>
            </Modal>
            {/*end here modal for adding education*/}
        </>
    )

}

const mapDispatchToProps = {
    FetchPlans,DeletePlan
};

export default connect(() => ({}), mapDispatchToProps)(Plan);

