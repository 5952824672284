import React, {useEffect, useState} from "react";
import {Button, Form, InputGroup, Modal, Row} from "@themesberg/react-bootstrap";
import {connect, useDispatch, useSelector} from "react-redux";
import * as yup from "yup";
import {useFormik} from "formik";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAddressBook, faEnvelope} from "@fortawesome/free-solid-svg-icons";
import {AddPlan, EditPlan, FetchPlans} from "../../../store/plan/planActions";
import {AddStaff, EditStaff} from "../../../store/staff";
import {FetchRoless} from "../../../store/roles";

const validationSchema = yup.object().shape({
    name: yup.string().required("This field is required"),
    role: yup.string().required("This field is required"),
    email: yup.string().required("This field is required"),
    // password: yup.string().required("This field is required"),
});

function AddStaffForm(props) {
    const {AddPlan, handleShow, data} = props;
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(FetchRoless());

    }, [dispatch]);
    const roleData = useSelector((state) => state.roles);
    // console.log(data)
    //using useFormik
    const formik = useFormik({
        initialValues: {
            name: data.name,
            role: data.role,
            email: data.email,
            password: "zls12345678",
            id: data.id,
        },
        validationSchema,
    });

    function handleOnclickOption() {
        handleShow(false);
        if (data != 0) {
            dispatch(EditStaff(formik.values))
        } else {
            dispatch(AddStaff(formik.values))
        }
        formik.resetForm({})
    }


    return (
        <div>
            <Modal.Header>
                <h4>
                    <FontAwesomeIcon icon={faAddressBook} className="icon"/>&nbsp;
                    Add Staff
                </h4>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Name</Form.Label>
                        <input
                            type="text"
                            className="form-control mb-0"
                            autoComplete="off"
                            id="name"
                            name="name"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.name && formik.errors.name ? (
                            <div className="text-danger">
                                {formik.errors.name}
                            </div>
                        ) : null
                        }
                    </Form.Group>
                    <Form.Group id="email" className="mb-4">
                        <Form.Label>Your Email</Form.Label>
                        <InputGroup>
                            <InputGroup.Text>
                                <FontAwesomeIcon icon={faEnvelope}/>
                            </InputGroup.Text>
                            <input
                                type="email"
                                className="form-control mb-0"
                                placeholder="Enter email"
                                autoComplete="off"
                                id="email"
                                name="email"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                        </InputGroup>
                        {formik.touched.email && formik.errors.email ? (
                            <div className="text-danger">
                                {formik.errors.email}
                            </div>
                        ) : null}
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Roles (Permission for this plan)</Form.Label>
                        <Form.Select
                            className="form-control mb-0"
                            onChange={formik.handleChange}
                            id="role"
                            name="role"
                            onBlur={formik.handleBlur}
                            value={formik.values.role}
                        >

                            <option value="">Select Role</option>
                            {roleData.map((option) => (
                                <option value={option.role}>{option.name}</option>
                            ))}

                        </Form.Select>
                        {formik.touched.role && formik.errors.role ? (
                            <div className="text-danger">{formik.errors.role}</div>
                        ) : null}
                    </Form.Group>

                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button size="sm" variant="outline-dark" onClick={() => handleShow(false)}>
                    Close
                </Button>

                <Button
                    size="sm"
                    disabled={!(formik.isValid && formik.dirty)}
                    onClick={() => handleOnclickOption()}>
                    Save Changes
                </Button>


            </Modal.Footer>
        </div>


    )
}

const mapDispatchToProps = {
    AddPlan, EditPlan
};

export default connect(() => ({}), mapDispatchToProps)(AddStaffForm);

