import React, {useEffect, useState} from 'react';
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import {Routes} from "../routes";
import {IntlProvider} from "react-intl";
import {connect} from "react-redux";
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
// pages
import Presentation from "./Presentation";
import Upgrade from "./Upgrade";
import DashboardOverview from "./dashboard/DashboardOverview";
import Transactions from "./Transactions";
import Settings from "./Settings";
import BootstrapTables from "./tables/BootstrapTables";
import Support from "./examples/support";
import Signin from "./examples/Signin";
import Signup from "./examples/Signup";
import ForgotPassword from "./examples/ForgotPassword";
import ResetPassword from "./examples/ResetPassword";
import Lock from "./examples/Lock";
import NotFoundPage from "./examples/NotFound";
import CancelPage from "./examples/cancelPage";
import ServerError from "./examples/ServerError";

// documentation pages
import DocsOverview from "./documentation/DocsOverview";
import DocsDownload from "./documentation/DocsDownload";
import DocsQuickStart from "./documentation/DocsQuickStart";
import DocsLicense from "./documentation/DocsLicense";
import DocsFolderStructure from "./documentation/DocsFolderStructure";
import DocsBuild from "./documentation/DocsBuild";
import DocsChangelog from "./documentation/DocsChangelog";

// components
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
// import Footer from "../components/Footer";
import Preloader from "../components/Preloader";

import Accordion from "./components/Accordion";
import Alerts from "./components/Alerts";
import Badges from "./components/Badges";
import Breadcrumbs from "./components/Breadcrumbs";
import Buttons from "./components/Buttons";
import Forms from "./components/Forms";
import Modals from "./components/Modals";
import Navs from "./components/Navs";
import Navbars from "./components/Navbars";
import Pagination from "./components/Pagination";
import Popovers from "./components/Popovers";
import Progress from "./components/Progress";
import Tables from "./components/Tables";
import Tabs from "./components/Tabs";
import Tooltips from "./components/Tooltips";
import Toasts from "./components/Toasts";
import Profile from "./dashboard/profile";
import Roles from "./dashboard/settings/roles";
import Plan from "./dashboard/settings/plan";
import Invoice from "./dashboard/Billing/invoice";
import Subscription from "./dashboard/Billing/subscription";
import UserPlan from "./dashboard/Billing/userPlan";
import MemberPlan from "./dashboard/Billing/userPlan";
import Staff from "./dashboard/user/staff";
import ProtectedRoute from "../ProtectedRoute";
import ResendToken from "./examples/resendToken";
import SettingIndex from "./dashboard/settings";
import BillList from "./dashboard/Billing/bill-list";
import PasswordChange from "./examples/changePassword";
import Event from "./dashboard/EventsAndTrainingManagement/Event";
import Training from "./dashboard/EventsAndTrainingManagement/Training";
import ListEvent from "./dashboard/EventsAndTrainingManagement/ListEvent";
import ListTraining from "./dashboard/EventsAndTrainingManagement/listTraining";
import MyTraining from "./dashboard/EventsAndTrainingManagement/myTraining";
import MyEvent from "./dashboard/EventsAndTrainingManagement/MyEvent";
import PlanChageRedirect from "./dashboard/Billing/PlanChageRedirect";
import MyPoints from "./dashboard/CLE/myPoints";
import Speakers from "./dashboard/EventsAndTrainingManagement/speaker";
import SpeakersAssigned from "./dashboard/EventsAndTrainingManagement/speakerAssigned";
import SpeakerClaims from "./dashboard/CLE/claimList";
import ClePoints from "./dashboard/CLE/clePoints";
import RequestApproval from "./dashboard/profileComponents/RequestApproval";
import ReceiptList from "./dashboard/Billing/receipt";
import InvoiceList from "./dashboard/Billing/invoiceList";
import AllBillsByUser from "./dashboard/Billing/AllBillByUser";
import CashPaymentRequest from "./dashboard/Billing/CashPaymentRequest";
import Invitation from "./dashboard/EventsAndTrainingManagement/invitation";

window.$name = "https://zls-wakili-api.zls.or.tz";
// window.$name = "http://127.0.0.1:8000";
window.$nameweb = "https://wakili.zls.or.tz";
// heroku_url
// window.$name = "https://api-zls-test.herokuapp.com"

// window.$name = "http://159.223.14.48:8001"

const RouteWithLoader = ({component: Component, ...rest}) => {
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => setLoaded(true), 1000);
        return () => clearTimeout(timer);
    }, []);

    return (
        <Route {...rest} render={props => (<> <Preloader show={loaded ? false : true}/> <Component {...props} /> </>)}/>
    );
};

// const ProtectedRoute = ({component: Component, ...rest}) => {
//     const [loaded, setLoaded] = useState(false);
//
//     useEffect(() => {
//         const timer = setTimeout(() => setLoaded(true), 1000);
//         return () => clearTimeout(timer);
//     }, []);
//
//     const localStorageIsSettingsVisible = () => {
//         return localStorage.getItem('settingsVisible') === 'false' ? false : true
//     }
//
//     const [showSettings, setShowSettings] = useState(localStorageIsSettingsVisible);
//
//     const toggleSettings = () => {
//         setShowSettings(!showSettings);
//         localStorage.setItem('settingsVisible', !showSettings);
//     }
//
//     return (
//         <Route {...rest} render={props => (
//             <>
//                 <Preloader show={loaded ? false : true}/>
//                 <Sidebar/>
//                 <main className="content">
//                     <Navbar/>
//                     <Component {...props} />
//                     {/*<Footer toggleSettings={toggleSettings} showSettings={showSettings}/>*/}
//                 </main>
//             </>
//         )}
//         />
//     );
// };

const shouldUpdateScroll = (prevRouterProps, {location}) =>
    prevRouterProps && location.pathname !== prevRouterProps.location.pathname;

function Root() {
    return (
        <IntlProvider>
            <BrowserRouter>
                <ToastContainer autoClose={5000} hideProgressBar/>
                <Switch>
                    <RouteWithLoader exact path={Routes.Presentation.path} component={Presentation}/>
                    <RouteWithLoader exact path={Routes.Signin.path} component={Signin}/>
                    <RouteWithLoader exact path={Routes.Support.path} component={Support}/>
                    <RouteWithLoader exact path={Routes.Signup.path} component={Signup}/>
                    <RouteWithLoader exact path={Routes.ForgotPassword.path} component={ForgotPassword}/>
                    <RouteWithLoader exact path={Routes.ResetPassword.path} component={ResetPassword}/>
                    <RouteWithLoader exact path={Routes.Lock.path} component={Lock}/>
                    <RouteWithLoader exact path={Routes.NotFound.path} component={NotFoundPage}/>
                    <RouteWithLoader exact path={Routes.CancelUrl.path} component={CancelPage}/>
                    <RouteWithLoader exact path={Routes.ServerError.path} component={ServerError}/>
                    <RouteWithLoader exact path={Routes.ResendToken.path} component={ResendToken}/>

                    {/* pages */}
                    <ProtectedRoute exact path={Routes.DashboardOverview.path} component={DashboardOverview}/>
                    <ProtectedRoute exact path={Routes.Upgrade.path} component={Upgrade}/>
                    <ProtectedRoute exact path={Routes.Transactions.path} component={Transactions}/>
                    {/*my dashboard*/}
                    <ProtectedRoute exact path={Routes.Settings.path} component={Settings}/>
                    <ProtectedRoute exact path={Routes.Profile.path} component={Profile}/>
                    <ProtectedRoute exact path={Routes.Approval.path} component={RequestApproval}/>
                    <ProtectedRoute exact path={Routes.Roles.path} component={Roles}/>
                    <ProtectedRoute exact path={Routes.Plan.path} component={Plan}/>
                    <ProtectedRoute exact path={Routes.redirectPlanUrl.path} component={PlanChageRedirect}/>

                    {/*events and training managements*/}
                    <ProtectedRoute exact path={Routes.Event.path} component={Event}/>
                    <ProtectedRoute exact path={Routes.Training.path} component={Training}/>
                    <ProtectedRoute exact path={Routes.MyTraining.path} component={MyTraining}/>
                    <ProtectedRoute exact path={Routes.MyEvent.path} component={MyEvent}/>
                    <ProtectedRoute exact path={Routes.ListTraining.path} component={ListTraining}/>
                    <ProtectedRoute exact path={Routes.ListEvent.path} component={ListEvent}/>
                    <ProtectedRoute exact path={Routes.SpeakerList.path} component={Speakers}/>
                    <ProtectedRoute exact path={Routes.SpeakerAssigned.path} component={SpeakersAssigned}/>
                    <ProtectedRoute exact path={Routes.ClePoints.path} component={ClePoints}/>
                    <ProtectedRoute exact path={Routes.Claims.path} component={SpeakerClaims}/>
                    <ProtectedRoute exact path={Routes.invitation.path} component={Invitation}/>

                    {/*payments*/}
                    <ProtectedRoute exact path={Routes.InvoiceList.path} component={InvoiceList}/>
                    <ProtectedRoute exact path={Routes.BillingReceipt.path} component={ReceiptList}/>
                    <ProtectedRoute exact path={Routes.BillList.path} component={BillList}/>
                    <ProtectedRoute exact path={Routes.CashPayment.path} component={CashPaymentRequest}/>
                    <ProtectedRoute exact path="/invoice/:order_id" component={Invoice}/>
                    <ProtectedRoute exact path="/member/bills/:user_id/:name" component={AllBillsByUser}/>
                    <ProtectedRoute exact path={Routes.Subscription.path} component={Subscription}/>
                    <ProtectedRoute exact path={Routes.MemberPlan.path} component={MemberPlan}/>
                    <ProtectedRoute exact path={Routes.Staff.path} component={Staff}/>
                    <ProtectedRoute exact path={Routes.Setting.path} component={SettingIndex}/>

                    <ProtectedRoute exact path={Routes.ChangePassword.path} component={PasswordChange}/>

                    <ProtectedRoute exact path={Routes.BootstrapTables.path} component={BootstrapTables}/>

                    {/* components */}
                    <ProtectedRoute exact path={Routes.Accordions.path} component={Accordion}/>
                    <ProtectedRoute exact path={Routes.Alerts.path} component={Alerts}/>
                    <ProtectedRoute exact path={Routes.Badges.path} component={Badges}/>
                    <ProtectedRoute exact path={Routes.Breadcrumbs.path} component={Breadcrumbs}/>
                    <ProtectedRoute exact path={Routes.Buttons.path} component={Buttons}/>
                    <ProtectedRoute exact path={Routes.Forms.path} component={Forms}/>
                    <ProtectedRoute exact path={Routes.Modals.path} component={Modals}/>
                    <ProtectedRoute exact path={Routes.Navs.path} component={Navs}/>
                    <ProtectedRoute exact path={Routes.Navbars.path} component={Navbars}/>
                    <ProtectedRoute exact path={Routes.Pagination.path} component={Pagination}/>
                    <ProtectedRoute exact path={Routes.Popovers.path} component={Popovers}/>
                    <ProtectedRoute exact path={Routes.Progress.path} component={Progress}/>
                    <ProtectedRoute exact path={Routes.Tables.path} component={Tables}/>
                    <ProtectedRoute exact path={Routes.Tabs.path} component={Tabs}/>
                    <ProtectedRoute exact path={Routes.Tooltips.path} component={Tooltips}/>
                    <ProtectedRoute exact path={Routes.Toasts.path} component={Toasts}/>

                    {/* documentation */}
                    <ProtectedRoute exact path={Routes.DocsOverview.path} component={DocsOverview}/>
                    <ProtectedRoute exact path={Routes.DocsDownload.path} component={DocsDownload}/>
                    <ProtectedRoute exact path={Routes.DocsQuickStart.path} component={DocsQuickStart}/>
                    <ProtectedRoute exact path={Routes.DocsLicense.path} component={DocsLicense}/>
                    <ProtectedRoute exact path={Routes.DocsFolderStructure.path} component={DocsFolderStructure}/>
                    <ProtectedRoute exact path={Routes.DocsBuild.path} component={DocsBuild}/>
                    <ProtectedRoute exact path={Routes.DocsChangelog.path} component={DocsChangelog}/>

                    <Redirect to={Routes.NotFound.path}/>
                </Switch>
            </BrowserRouter>
        </IntlProvider>
    );
}

const mapStateToProps = (state) => ({
    authentication: state.auth,
});
export default connect(mapStateToProps)(Root);

