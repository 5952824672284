import React, {useState} from "react";
import {Button, Form, Modal} from "@themesberg/react-bootstrap";
import {useFormik} from "formik";
import * as yup from "yup";
import {userToken} from "../../../store/Login";
import Axios from "axios";
import {toast} from "react-toastify";
import {useDispatch} from "react-redux";
import {FetchAllBill} from "../../../store/Bills";
import {Link} from "react-router-dom";

const validationSchema = yup.object().shape({
    attachment_url: yup.string().required("This field is required"),
});

export function CashPayment(props) {

    const {billData, handleShow} = props;
    const dispatch = useDispatch()
    // console.log(profileData)
    //using useFormik
    const formik = useFormik({
            initialValues: {
                bill_id: billData.id,
                processed_by: userToken().userId,
                attachment_url: ''

            }
        },
        validationSchema,
    )
    const [data, setData] = useState({
        attachment_url: "",
    });
    const handleImageChange = (e) => {
        let newData = {...data};
        newData["attachment_url"] = e.target.files[0];
        setData(newData);
    };

    function uploadFile() {
        let form_data = new FormData();
        if (data.attachment_url)
            form_data.append("attachment_url", data.attachment_url, data.attachment_url.name);
        form_data.append("bill_id", formik.values.bill_id);
        form_data.append("processed_by", formik.values.processed_by);

        const Request = Axios.post(window.$name + '/payment/cash/', form_data, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        }).then((res) => {

            if (res.status === 200) {

                toast.success("Cash payment send for approval process");
                handleShow(false);
                dispatch(FetchAllBill())
            }
            return res;
        }).catch((error) => {
            return error.response;
        });
        return Request;
    }

    console.log(billData)
    return (
        <div>
            <Modal.Header>
                CASH PAYMENT
                {/*<h3 className="text-center"></h3>*/}
                {/*<hr style={{color: 'dark'}}/>*/}
            </Modal.Header>
            <Modal.Body>
                <div>
                    <h5>Bill details</h5>
                    <table className="table table-bordered">
                        <tr>
                            <td>
                                <b>Bill Number</b>
                            </td>
                            <td>
                                <Link to={`/invoice/` + billData.order_id}
                                      className="text-blue fw-bold">{billData.order_id}</Link>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>Name</b>
                            </td>
                            <td>
                                {billData.created_by.name}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>Email</b>
                            </td>
                            <td>
                                {billData.created_by.email}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>Item description </b>
                            </td>
                            <td>
                                {billData.type}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>Amount</b>
                            </td>
                            <td>
                                TZS {billData.amount}
                            </td>
                        </tr>
                    </table>
                </div>
                <Form className="mt-4">
                    <Form.Group controlId="formFile" className="mb-3">
                        <Form.Label>ATTACH PAYMENT RECEIPT</Form.Label>
                        <Form.Control
                            type="file"
                            name="attachment_url"
                            accept=".png, .jpg, .jpeg, .pdf"
                            onChange={(e) => {
                                handleImageChange(e);
                            }}
                        />

                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button size="sm" variant="outline-dark" onClick={() => handleShow(false)}>
                    Close
                </Button>
                <Button size="sm" onClick={() => uploadFile()}>
                    Confirm
                </Button>
            </Modal.Footer>
        </div>
    )
}

