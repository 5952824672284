import {EDIT_STAFF_DATA, STAFF_DATA} from "./staffActionTypes";
export default function staffReducer(state = [], action) {
  switch (action.type) {
    case STAFF_DATA:
      return action.payload;
    case EDIT_STAFF_DATA:
      return action.payload;
    default:
      return state;
  }
}
