import {Button, Card, Col, Form, Modal, Row} from "@themesberg/react-bootstrap";
import React, {useState} from "react";
import {useFormik} from "formik";
import * as yup from "yup";
import {AddKin, DeleteKin, EditKin} from "../../../store/Kin";
import AsyncAction from "../../../AsyncAction";
import {connect, useDispatch} from "react-redux";
import EducationEditForm from "./editEducation";
import KinEditForm from "./kinEdit";
import NotFoundPage from "./notFound";
import {Gender, Relations} from "../../../data/nextKin";

const validationSchema = yup.object().shape({
    address: yup.string().required("This field is required"),
    name: yup.string().required("This field is required"),
    email: yup.string().required("This field is required"),
    Phone: yup.string().required("This field is required"),
    relation: yup.string().required("This field is required"),
});

function Datf(props) {
    const [show, setShow] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);
    const [value, setValues] = useState({});
    const dispatch = useDispatch();
    const handleClose = () => setShow(false);
    const handleCloseEdit = () => setShowEditForm(false);
    const handleShow = () => setShow(true);
    const {AddKin, EditKin, DeleteKin, kinData} = props;
    //using useFormik
    const formik = useFormik({
        initialValues: {
            address: "",
            name: "",
            relation: "",
            gender: "",
            Phone: "",
            email: "",
        },
        validationSchema,
    });

    function AddNextKinForm(values) {
        if (values) {
            AddKin(values);
            setShow(false);
            formik.resetForm({})
        }
    }

    const handleShowEditForm = (value) => {
        // const Values = formik.values = value;
        // console.log("Values", formik.values)
        if (value) {
            setShowEditForm(true);
            setValues(value);
        } else {
            setShowEditForm(false);
        }
    };

    return (
        <>
            <Row>
                <Col className="col d-flex justify-content-start">
                    <h5>Next of Kin </h5>
                    &nbsp;
                    &nbsp;
                    {
                        kinData.length >= 1 ?
                            <Button onClick={handleShow} size="sm" variant="outline-primary">Add Next of Kin</Button>
                            :
                            <></>
                    }

                </Col>
            </Row>
            {
                kinData.length > 0 ?
                    <div>
                        <Row className="mt-3">
                            {
                                kinData.map((index) => (
                                    <Col className="col-md-6">
                                        <Card border="light" className="shadow-none">
                                            <Card.Body>
                                                <div
                                                    className="d-flex align-items-center justify-content-between border-bottom border-light">
                                                    <div>
                                                        <h6 className="text-uppercase">{index.name} <span
                                                            className="text-capitalize">({index.relation})</span></h6>
                                                    </div>
                                                    <div>
                                                        <Card.Link href="#" className="text-primary fw-bold">
                                                        </Card.Link>
                                                    </div>
                                                </div>
                                                <div
                                                    className="d-flex align-items-center justify-content-between border-bottom border-light">
                                                    <div>

                                                        <h6>{index.Phone}</h6>
                                                    </div>
                                                </div>
                                                <div
                                                    className="d-flex align-items-center justify-content-between border-bottom border-light">
                                                    <div>
                                                        <h6>{index.email}</h6>
                                                    </div>
                                                    <div>
                                                        <Card.Link href="#" className="text-primary fw-bold">

                                                        </Card.Link>
                                                    </div>
                                                </div>
                                                <div
                                                    className="d-flex align-items-center justify-content-between border-bottom">
                                                    <div>
                                                        <h6> {index.address}</h6>
                                                    </div>
                                                </div>
                                                <div className="mt-1" style={{float: 'right'}}>
                                                    <Button
                                                        size="sm"
                                                        onClick={() => handleShowEditForm(index)}
                                                        variant="outline-primary">
                                                        Edit
                                                    </Button>
                                                    &nbsp; &nbsp;
                                                    <AsyncAction
                                                        action={() => DeleteKin(index.id)}
                                                        render={({run, loading}) => (
                                                            <Button
                                                                size="sm"
                                                                variant="outline-danger"
                                                                type="submit"
                                                                data-toggle="tooltip"
                                                                data-placement="right"
                                                                title="sign-in"
                                                                onClick={run}
                                                            >
                                                                Remove
                                                            </Button>
                                                        )}
                                                    />
                                                </div>

                                            </Card.Body>
                                        </Card>
                                    </Col>
                                ))
                            }
                        </Row>
                    </div>
                    :
                    <Row>
                        <NotFoundPage/>
                        <div className="d-flex justify-content-center">
                            <Button onClick={handleShow} variant="primary mt-2">Add Next of Kin</Button>
                        </div>
                    </Row>
            }
            {/*model for adding NEXT OF KIN */}
            <Modal show={show} onHide={handleClose}>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Full name</Form.Label>
                            <input
                                type="text"
                                className="form-control mb-0"
                                autoComplete="off"
                                id="name"
                                name="name"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            {formik.touched.name && formik.errors.name ? (
                                <div className="text-danger">
                                    {formik.errors.name}
                                </div>
                            ) : null
                            }
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Gender</Form.Label>
                            <Form.Select
                                className="form-control mb-0"
                                onChange={formik.handleChange}
                                id="gender"
                                name="gender"
                                onBlur={formik.handleBlur}
                                value={formik.values.gender}
                            >
                                <option value="">Select gender</option>
                                {Gender.map((option) => (
                                    <option value={option.name}>{option.name}</option>
                                ))}
                            </Form.Select>
                            {formik.touched.gender && formik.errors.gender ? (
                                <div className="text-danger">{formik.errors.gender}</div>
                            ) : null}
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Relationship</Form.Label>
                            <select
                                className="form-control mb-0"
                                onChange={formik.handleChange}
                                id="relation"
                                name="relation"
                                onBlur={formik.handleBlur}
                                value={formik.values.relation}
                            >
                                <option value="">Select Relationship</option>
                                {Relations.map((option) => (
                                    <option value={option.name}>{option.name}</option>
                                ))}


                            </select>
                            {formik.touched.relation && formik.errors.relation ? (
                                <div className="text-danger">{formik.errors.relation}</div>
                            ) : null}
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Phone</Form.Label>
                            <input
                                type="number"
                                className="form-control mb-0"
                                autoComplete="off"
                                id="Phone"
                                name="Phone"
                                placeholder="eg.255 767 456 546"
                                value={formik.values.Phone}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            {formik.touched.Phone && formik.errors.Phone ? (
                                <div className="text-danger">
                                    {formik.errors.Phone}
                                </div>
                            ) : null
                            }
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Email</Form.Label>
                            <input
                                type="text"
                                className="form-control mb-0"
                                autoComplete="off"
                                id="email"
                                name="email"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            {formik.touched.email && formik.errors.email ? (
                                <div className="text-danger">
                                    {formik.errors.email}
                                </div>
                            ) : null
                            }
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Address</Form.Label>
                            <input
                                type="text"
                                className="form-control mb-0"
                                autoComplete="off"
                                id="address"
                                name="address"
                                value={formik.values.address}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            {formik.touched.address && formik.errors.address ? (
                                <div className="text-danger">
                                    {formik.errors.address}
                                </div>
                            ) : null
                            }
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button size="sm" variant="outline-dark" onClick={handleClose}>
                        Close
                    </Button>
                    {/*<Button size="sm"  onClick={handleClose}>*/}
                    {/*    Save Changes*/}
                    {/*</Button>*/}
                    {/*<AsyncAction*/}
                    {/*    action={}*/}
                    {/*    render={({run, loading}) => (*/}
                    <Button
                        size="sm"
                        type="submit"
                        data-toggle="tooltip"
                        data-placement="right"
                        title="sign-in"
                        variant="dark"
                        onClick={() => AddNextKinForm(formik.values)}
                        disabled={!(formik.isValid && formik.dirty)}
                    >
                        Save Changes
                    </Button>
                    {/*    )}*/}
                    {/*/>*/}
                </Modal.Footer>
            </Modal>
            {/*end here modal for adding NEXT OF KIN*/}

            {/*model for edit next of kin */}
            <Modal show={showEditForm} onHide={handleClose}>
                {/* eslint-disable-next-line react/jsx-no-undef */}
                <KinEditForm kinData={value} handleShowEditForm={handleShowEditForm}/>
            </Modal>
            {/*end here modal for edit next of kin*/}

        </>
    );
}

const mapDispatchToProps =
    {
        AddKin, EditKin, DeleteKin
    }
;

export default connect(() => (
    {}
), mapDispatchToProps)(Datf);


