import Axios from "axios";
import {EDIT_TRAINING_DATA, TRAINING_DATA} from "./trainingActionTypes";
import {toast} from "react-toastify";
import {userToken} from "../Login";

export function AddTraining(data) {

    // console.log(value);
    return async (dispatch) =>
        Axios.post(window.$name + "/events/trainings/", data)
            .then(function (response) {
                if (response.data) {
                    toast.success("Training added successfully");
                    dispatch(FetchTrainings())
                }
            })
            .catch(function (error) {
                if (error.response.data.errors) {
                    if (error.response.data.errors.training_date) {
                        toast.error("Training date - " + " " + error.response.data.errors.training_date[0]);
                    } else if (error.response.data.errors.location) {
                        toast.error("location - " + " " + error.response.data.errors.location[0]);
                    } else if (error.response.data.errors.duration) {
                        toast.error("duration - " + " " + error.response.data.errors.duration[0]);
                    } else {
                        toast.error(error.response);
                    }
                } else {
                    toast.error(error.response.data.detail);
                }
            });
}

export function FetchTrainings() {
    return async (dispatch) =>
        Axios.get(window.$name + '/events/trainings/', {})
            .then(function (response) {
                // console.log("DATA", response.data)
                dispatch({type: TRAINING_DATA, payload: response.data});
                // console.log(response.data);
            })
            .catch(function (error) {
                if (error.response) {
                    toast.error(
                        `"${error.response.data}" Error Status  "${error.response.status}" `
                    );
                } else if (error.request) {
                    toast.error(`"${error.request}" `);
                } else {
                    toast.error(`"${error.message}" `);
                }
            });
}


export function EditTraining(data,id) {

    return async (dispatch) =>
        Axios.put(window.$name + `/events/training/${id}`, data, {
            headers: {}
        }, {})
            .then(function (response) {
                if (response.status === 200) {
                    dispatch(FetchTrainings())
                    toast.success("Training Editing ,successfully");
                    dispatch({type: EDIT_TRAINING_DATA, payload: response.data})
                }
            })
            .catch(function (error) {
                if (error.response) {
                    toast.error("Something is wrong,Please contact us");
                }
            });
}

export function  DeleteTraining(id) {

    return async (dispatch) =>
        Axios.delete(window.$name + `/events/training/${id}`, {
            headers: {},
        }, {})
            .then(function (response) {
                if (response.status === 204) {
                    toast.success("Training Deleting ,successfully");
                    dispatch(FetchTrainings())
                }
            })
            .catch(function (error) {
                if (error.response) {
                    toast.error("Something is wrong,Please contact us");
                }
            });
}