import React, {useState} from "react";
import {Button, Form, Modal, Row} from "@themesberg/react-bootstrap";
import {AddRoles} from "../../../store/roles";
import {connect} from "react-redux";
import * as yup from "yup";
import {useFormik} from "formik";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLock, faTrash} from "@fortawesome/free-solid-svg-icons";

const validationSchema = yup.object().shape({
    display_name: yup.string().required("This field is required"),
    name: yup.string().required("This field is required"),
    // permission: yup.string().required("This field is required"),
});

function AddRolesForm(props) {
    const {AddRoles, handleShow} = props;
    const [permissionValues, setArray] = useState([]);
    // const [showEditForm, setShowEditForm] = useState(false);
    const data = [
        {
            "id": 1,
            "name": "Menus",
            "authorization": [{"name": "Add profile"}, {"name": "Browse profile"}, {"name": "Read profile"}, {"name": "Delete profile"}, {"name": "Edit profile"}]
        },
        {
            "id": 2,
            "name": "Billing",
            "authorization": [{"name": "Add bill"}, {"name": "Browse bill"}, {"name": "Read bill"}, {"name": "Delete bill"}, {"name": "Edit bill"}]
        },
        {
            "id": 3,
            "name": "Event",
            "authorization": [{"name": "Add event"}, {"name": "Browse event"}, {"name": "Read event"}, {"name": "Delete event"}, {"name": "Edit event"}]
        },
        {
            "id": 4,
            "name": "Membership",
            "authorization": [{"name": "Add subscription"}, {"name": "Browse subscription"}, {"name": "Read subscription"}, {"name": "Delete subscription"}, {"name": "Edit subscription"}, {"name": "Add plan"}, {"name": "Browse plan"}, {"name": "Read plan"}, {"name": "Delete plan"}, {"name": "Edit plan"}]
        },
        {
            "id": 5,
            "name": "Settings",
            "authorization": [{"name": "Add user"}, {"name": "Read user"}, {"name": "Browse user"}, {"name": "Edit user"}, {"name": "Delete user"}]
        }
    ]
   // "Add event","Browse event","Read event","Delete event","Edit event","Browse subscription","Read subscription",
    //using useFormik

    function handleOnclickOption() {
        handleShow(false);
        AddRoles(formik.values)
        // console.log("formik",formik.values)
        formik.resetForm({})
    }

    function PermissionArray(newValue) {
        console.log(newValue);
        if (newValue) {
            setArray(oldArray => [...oldArray,  newValue]);
            formik.values.permission = JSON.stringify(permissionValues);
        }
    }

    const formik = useFormik({
        initialValues: {
            name: "",
            display_name: "",
            permission: [],
        },
        validationSchema,
    });
    const role = [
        {
            "role": "MemberTrial",
            "name": "Member-trial"
        },
        {
            "role": "MemberNonPractising",
            "name": "Non-Practising"
        },
        {
            "role": "MemberPractising(1 - 2 years)",
            "name": "Practising(1 - 2 years)"
        },
        {
            "role": "MemberPractising3+",
            "name": "Practising (3+ years)"
        },
        {
            "role": "Admin",
            "name": "Admin"
        },
        {
            "role": "Developer",
            "name": "Developer"
        },

    ]

    return (
        <div>
            <Modal.Header>
                <h4>
                    <FontAwesomeIcon icon={faLock} className="icon"/>&nbsp;
                    Add Roles
                </h4>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    {/*<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">*/}
                    {/*    <Form.Label>Name</Form.Label>*/}
                    {/*    <input*/}
                    {/*        type="text"*/}
                    {/*        className="form-control mb-0"*/}
                    {/*        autoComplete="off"*/}
                    {/*        id="name"*/}
                    {/*        name="name"*/}
                    {/*        value={formik.values.name}*/}
                    {/*        onChange={formik.handleChange}*/}
                    {/*        onBlur={formik.handleBlur}*/}
                    {/*    />*/}
                    {/*    {formik.touched.name && formik.errors.name ? (*/}
                    {/*        <div className="text-danger">*/}
                    {/*            {formik.errors.name}*/}
                    {/*        </div>*/}
                    {/*    ) : null*/}
                    {/*    }*/}
                    {/*</Form.Group>*/}
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Roles Name (Permission for this plan)</Form.Label>
                        <Form.Select
                            className="form-control mb-0"
                            onChange={formik.handleChange}
                            id="name"
                            name="name"
                            onBlur={formik.handleBlur}
                            value={formik.values.role_id}
                        >

                            <option value="">Select Role Name</option>
                            {role.map((option) => (
                                <option value={option.role}>{option.name}</option>
                            ))}

                        </Form.Select>
                        {formik.touched.role_id && formik.errors.role_id ? (
                            <div className="text-danger">{formik.errors.role_id}</div>
                        ) : null}
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Display Name</Form.Label>
                        <input
                            type="text"
                            className="form-control mb-0"
                            autoComplete="off"
                            id="display_name"
                            name="display_name"
                            value={formik.values.display_name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.display_name && formik.errors.display_name ? (
                            <div className="text-danger">
                                {formik.errors.display_name}
                            </div>
                        ) : null
                        }
                    </Form.Group>
                    <Form.Label><h4>Permissions</h4></Form.Label>
                    <div className="row">
                        {data.map((index) => (
                                <div className="col-md-4">
                                    {index.name}
                                    {/*<Form.Check label= id="checkbox1" htmlFor="checkbox1"/>*/}
                                    {index.authorization.map((value) => (
                                            <div className="container-fluid">
                                                <Form.Check
                                                    label={value.name} id="checkbox1"
                                                    name="checkbox1"
                                                    value={value.name}
                                                    onChange={() => PermissionArray(value.name)}
                                                    htmlFor="checkbox1"/>
                                            </div>
                                        )
                                    )}
                                </div>
                            )
                        )}

                    </div>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button size="sm" variant="outline-dark" onClick={() => handleShow(false)}>
                    Close
                </Button>
                <Button
                    size="sm"
                    disabled={!(formik.isValid && formik.dirty)}
                    onClick={() => handleOnclickOption()}>
                    Save Changes
                </Button>
            </Modal.Footer>
        </div>

    )
}

const mapDispatchToProps = {
    AddRoles,
};

export default connect(() => ({}), mapDispatchToProps)(AddRolesForm);

