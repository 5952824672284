import {EMAIL_DATA} from "./emailActionTypes";

export default function emailReducer(state = [], action) {
    switch (action.type) {
        case EMAIL_DATA:
            return action.payload;
        default:
            return state;
    }
}
