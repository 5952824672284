import Axios from "axios";
import {BILL_DATA} from "./BillActionTypes";
import {toast} from "react-toastify";
import {userToken} from "../Login";
import {FetchRoless} from "../roles";
import {FetchKin} from "../Kin";

export function FetchAllBill() {
    return async (dispatch) =>
        Axios.get(window.$name + '/payment/all/bill', {})
            .then(function (response) {
                // console.log("DATA", response.data)
                dispatch({type: BILL_DATA, payload: response.data});
                // console.log(response.data);
            })
            .catch(function (error) {
                if (error.response) {
                    toast.error(
                        `"${error.response.data}" Error Status  "${error.response.status}" `
                    );
                } else if (error.request) {
                    toast.error(`"${error.request}" `);
                } else {
                    toast.error(`"${error.message}" `);
                }
            });
}

export function CancelOrder(order) {
    const data = {
        order_id: order.order_id,
        timestamp: order.formated_date,
    };
    // console.log(data)
    return async (dispatch) =>
        Axios.post(window.$name + `/payment/bill/cancel/${order.order_id}`, data, {
            headers: {}
        }, {})
            .then(function (response) {
                if (response.status === 200) {
                    dispatch(FetchAllBill())
                    toast.success(response.data.message);
                }
            })
            .catch(function (error) {
                if (error.response) {
                    toast.error("Something is wrong,Please contact us");
                }
            });
}

