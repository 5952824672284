import {EDIT_KIN_DATA, KIN_DATA} from "./kinActionTypes";
export default function kinReducer(state = [], action) {
  switch (action.type) {
    case KIN_DATA:
      return action.payload;
    case EDIT_KIN_DATA:
      return action.payload;
    default:
      return state;
  }
}
