import {CLAIM_POINTS_DATA} from "./claimPointsActionTypes";

export default function claimPointsReducer(state = [], action) {
    switch (action.type) {
        case CLAIM_POINTS_DATA:
            return action.payload;
        default:
            return state;
    }
}
