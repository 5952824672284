import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBook, faCheck, faCog, faEdit, faGift, faGifts, faHome, faSearch} from '@fortawesome/free-solid-svg-icons';
import {
    Col,
    Row,
    Form,
    Button,
    ButtonGroup,
    Breadcrumb,
    InputGroup,
    Dropdown,
    Card,
    Table
} from '@themesberg/react-bootstrap';
import {Logout} from "../../../store/Login";
import {FetchPersonalProfiles} from "../../../store/personalProfile";
import {connect, useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import Moment from "moment";
import {FetchCleAll, FetchCleByUser} from "../../../store/ClePoints";


function CleTotalTable(props) {
    const {cleTotalData, title} = props;
    const dispatch = useDispatch()
    console.log("speaker here",cleTotalData)
    useEffect(() => {
        TotalFunc();
    }, [dispatch]);
    //Search Filter Data
    const [searchQuery, setSearchQuery] = useState("")

    function search() {
        if (cleTotalData.length > 0) {
            return  cleTotalData.filter(row => row.cle_points.toLowerCase().indexOf(searchQuery) > -1)
        } else {
            return []
        }
    }
    function TotalFunc() {
        let total = 0;
        cleTotalData.forEach(index => {
            total += Number(index.cle_points);
            // console.log(index.book.price);
        })
        return total
    }
    return (
        <div>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="d-block mb-4 mb-md-0">
                    <Breadcrumb className="d-none d-md-inline-block"
                                listProps={{className: "breadcrumb-dark breadcrumb-transparent"}}>
                        <Breadcrumb.Item><FontAwesomeIcon icon={faHome}/></Breadcrumb.Item>
                        <Breadcrumb.Item>Home</Breadcrumb.Item>
                        <Breadcrumb.Item active>{title}</Breadcrumb.Item>
                    </Breadcrumb>
                    {/*<h4>{title}</h4>*/}
                    {/*<p className="mb-0">Your web analytics dashboard template.</p>*/}
                </div>
                {/*<div className="btn-toolbar mb-2 mb-md-0">*/}
                {/*    <ButtonGroup>*/}
                {/*        <Button variant="outline-primary" size="sm">Share</Button>*/}
                {/*        <Button variant="outline-primary" size="sm">Export</Button>*/}
                {/*    </ButtonGroup>*/}
                {/*</div>*/}
            </div>

            <Row className="justify-content-between align-items-center">
                <Col xs={12} md={6} xl={4} className="ps-md-0">
                    <h4>
                        <FontAwesomeIcon icon={faGift} className="icon icon-xs text-dark w-30"/>
                        {title}  - <span className="font-monospace text-dark">({TotalFunc()} points)</span>
                    </h4>
                </Col>
                <Col xs={12} md={6} lg={3} xl={4}>
                    <InputGroup>
                        <InputGroup.Text>
                            <FontAwesomeIcon icon={faSearch}/>
                        </InputGroup.Text>
                        <Form.Control
                            type="text"
                            placeholder="Search"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                    </InputGroup>
                </Col>
            </Row>
            <Card border="light" className="shadow-sm mt-2">
                <Card.Body className="pb-0">
                    <Table responsive className="table-centered  table-nowrap rounded mb-0">
                        <thead className="thead-light">
                        <tr>
                            <th className="border-0 text-left">#</th>
                            <th className="border-0 text-left">Name</th>
                            <th className="border-0 text-left">Points</th>
                            <th className="border-0 text-left">Created date</th>
                            {/*<th className="border-0 text-center">Actions</th>*/}
                        </tr>
                        </thead>
                        <tbody>
                        {search(cleTotalData).map((index,key) => (
                                <tr>
                                    <td>
                                        {key + 1}
                                    </td>
                                    <td className="text-left text-uppercase">{index.user_id.name}</td>
                                    <td className="text-left">{index.cle_points}</td>
                                    {/*<td className="text-left">{index.sources}</td>*/}
                                    <td className="text-left">{Moment(index.created_at).format('DD-MM-YYYY')}</td>
                                </tr>
                            )
                        )}

                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        </div>
    );
}

const mapDispatchToProps = {
    Logout, FetchPersonalProfiles
};
export default connect(() => ({}), mapDispatchToProps)(CleTotalTable);





