import React, {useEffect, useState} from "react";
import {Button, Form, InputGroup, Modal, Row} from "@themesberg/react-bootstrap";
import {connect, useDispatch, useSelector} from "react-redux";
import * as yup from "yup";
import {useFormik} from "formik";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAddressBook, faEnvelope} from "@fortawesome/free-solid-svg-icons";
import {AddPlan, EditPlan, FetchPlans} from "../../../../store/plan/planActions";
import Axios from "axios";
import {userToken} from "../../../../store/Login";
import {toast} from "react-toastify";
import {FetchUser} from "../../../../store/AccountProfiles";
import {FetchWebsite} from "../../../../store/website";

const validationSchema = yup.object().shape({
    title: yup.string().required("This field is required"),
    description: yup.string().required("This field is required"),
});

function WebConfigForm(props) {
    const {AddPlan, handleShow, data} = props;
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(FetchPlans());
    }, [dispatch]);
    const roleData = useSelector((state) => state.roles);
    // console.log(data)
    //using useFormik
    const formik = useFormik({
        initialValues: {
            title: data.title,
            description: data.description,
        },
        validationSchema,
    });
    const [valueLogo, setData] = useState({
        logo: "",
    });
    const [valueDefault, setDataDefault] = useState({
        default_logo: "",
    });

    const handleImageChangeLogo = (e) => {
        let newData = {...valueLogo};
        newData["logo"] = e.target.files[0];
        setData(newData);
    };
    const handleImageChangeDefaultLogo = (e) => {
        let newData = {...valueDefault};
        newData["default_logo"] = e.target.files[0];
        setDataDefault(newData);
    };

    function addWebDeatils() {
        let form_data = new FormData();
        // if (data.image_url)
        form_data.append("logo", valueLogo.logo, valueLogo.logo.name);
        form_data.append("default_logo", valueDefault.default_logo, valueDefault.default_logo.name);
        form_data.append("title", formik.values.title);
        form_data.append("description", formik.values.description);

        const Request = Axios.post(window.$name + '/settings/web/', form_data, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        }).then((res) => {
            console.log(res);
            if (res.status === 201) {

                toast.success("Profile update success fully");
                // handleShowEditForm(false);
                dispatch(FetchWebsite())
            }
            return res;
        }).catch((error) => {
            return error.response;
        });
        return Request;
    }
    function editWebDeatils() {
        let form_data = new FormData();
        // if (data.image_url)
        form_data.append("logo", valueLogo.logo, valueLogo.logo.name);
        form_data.append("default_logo", valueDefault.default_logo, valueDefault.default_logo.name);
        form_data.append("title", formik.values.title);
        form_data.append("description", formik.values.description);

        const Request = Axios.put(window.$name + `/settings/web/${data.id}`, form_data, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        }).then((res) => {
            console.log(res);
            if (res.status === 200) {

                toast.success("Profile update success fully");
                handleShow(false);
                dispatch(FetchWebsite())
            }
            return res;
        }).catch((error) => {
            return error.response;
        });
        return Request;
    }

    function handleOnclickOption() {
        handleShow(false);
        if (data != 0) {
            editWebDeatils()
        } else {
            addWebDeatils()
        }
        formik.resetForm({})
    }

    return (
        <div>
            <Modal.Header>
                <h4>
                    <FontAwesomeIcon icon={faAddressBook} className="icon"/>&nbsp;
                    Company Web Information
                </h4>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Title</Form.Label>
                        <input
                            type="text"
                            className="form-control mb-0"
                            autoComplete="off"
                            id="title"
                            name="title"
                            value={formik.values.title}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.title && formik.errors.title ? (
                            <div className="text-danger">
                                {formik.errors.title}
                            </div>
                        ) : null
                        }
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Description</Form.Label>
                        <textarea
                            className="form-control mb-0"
                            autoComplete="off"
                            id="description"
                            name="description"
                            value={formik.values.description}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.description && formik.errors.description ? (
                            <div className="text-danger">
                                {formik.errors.description}
                            </div>
                        ) : null
                        }
                    </Form.Group>
                    <Form.Group controlId="formFile" className="mb-3">
                        <Form.Label>Company Logo</Form.Label>
                        <Form.Control
                            type="file"
                            name="logo"
                            accept="image/jpeg,image/png,image/gif"
                            onChange={(e) => {
                                handleImageChangeLogo(e);
                            }}
                        />

                    </Form.Group>
                    <Form.Group controlId="formFile" className="mb-3">
                        <Form.Label>Default Profile Photo</Form.Label>
                        <Form.Control
                            type="file"
                            name="default_logo"
                            accept="image/jpeg,image/png,image/gif"
                            onChange={(e) => {
                                handleImageChangeDefaultLogo(e);
                            }}
                        />

                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button size="sm" variant="outline-dark" onClick={() => handleShow(false)}>
                    Close
                </Button>

                <Button
                    size="sm"
                    disabled={!(formik.isValid && formik.dirty)}
                    onClick={() => handleOnclickOption()}>
                    Save Changes
                </Button>


            </Modal.Footer>
        </div>


    )
}

const mapDispatchToProps = {
    AddPlan, EditPlan
};

export default connect(() => ({}), mapDispatchToProps)(WebConfigForm);

