import React, {useEffect, useState, Fragment} from "react"
import {
    Breadcrumb,
    Button,
    ButtonGroup,
    Card,
    Col, Dropdown,
    Form,
    InputGroup,
    Modal,
    Row,
    Table
} from "@themesberg/react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCalendarAlt, faEdit,
    faHome, faMale, faMicrophone,
    faSearch, faTrash, faUserCircle,
} from "@fortawesome/free-solid-svg-icons";
import {connect, useDispatch, useSelector} from "react-redux";
import EventForm from "./EventForm";
import {DeleteEvent, FetchEvents} from "../../../store/Events";
import CurrencyFormat from "react-currency-format";
import Moment from "moment";
import AsyncAction from "../../../AsyncAction";
import {faUser} from "@fortawesome/free-regular-svg-icons/faUser";
import AddSpeakerForm from "./speakerForm";

function Event(props) {
    const {DeleteEvent} = props
    const dispatch = useDispatch()
    const [Show, setShow] = useState(false);
    const [showSpeaker, setShowSpeaker] = useState(false);
    const [Value, setId] = useState(0);
    const [ValueSpeaker, setIdSpeaker] = useState(0);
    const handleClose = () => setShow(false);
    const handleShow = (value) => {
        if (Show) {
            setShow(false);
        } else {
            setShow(true);
            setId(value)

        }
    };
    const handleCloseSpeaker = () => setShowSpeaker(false);
    const handleShowSpeaker = (value) => {
        if (showSpeaker) {
            setShowSpeaker(false);
        } else {
            setShowSpeaker(true);
            setIdSpeaker(value)

        }
    };
    useEffect(() => {
        dispatch(FetchEvents());

    }, [dispatch]);

    const EventData = useSelector((state) => state.event);

    //Search Filter Data
    const [searchQuery, setSearchQuery] = useState("")
    function search() {
        return EventData.filter(row => row.title.toLowerCase().indexOf(searchQuery) > - 1)

    }
    //Edit data

    return (
        <>
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                    <div className="d-block mb-4 mb-md-0">
                        <Breadcrumb className="d-none d-md-inline-block"
                                    listProps={{className: "breadcrumb-dark breadcrumb-transparent"}}>
                            <Breadcrumb.Item><FontAwesomeIcon icon={faHome}/></Breadcrumb.Item>
                            <Breadcrumb.Item>Home</Breadcrumb.Item>
                            <Breadcrumb.Item active>Event</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <div className="btn-toolbar mb-2 mb-md-0">
                        <ButtonGroup>
                            <Button size="sm" variant="dark" onClick={() => handleShow(0)}>
                                Add Event
                            </Button>
                            <Button variant="outline-primary" size="sm">Export</Button>
                        </ButtonGroup>
                    </div>
                </div>

                <div className="table-settings mb-4">
                    <Row className="justify-content-between align-items-center">
                        <Col xs={6} md={6} xl={4} className="ps-md-0">
                            <h4>
                                <FontAwesomeIcon icon={faCalendarAlt} className="icon icon-xs text-dark w-30"/>
                                Zanzibar Law Society Event
                            </h4>
                        </Col>
                        <Col xs={6} md={6} lg={3} xl={4}>
                            <InputGroup>
                                <InputGroup.Text>
                                    <FontAwesomeIcon icon={faSearch}/>
                                </InputGroup.Text>
                                <Form.Control
                                    type="text"
                                    placeholder="Search"
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                </div>

                <Card border="light" className="shadow-sm mb-4">
                    <Card.Body className="pb-0">
                        <Table responsive className="table-centered  table-nowrap rounded mb-0">
                            <thead className="thead-light">
                            <tr>
                                <th className="border-0">#</th>
                                <th className="border-0">Title</th>
                                {/*<th className="border-0">Image</th>*/}
                                <th className="border-0">Type</th>
                                <th className="border-0">Amount</th>
                                <th className="border-0">Duration</th>
                                <th className="border-0">CLE points</th>
                                <th className="border-0">Event date</th>
                                <th className="border-0 text-center">Actions</th>
                            </tr>
                            </thead>
                            <tbody>
                            {search(EventData).map((post,key) => (
                                <tr>
                                    <td>
                                        { key + 1}
                                    </td>
                                    <td> {post.title}</td>
                                    <td>{post.type}</td>
                                    <td>
                                        {post.is_free ==='true' ?
                                            <CurrencyFormat
                                                value={post.amount}
                                                displayType={'text'}
                                                thousandSeparator={true}
                                                prefix={' TZS '}
                                                renderText={value => <span>{value} </span>}/>
                                            :
                                            <span className="badge-md badge bg-success"> Free </span>
                                        }
                                    </td>
                                    <td>{post.duration}</td>
                                    <td>{post.cle_points}</td>
                                    <td> {Moment(post.event_date).format('DD-MM-YYYY')}</td>
                                    <td>
                                        <Button size="sm" onClick={() => handleShow(post)} variant="outline-primary">
                                            <FontAwesomeIcon icon={faEdit} className="icon icon-xs text-dark w-30"/>Edit</Button>
                                        &nbsp;&nbsp;
                                        <Button size="sm" onClick={() => handleShowSpeaker(post)} variant="outline-primary">
                                            <FontAwesomeIcon  icon={faMicrophone} className="icon icon-xs text-dark w-30"/> Add Speaker</Button>
                                        &nbsp;&nbsp;
                                        <AsyncAction
                                            action={() => DeleteEvent(post.id)}
                                            render={({run, loading}) => (
                                                <Button
                                                    size="sm"
                                                    variant="outline-danger"
                                                    type="submit"
                                                    data-toggle="tooltip"
                                                    data-placement="right"
                                                    title="sign-in"
                                                    onClick={run}
                                                    // disabled={!(formik.isValid && formik.dirty)}
                                                > <FontAwesomeIcon icon={faTrash} className="icon icon-xs text-dark w-30"/>
                                                    Delete
                                                </Button>
                                            )}
                                        />
                                    </td>

                                </tr>
                            ))}
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
            {/*model for adding education */}
            <Modal show={Show} onHide={handleClose}>
                <EventForm id={Value.id} data={Value} handleShow={handleShow}/>
            </Modal>
            {/*end here modal for adding education*/}

            {/*model for adding speaker */}
            <Modal show={showSpeaker} onHide={handleCloseSpeaker}>
                <AddSpeakerForm id={ValueSpeaker.event_id} data={ValueSpeaker} handleShow={handleShowSpeaker}/>
            </Modal>
            {/*end here modal for adding speaker*/}
        </>
    )

}

const mapDispatchToProps =
    {
        FetchEvents, DeleteEvent
    };

export default connect(() => ({}), mapDispatchToProps)(Event);

