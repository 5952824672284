import React, {useEffect} from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheck, faCog, faReceipt} from '@fortawesome/free-solid-svg-icons';
import {Button, ButtonGroup, Card, Col, Dropdown, Row, Table} from '@themesberg/react-bootstrap';
import {Logout} from "../../../store/Login";
import {FetchPersonalProfiles} from "../../../store/personalProfile";
import {connect, useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import Moment from "moment";
import CurrencyFormat from "react-currency-format";
import {FetchCashPayment} from "../../../store/CashPayment";
import Axios from "axios";
import {toast} from "react-toastify";

function CashPaymentRequest() {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(FetchCashPayment());

    }, [dispatch]);

    const BillData = useSelector((state) => state.cashPayment);
    //Search Filter Data
    // const [searchQuery, setSearchQuery] = useState("")

    // function search() {
    //     return BillData.filter(row => row.order_id.toLowerCase().indexOf(searchQuery) > -1)
    // }
    function CashPayApproval(bill) {
        console.log(bill)
        const data = {
            bill_id: Number(bill.bill_id.order_id),
            id: bill.id,
            proccesed_by: bill.processed_by.id
        };
        // console.log(data)
        Axios.post(window.$name + '/payment/cash/approve', data, {
        }).then(response => {
                if (response.status === 200) {
                    dispatch(FetchCashPayment())
                    toast.success(response.data.message);
                }
            })
        // dispatch(ApproveCash(bill))
    }

    return (
        <>
            <div className="table-settings mb-4">
                <p>
                    {/*{dataTest}*/}
                </p>
                <Row className="justify-content-between align-items-center">
                    {/*<Col xs={8} md={6} lg={3} xl={4}>*/}
                    {/*    <InputGroup>*/}
                    {/*        <InputGroup.Text>*/}
                    {/*            <FontAwesomeIcon icon={faSearch}/>*/}
                    {/*        </InputGroup.Text>*/}
                    {/*        <Form.Control*/}
                    {/*            type="text"*/}
                    {/*            placeholder="Search"*/}
                    {/*            value={searchQuery}*/}
                    {/*            onChange={(e) => setSearchQuery(e.target.value)}*/}
                    {/*        />*/}
                    {/*    </InputGroup>*/}
                    {/*</Col>*/}
                    <Col xs={4} md={2} xl={1} className="ps-md-0 text-end">
                        <Dropdown as={ButtonGroup}>
                            <Dropdown.Toggle split as={Button} variant="link" className="text-dark m-0 p-0">
                <span className="icon icon-sm icon-gray">
                  <FontAwesomeIcon icon={faCog}/>
                </span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dropdown-menu-xs dropdown-menu-right">
                                <Dropdown.Item className="fw-bold text-dark">Show</Dropdown.Item>
                                <Dropdown.Item className="d-flex fw-bold">
                                    10 <span className="icon icon-small ms-auto"><FontAwesomeIcon
                                    icon={faCheck}/></span>
                                </Dropdown.Item>
                                <Dropdown.Item className="fw-bold">20</Dropdown.Item>
                                <Dropdown.Item className="fw-bold">30</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                </Row>
            </div>

            <Card border="light" className="shadow-sm mb-4">
                <Card.Body className="pb-0">
                    <Table responsive className="table-centered  table-nowrap rounded mb-0">
                        <thead className="thead-light">
                        <tr>
                            <th className="border-0">#</th>
                            <th className="border-0">Attachment</th>
                            <th className="border-0">Issued Date</th>
                            <th className="border-0">Bill details</th>
                            <th className="border-0">Status</th>
                            <th className="border-0">Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {BillData.map((index) => (
                                <tr>
                                    <td>
                                        <Link to={`/invoice/` + index.bill_id.order_id}
                                              className="text-blue fw-bold">{index.bill_id.order_id}</Link>
                                    </td>
                                    <td><a href={index.attachment_url} target='_blank' className="text-blue fw-bold">
                                        View Attachment
                                    </a></td>
                                    <td>{Moment(index.created_at).format('DD-MM-YYYY')}</td>
                                    <td>
                                        {index.bill_id.amount === 'null' ?
                                            <span className="badge bg-warning badge-lg text-dark ">FREE</span>
                                            :
                                            <div>
                                                Amount :
                                                <CurrencyFormat
                                                    value={index.bill_id.amount}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    prefix={' TZS '}
                                                    renderText={value => <span> {value} </span>}/>
                                                <br/>
                                                <span>
                                                   For : <b>{index.bill_id.type}</b>
                                                </span>
                                                <br/>
                                                <span>
                                                   Member : <b>{index.processed_by.name}</b>
                                                </span>

                                            </div>

                                        }
                                    </td>
                                    <td>
                                        {index.status ?
                                            <span className="badge-md badge bg-success">Approved</span>
                                            :
                                            <span className="badge-md badge bg-danger">Not Approved</span>
                                        }
                                    </td>

                                    <td>
                                        {
                                            !index.status ?
                                                <Button size="sm" onClick={() => CashPayApproval(index)}
                                                        variant="warning">
                                                    <FontAwesomeIcon icon={faReceipt}
                                                                     className="icon icon-xs text-dark w-30"/>
                                                    Approve
                                                </Button>
                                                :
                                                <span className="badge badge-lg bg-gradient-warning text-dark">
                                                  No action
                                                </span>
                                        }
                                    </td>
                                </tr>
                            )
                        )}

                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        </>
    );
}

const mapDispatchToProps = {
    Logout, FetchPersonalProfiles
};
export default connect(() => ({}), mapDispatchToProps)(CashPaymentRequest);





