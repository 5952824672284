import Axios from "axios";
import {toast} from "react-toastify";
import {CLE_POINTS_TOTAL_DATA} from "./clePointsTotalActionTypes";



export function FetchCleTotal() {
    return async (dispatch) =>
        Axios.get(window.$name + '/events/cle/history/total', {})
            .then(function (response) {
                // console.log("DATA", response.data)
                dispatch({type: CLE_POINTS_TOTAL_DATA, payload: response.data});
                // console.log(response.data);
            })
            .catch(function (error) {
                if (error.response) {
                    toast.error(
                        `"${error.response.data}" Error Status  "${error.response.status}" `
                    );
                } else if (error.request) {
                    toast.error(`"${error.request}" `);
                } else {
                    toast.error(`"${error.message}" `);
                }
            });
}

