import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faHome, faReceipt} from '@fortawesome/free-solid-svg-icons';
import {Button, Card, Col, Row, Table, Modal, Form, Breadcrumb, ButtonGroup} from '@themesberg/react-bootstrap';
import {useFormik} from "formik";
import * as yup from "yup";
import {Logout, userToken} from "../../../store/Login";
import {FetchPersonalProfiles} from "../../../store/personalProfile";
import {connect, useDispatch} from "react-redux";
import Moment from "moment";
import {FetchCashPayment} from "../../../store/CashPayment";
import Axios from "axios";
import {toast} from "react-toastify";

const validationSchema = yup.object().shape({
    attachment_url: yup.string().required("This field is required"),
    name: yup.string().required("This field is required"),
});


function Invitation() {
    const dispatch = useDispatch()
    const [Invitation, setInvitation] = useState([]);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [AttachmentData, setAttachmentData] = useState({
        attachment_url: "",
    });
    const handleImageChange = (e) => {
        let newData = {...AttachmentData};
        newData["attachment_url"] = e.target.files[0];
        setAttachmentData(newData);
    };
    const formik = useFormik({
        initialValues: {
            name: "",
            attachment_url: "",
        },
        validationSchema,
    });

    function get_Invitations() {
        Axios.get(window.$name + '/events/list/invitation').then(response => {
            setInvitation(response.data)
            console.log(response.data)
        })
    }


    useEffect(() => {
        get_Invitations()
        dispatch(FetchCashPayment());
    }, [dispatch]);

    function SendInvitation() {
        let form_data = new FormData();
        if (AttachmentData.attachment_url)
            form_data.append("attachment_url", AttachmentData.attachment_url, AttachmentData.attachment_url.name);
        form_data.append("name", formik.values.name);
        form_data.append("created_by", userToken().userId);

        const Request = Axios.post(window.$name + '/events/upload/invitation', form_data, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        }).then((res) => {

            if (res.status === 200) {

                toast.success("Cash payment send for approval process");
                handleClose()
                get_Invitations()
            }
            return res;
        }).catch((error) => {
            return error.response;
        });
        return Request;
    }

    return (
        <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="d-block mb-4 mb-md-0">
                    <Breadcrumb className="d-none d-md-inline-block"
                                listProps={{className: "breadcrumb-dark breadcrumb-transparent"}}>
                        <Breadcrumb.Item><FontAwesomeIcon icon={faHome}/></Breadcrumb.Item>
                        <Breadcrumb.Item>Home</Breadcrumb.Item>
                        <Breadcrumb.Item active>Event</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <div className="btn-toolbar mb-2 mb-md-0">
                    <ButtonGroup>
                        <Button size="sm" variant="dark" onClick={() => setShow(true)}>
                            Add Invitation
                        </Button>
                    </ButtonGroup>
                </div>
            </div>

            <Card border="light" className="shadow-sm mb-4">
                <Card.Body className="pb-0">
                    <Table responsive className="table-centered  table-nowrap rounded mb-0">
                        <thead className="thead-light">
                        <tr>
                            <th className="border-0">#</th>
                            <th className="border-0">name</th>
                            <th className="border-0">Attachment</th>
                            <th className="border-0">Issued Date</th>
                            <th className="border-0">Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {Invitation.map((index, key) => (
                                <tr>
                                    <td>
                                        {1 + key}
                                    </td>
                                    <td>
                                        {index.name}
                                    </td>
                                    <td><a href={index.attachment_url} target='_blank' className="text-blue fw-bold">
                                        View Attachment
                                    </a></td>
                                    <td>{Moment(index.created_at).format('DD-MM-YYYY')}</td>
                                    <td>
                                        :
                                    </td>
                                </tr>
                            )
                        )}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
            {/*model for adding cash payment */}
            <Modal show={show} onHide={handleClose}>
                <Modal.Header>
                    <h3 className="text-center"> Invitation upload</h3>
                    <hr style={{color: 'dark'}}/>
                </Modal.Header>
                <Modal.Body>
                    <Form className="mt-4">
                        <div className="form-group">
                            <Form.Label>Attachment Name</Form.Label>
                            <input
                                type="text"
                                className="form-control mb-0"
                                autoComplete="off"
                                id="name"
                                name="name"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            {formik.touched.name && formik.errors.name ? (
                                <div className="text-danger">
                                    {formik.errors.name}
                                </div>
                            ) : null
                            }
                        </div>
                        <div className="form-group">
                            <Form.Label>File</Form.Label>
                            <Form.Control
                                type="file"
                                name="attachment_url"
                                accept=".png, .jpg, .jpeg, .pdf"
                                onChange={(e) => {
                                    handleImageChange(e);
                                }}
                            />
                        </div>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button size="sm" variant="outline-dark" onClick={() => handleClose()}>
                        Close
                    </Button>
                    <Button size="sm" onClick={() => SendInvitation()}>
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>
            {/*end here modal for adding cash payment*/}
        </>
    );
}

const mapDispatchToProps = {
    Logout, FetchPersonalProfiles
};
export default connect(() => ({}), mapDispatchToProps)(Invitation);





