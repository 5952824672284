import Axios from "axios";
import {toast} from "react-toastify";
import {BILL_STATUS_DATA} from "./BillStatusActionTypes";

export function FetchBillStatus(user_id,status) {

    return async (dispatch) =>
        Axios.get(window.$name + `/payment/bill/list/${user_id}/${status}`, {})
            .then(function (response) {
                // console.log("DATA", response.data)
                dispatch({type: BILL_STATUS_DATA, payload: response.data});
                // console.log(response.data);
            })
            .catch(function (error) {
                if (error.response) {
                    toast.error(
                        `"${error.response.data}" Error Status  "${error.response.status}" `
                    );
                } else if (error.request) {
                    toast.error(`"${error.request}" `);
                } else {
                    toast.error(`"${error.message}" `);
                }
            });
}
