import {Button, Card, Col, Form, Row} from "@themesberg/react-bootstrap";
import React, {useEffect, useState} from "react";
import {useFormik} from "formik";
import * as yup from "yup";
import AsyncAction from "../../../AsyncAction";
import classNames from "classnames";
import Axios from "axios";
import EditPersonalDetails from "./editPersonalDetails";
import {userToken} from "../../../store/Login";
import {connect, useDispatch, useSelector} from "react-redux";
import {AddPersonalProfile} from "../../../store/personalProfile";
import {FetchUser} from "../../../store/AccountProfiles";
import {Loader} from "../../../store/loader";
import NotFoundPage from "./notFound";
import {FetchApprovalRequest} from "../../../store/ApprovalProfile";
import {FetchPlans} from "../../../store/plan/planActions";
import Grid from "@mui/material/Grid";


const validationSchema = yup.object().shape({
    Date_birth: yup.string().required("This field is required"),
    Phone: yup.string().required("This field is required"),
    gender: yup.string().required("This field is required"),
    marital: yup.string().required("This field is required"),
    chapter: yup.string().required("This field is required"),
    level: yup.string().required("This field is required"),
    Spouse_name: yup.string().required("This field is required"),
    Passport_no: yup.string(),
    health_challenges: yup.string(),
    National_id_no: yup.string().required("This field is required"),
    specialization: yup.string().required("This field is required"),
    name: yup.string().required("This field is required"),
    email: yup.string().required("This field is required"),
});

function PersonalDetails(props) {
    const {AddPersonalProfile, personalData, userProfile} = props
    const [personalInfo, setInfo] = useState(null);
    const [editActive, setActive] = useState(false);
    const dispatch = useDispatch()
    const [image, setImage] = useState(null);
    const [url, setUrl] = useState("");

    useEffect(() => {
        dispatch(FetchPlans());

    }, [dispatch]);

    const isLoading = useSelector((state) => state.isLoading);

    const planData = useSelector((state) => state.plan);

    // console.log(isLoading);

    function EditPersonaInformation() {
        // Axios.get(window.$name + `/profile/datf/${id}`,)
        Axios.get(window.$name + `/profile/personal/${userToken().userId}`)
            .then(res => {
                const persons = res.data[0];
                setInfo(persons)
                if (editActive) {
                    setActive(false)
                } else {
                    setActive(true)
                }
            })
    }

    //using useFormik
    const formik = useFormik({
        initialValues: {
            Date_birth: "",
            gender: "",
            marital: "",
            Phone: "",
            chapter: "",
            level: "",
            Spouse_name: "",
            Passport_no: "",
            health_challenges: "",
            National_id_no: "",
            specialization: "",
            email: userProfile.email,
            image_url: "",
            name: userProfile.name,
            username: userProfile.username,
        },
        validationSchema,
    });
    const [data, setData] = useState({
        image_url: "",
    });

    const handleImageChange = (e) => {
        let newData = {...data};
        newData["image_url"] = e.target.files[0];
        setData(newData);
    };


    function SaveProfile() {
        dispatch(Loader(true));
        let form_data = new FormData();
        if (data.image_url)
            form_data.append("image_url", data.image_url, data.image_url.name);
        form_data.append("email", userProfile.email);
        form_data.append("username", formik.values.username);
        // form_data.append("image_url", data.image_url);
        form_data.append("name", formik.values.name);

        const Request = Axios.patch(window.$name + `/auth/user/${userToken().userId}`, form_data, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        }).then((res) => {
            // console.log(res);
            if (res.status === 200) {
                // toast.success("Profile update success fully");
                // handleShowEditForm(false);
                dispatch(FetchUser())
                AddPersonalProfile(formik.values);
                formik.resetForm({})
            }
            return res;
        }).catch((error) => {
            return error.response;
        });
        return Request;
    }

    return (
        <>
            <Row>
                <Col className="col-md-6 d-flex justify-content-start">
                    <>
                    <h5>Personal Information</h5> &nbsp; &nbsp; {personalData.length > 0 ?
                    <Button onClick={() => (EditPersonaInformation())} size="sm"
                            variant="outline-primary">
                        <>
                            {
                                editActive ?
                                    <>
                                       Back
                                    </>
                                    :
                                    <>
                                        Edit
                                    </>
                            }
                        </>
                    </Button>
                    :
                    <></>
                }
                </>
                </Col>
            </Row>
            {
                personalData.length <=0 ?
                    <Card border="light" className="bg-white shadow-sm mb-4">
                        <Card.Body>
                            <Row>
                                <Col md={6} className="mb-3">
                                    <div className="form-group">
                                        <Form.Label>Full Name</Form.Label>
                                        <input
                                            type="text"
                                            className="form-control mb-0"
                                            autoComplete="off"
                                            id="name"
                                            name="name"
                                            value={formik.values.name}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                        {formik.touched.name && formik.errors.name ? (
                                            <div className="text-danger">
                                                {formik.errors.name}
                                            </div>
                                        ) : null
                                        }
                                    </div>
                                </Col>
                                <Col md={6} className="mb-3">
                                    <div className="form-group">
                                        <Form.Label>Upload Profile Photo</Form.Label>
                                        <Form.Control
                                            type="file"
                                            name="image_url"
                                            accept="image/jpeg,image/png,image/gif"
                                            onChange={(e) => {
                                                handleImageChange(e);
                                            }}
                                        />

                                    </div>
                                </Col>
                                <Col md={6} className="mb-3">
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Email</Form.Label>
                                        <input
                                            type="text"
                                            className="form-control mb-0"
                                            autoComplete="off"
                                            id="email"
                                            name="email"
                                            disabled
                                            placeholder="eg. name@company.com"
                                            value={formik.values.email}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                        {formik.touched.email && formik.errors.email ? (
                                            <div className="text-danger">
                                                {formik.errors.email}
                                            </div>
                                        ) : null
                                        }
                                    </Form.Group>
                                </Col>
                                <Col md={6} className="mb-3">
                                    <div className="form-group">
                                        <Form.Label>Phone</Form.Label>
                                        <input
                                            type="number"
                                            className="form-control mb-0"
                                            autoComplete="off"
                                            id="Phone"
                                            name="Phone"
                                            placeholder="eg.255 767 456 546"
                                            value={formik.values.Phone}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                        {formik.touched.Phone && formik.errors.Phone ? (
                                            <div className="text-danger">
                                                {formik.errors.Phone}
                                            </div>
                                        ) : null
                                        }
                                    </div>
                                </Col>
                                <Col md={6} className="mb-3">
                                    <div className="form-group">
                                        <Form.Label>Date of Birth</Form.Label>
                                        <input
                                            type="date"
                                            className="form-control mb-0"
                                            autoComplete="off"
                                            id="Date_birth"
                                            name="Date_birth"
                                            value={formik.values.Date_birth}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                        {formik.touched.Date_birth && formik.errors.Date_birth ? (
                                            <div className="text-danger">
                                                {formik.errors.Date_birth}
                                            </div>
                                        ) : null
                                        }
                                    </div>
                                </Col>
                                <Col md={6} className="mb-3">
                                    <div className="form-group">
                                        <Form.Label>Gender</Form.Label>
                                        <Form.Select
                                            className="form-control mb-0"
                                            onChange={formik.handleChange}
                                            id="gender"
                                            name="gender"
                                            onBlur={formik.handleBlur}
                                            value={formik.values.gender}
                                        >
                                            <option value="">Select gender</option>
                                            <option value="Male">Male</option>
                                            <option value="Female">Female</option>
                                        </Form.Select>
                                        {formik.touched.gender && formik.errors.gender ? (
                                            <div className="text-danger">{formik.errors.gender}</div>
                                        ) : null}
                                    </div>
                                </Col>
                                <Col md={6} className="mb-3">
                                    <div className="form-group">
                                        <Form.Label>Marital Status</Form.Label>
                                        <Form.Select
                                            className="form-control mb-0"
                                            onChange={formik.handleChange}
                                            id="marital"
                                            name="marital"
                                            onBlur={formik.handleBlur}
                                            value={formik.values.marital}
                                        >
                                            <option value="">Select Marital status</option>
                                            <option value="Divorced">Divorced</option>
                                            <option value="Single">Single</option>
                                            <option value="Widow">Widow</option>
                                            <option value="Married">Married</option>
                                            <option value="Widower">Widower</option>
                                        </Form.Select>
                                        {formik.touched.marital && formik.errors.marital ? (
                                            <div className="text-danger">{formik.errors.marital}</div>
                                        ) : null}
                                    </div>
                                </Col>
                                <Col md={6} className="mb-3">
                                    <div className="form-group">
                                        <Form.Label>Spouse Name</Form.Label>
                                        <input
                                            type="text"
                                            className="form-control mb-0"
                                            autoComplete="off"
                                            id="Spouse_name"
                                            name="Spouse_name"
                                            placeholder="eg. John Doe"
                                            value={formik.values.Spouse_name}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                        {formik.touched.Spouse_name && formik.errors.Spouse_name ? (
                                            <div className="text-danger">
                                                {formik.errors.Spouse_name}
                                            </div>
                                        ) : null
                                        }
                                    </div>
                                </Col>
                                <Col md={12} className="mb-3">
                                    <div className="form-group">
                                        <Form.Label>Member Level</Form.Label>
                                        <Form.Select
                                            className="form-control mb-0"
                                            onChange={formik.handleChange}
                                            id="level"
                                            name="level"
                                            onBlur={formik.handleBlur}
                                            value={formik.values.level}
                                        >
                                            <option value="">Select your level</option>
                                            {planData.map((level) => (
                                                <option value={level.id}>{level.name}</option>
                                            ))
                                            }
                                        </Form.Select>
                                        {formik.touched.level && formik.errors.level ? (
                                            <div className="text-danger">{formik.errors.level}</div>
                                        ) : null}
                                    </div>
                                </Col>
                                <Col md={6} className="mb-3">
                                    <div className="form-group">
                                        <Form.Label>National ID /Zanzibar ID &nbsp; (No dashes)
                                        </Form.Label>
                                        <input
                                            type="number"
                                            className="form-control mb-0"
                                            autoComplete="off"
                                            id="National_id_no"
                                            name="National_id_no"
                                            placeholder="eg. 655465475745745"
                                            value={formik.values.National_id_no}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                        {formik.touched.tin && formik.errors.National_id_no ? (
                                            <div className="text-danger">
                                                {formik.errors.National_id_no}
                                            </div>
                                        ) : null
                                        }
                                    </div>
                                </Col>

                                <Col md={6} className="mb-3">
                                    <div className="form-group">
                                        <Form.Label>Passport No (Optional)</Form.Label>
                                        <input
                                            type="text"
                                            className="form-control mb-0"
                                            autoComplete="off"
                                            id="Passport_no"
                                            name="Passport_no"
                                            placeholder="eg. TAR040547"
                                            value={formik.values.Passport_no}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                        {formik.touched.Passport_no && formik.errors.Passport_no ? (
                                            <div className="text-danger">
                                                {formik.errors.Passport_no}
                                            </div>
                                        ) : null
                                        }
                                    </div>
                                </Col>

                                <Col md={6} className="mb-3">
                                    <div className="form-group">
                                        <Form.Label>Chapter</Form.Label>
                                        <Form.Select
                                            className="form-control mb-0"
                                            onChange={formik.handleChange}
                                            id="chapter"
                                            name="chapter"
                                            onBlur={formik.handleBlur}
                                            value={formik.values.chapter}
                                        >
                                            <option value="">Select chapter</option>
                                            <optgroup label="Zanzibar Zone">
                                                <option value="Pemba">Pemba</option>
                                                <option value="Unguja">Unguja</option>
                                            </optgroup>
                                        </Form.Select>
                                        {formik.touched.chapter && formik.errors.chapter ? (
                                            <div className="text-danger">{formik.errors.chapter}</div>
                                        ) : null}
                                    </div>
                                </Col>
                                <Col md={6} className="mb-3">
                                    <div className="form-group">
                                        <Form.Label>Area of specialization</Form.Label>
                                        <input
                                            type="text"
                                            className="form-control mb-0"
                                            autoComplete="off"
                                            id="specialization"
                                            name="specialization"
                                            placeholder="eg. Intellectual Property Law"
                                            value={formik.values.specialization}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                        {formik.touched.specialization && formik.errors.specialization ? (
                                            <div className="text-danger">
                                                {formik.errors.specialization}
                                            </div>
                                        ) : null
                                        }
                                    </div>
                                </Col>
                                <Col md={12} className="mb-3">
                                    <div className="form-group">
                                        <Form.Label>State any health challenges/chronic disease/disability you wish to
                                            disclose
                                            (optional)</Form.Label>
                                        <textarea
                                            className="form-control mb-0"
                                            autoComplete="off"
                                            id="health_challenges"
                                            name="health_challenges"
                                            placeholder="eg. Diabetic"
                                            value={formik.values.health_challenges}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                        {formik.touched.health_challenges && formik.errors.health_challenges ? (
                                            <div className="text-danger">
                                                {formik.errors.health_challenges}
                                            </div>
                                        ) : null
                                        }
                                    </div>
                                </Col>
                            </Row>
                            <div className="mt-2" style={{float: 'right'}}>
                                {
                                    !isLoading.isLoading ?
                                        <Button
                                            type="submit"
                                            size="sm"
                                            data-toggle="tooltip"
                                            data-placement="right"
                                            title="sign-in"
                                            variant="dark"
                                            onClick={() => SaveProfile()}
                                            disabled={!(formik.isValid && formik.dirty)}
                                            className="w-100"
                                        >
                                            Save changes
                                        </Button>
                                        :
                                        <Button
                                            type="submit"
                                            size="sm"
                                            data-toggle="tooltip"
                                            data-placement="right"
                                            title="sign-in"
                                            variant="dark"
                                            disabled
                                            className="w-100"
                                        >
                                            Saving.....
                                        </Button>


                                }


                            </div>
                        </Card.Body>
                    </Card>
                    :
                    <>

                        {
                            personalInfo !== null && editActive !== false ?
                                <div>
                                    <EditPersonalDetails planData={planData} personalInfo={personalInfo}
                                                         userProfile={userProfile}
                                                         setActive={setActive}/>
                                </div>
                                :
                                <>
                                    {personalData.map((index) => (
                                            <div>
                                                <Row className="mt-2">
                                                    <Col className="col-12">
                                                        <Card border="light" className="shadow-none">
                                                            <Card.Body>
                                                                <div
                                                                    className="d-flex align-items-center justify-content-between border-bottom border-light">
                                                                    <div>
                                                                        <h6>Date of birth</h6>
                                                                    </div>
                                                                    <div>
                                                                        <Card.Link href="#"
                                                                                   className="text-primary fw-bold">
                                                                            {index.Date_of_Birth}
                                                                        </Card.Link>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className="d-flex align-items-center justify-content-between border-bottom border-light">
                                                                    <div>
                                                                        <h6>Gender</h6>
                                                                    </div>
                                                                    <div>
                                                                        <Card.Link href="#"
                                                                                   className="text-primary fw-bold">
                                                                            {index.Gender}
                                                                        </Card.Link>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className="d-flex align-items-center justify-content-between border-bottom border-light">
                                                                    <div>
                                                                        <h6> Passport no </h6>
                                                                    </div>
                                                                    <div>
                                                                        <Card.Link href="#"
                                                                                   className="text-primary fw-bold">
                                                                            {index.Passport_no}
                                                                        </Card.Link>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className="d-flex align-items-center justify-content-between border-bottom border-light">
                                                                    <div>
                                                                        <h6>
                                                                            National Id / Zanzibar ID
                                                                            No</h6>
                                                                    </div>
                                                                    <div>
                                                                        <Card.Link href="#"
                                                                                   className="text-primary fw-bold">
                                                                            {index.National_id_no}
                                                                        </Card.Link>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className="d-flex align-items-center justify-content-between border-bottom border-light">
                                                                    <div>
                                                                        <h6>Area of specialization</h6>
                                                                    </div>
                                                                    <div>
                                                                        <Card.Link href="#"
                                                                                   className="text-primary fw-bold">
                                                                            {index.specialization}
                                                                        </Card.Link>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className="d-flex align-items-center justify-content-between border-bottom border-light">
                                                                    <div>
                                                                        <h6>Health Challenge</h6>
                                                                    </div>
                                                                    <div>
                                                                        <Card.Link href="#"
                                                                                   className="text-primary fw-bold">
                                                                            {index.health_challenges}
                                                                        </Card.Link>
                                                                    </div>
                                                                </div>
                                                            </Card.Body>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                                <Row className="mt-3">
                                                    <Col className="col-md-6">
                                                        <Card border="light" className="shadow-none">
                                                            <Card.Body>
                                                                <div
                                                                    className="d-flex align-items-center justify-content-between border-bottom border-light">
                                                                    <div>
                                                                        <h6>Chapter</h6>
                                                                    </div>
                                                                    <div>
                                                                        <Card.Link href="#"
                                                                                   className="text-primary">
                                                                            {index.Chapter}
                                                                        </Card.Link>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className="d-flex align-items-center justify-content-between border-bottom border-light">
                                                                    <div>
                                                                        <h6> Phone number</h6>
                                                                    </div>
                                                                    <div>
                                                                        <Card.Link href="#"
                                                                                   className="text-primary">
                                                                            {index.Phone}
                                                                        </Card.Link>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className="d-flex align-items-center justify-content-between border-bottom border-light">
                                                                    <div>
                                                                        <h6> Email</h6>
                                                                    </div>
                                                                    <div>
                                                                        <Card.Link href="#"
                                                                                   className="text-primary">
                                                                            {index.email}
                                                                        </Card.Link>
                                                                    </div>
                                                                </div>
                                                            </Card.Body>
                                                        </Card>
                                                    </Col>
                                                    <Col className="col-md-6">
                                                        <Card border="light" className="shadow-none">
                                                            <Card.Body>
                                                                <div
                                                                    className="d-flex align-items-center justify-content-between border-bottom border-light">
                                                                    <div>
                                                                        <h6>Member Level</h6>
                                                                    </div>
                                                                    <div>
                                                                        <Card.Link href="#"
                                                                                   className="text-primary">
                                                                            {index.level.name}
                                                                        </Card.Link>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className="d-flex align-items-center justify-content-between border-bottom border-light">
                                                                    <div>
                                                                        <h6>Marital status</h6>
                                                                    </div>
                                                                    <div>
                                                                        <Card.Link href="#"
                                                                                   className="text-primary">
                                                                            {index.Marital_status}
                                                                        </Card.Link>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className="d-flex align-items-center justify-content-between border-bottom border-light">
                                                                    <div>
                                                                        <h6> Spouse name</h6>
                                                                    </div>
                                                                    <div>
                                                                        <Card.Link href="#"
                                                                                   className="text-primary">
                                                                            {index.Spouse_name}
                                                                        </Card.Link>
                                                                    </div>
                                                                </div>

                                                            </Card.Body>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            </div>
                                        )
                                    )}
                                </>
                        }
                    </>
            }
        </>
    );

}

const mapDispatchToProps =
    {
        AddPersonalProfile
    }
;

export default connect(() => (
    {}
), mapDispatchToProps)(PersonalDetails);



