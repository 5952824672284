import React, {useEffect, useState} from "react";
import {Button, Form, Modal, Row} from "@themesberg/react-bootstrap";
import {AddEducationProfile, DeleteEducation} from "../../../store/eductionProfile";
import {connect, useDispatch, useSelector} from "react-redux";
import * as yup from "yup";
import {useFormik} from "formik";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAddressBook, faLock, faTrash} from "@fortawesome/free-solid-svg-icons";
import {AddPlan, EditPlan, FetchPlans} from "../../../store/plan/planActions";

const validationSchema = yup.object().shape({
    name: yup.string().required("This field is required"),
    role_id: yup.string().required("This field is required"),
    description: yup.string().required("This field is required"),
    features: yup.string().required("This field is required"),
    plan_id: yup.string().required("This field is required"),
    price: yup.string().required("This field is required"),
    default: yup.string().required("This field is required"),
});

function AddPlanForm(props) {
    const {AddPlan, handleShow, data} = props;
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(FetchPlans());

    }, [dispatch]);
    const roleData = useSelector((state) => state.roles);
    // console.log(data)
    //using useFormik
    const formik = useFormik({
        initialValues: {
            name: data.name,
            role_id: data.role_id,
            description: data.description,
            features: data.features,
            plan_id: data.plan_id,
            price: data.price,
            default: data.default,
            id: data.id,
        },
        validationSchema,
    });

    function handleOnclickOption() {
        handleShow(false);
        if (data != 0) {
            dispatch(EditPlan(formik.values))
        } else {
            AddPlan(formik.values)
        }
        formik.resetForm({})
    }

    function handlechange(e) {
        formik.values.default = e.checked
    }

    return (
        <div>
            <Modal.Header>
                <h4>
                    <FontAwesomeIcon icon={faAddressBook} className="icon"/>&nbsp;
                    Add Plan
                </h4>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Name</Form.Label>
                        <input
                            type="text"
                            className="form-control mb-0"
                            autoComplete="off"
                            id="name"
                            name="name"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.name && formik.errors.name ? (
                            <div className="text-danger">
                                {formik.errors.name}
                            </div>
                        ) : null
                        }
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Features (Comma Separated)</Form.Label>
                        <textarea
                            className="form-control mb-0"
                            autoComplete="off"
                            id="features"
                            name="features"
                            value={formik.values.features}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.features && formik.errors.features ? (
                            <div className="text-danger">
                                {formik.errors.features}
                            </div>
                        ) : null
                        }
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Permission for this plan</Form.Label>
                        <Form.Select
                            className="form-control mb-0"
                            onChange={formik.handleChange}
                            id="role_id"
                            name="role_id"
                            onBlur={formik.handleBlur}
                            value={formik.values.role_id}
                        >

                            <option value="">Select Role</option>
                            {roleData.map((option) => (
                                <option value={option.id}>{option.name}</option>
                            ))}

                        </Form.Select>
                        {formik.touched.role_id && formik.errors.role_id ? (
                            <div className="text-danger">{formik.errors.role_id}</div>
                        ) : null}
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Description (optional)</Form.Label>
                        <textarea
                            className="form-control mb-0"
                            autoComplete="off"
                            id="description"
                            name="description"
                            value={formik.values.description}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.description && formik.errors.description ? (
                            <div className="text-danger">
                                {formik.errors.description}
                            </div>
                        ) : null
                        }
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Plan Id</Form.Label>
                        <input
                            type="text"
                            className="form-control mb-0"
                            autoComplete="off"
                            id="plan_id"
                            name="plan_id"
                            value={formik.values.plan_id}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.plan_id && formik.errors.plan_id ? (
                            <div className="text-danger">
                                {formik.errors.plan_id}
                            </div>
                        ) : null
                        }
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Price</Form.Label>
                        <input
                            type="text"
                            className="form-control mb-0"
                            autoComplete="off"
                            id="price"
                            name="price"
                            value={formik.values.price}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.price && formik.errors.price ? (
                            <div className="text-danger">
                                {formik.errors.price}
                            </div>
                        ) : null
                        }
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Check onChange={(e) => handlechange(e.target)} label="Make this default plan"
                                    id="checkbox1" htmlFor="checkbox1"/>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button size="sm" variant="outline-dark" onClick={() => handleShow(false)}>
                    Close
                </Button>

                <Button
                    size="sm"
                    disabled={!(formik.isValid && formik.dirty)}
                    onClick={() => handleOnclickOption()}>
                    Save Changes
                </Button>


            </Modal.Footer>
        </div>


    )
}

const mapDispatchToProps = {
    AddPlan, EditPlan
};

export default connect(() => ({}), mapDispatchToProps)(AddPlanForm);

