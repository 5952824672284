import React, {useEffect, useState} from "react";
import {Logout} from "../../../store/Login";
import {FetchPersonalProfiles} from "../../../store/personalProfile";
import {connect, useDispatch, useSelector} from "react-redux";
import {FetchSpeaker, FetchSpeakerById} from "../../../store/Speaker";
import CleTable from "./cleTable";
import {FetchCleAll, FetchCleByUser} from "../../../store/ClePoints";
import CleTotalTable from "./CleTotal";
import {FetchCleTotal} from "../../../store/cleTotal";


function ClePoints() {
    const dispatch =useDispatch();

    const rolesData = useSelector((state) => state.permission[0]);

    const isAllowed=JSON.parse(rolesData.permission);
    // const isAllowed = ['Read profile', 'Browse user', 'Add plan','Add event', 'Browse event','Browse bill','Browse profile'];

    useEffect(() => {
        if(isAllowed.includes('Browse user')){
            dispatch(FetchCleTotal());
        }
        else{
            // dispatch(FetchCleTotal());
            dispatch(FetchCleByUser());
        }


    }, [dispatch]);

    const cleData = useSelector((state) => state.clePoints);
    const cleTotalData = useSelector((state) => state.cleTotal);

console.log("cle data",cleData)
    return (
        <>
            { isAllowed.includes('Browse user') ?

                <CleTotalTable cleTotalData={cleTotalData} title="CLE Points" />
                :
                <CleTable speakerData={cleData} title="CLE Points" />
            }

        </>
    );
};

const mapDispatchToProps = {
    Logout, FetchPersonalProfiles
};
export default connect(() => ({}), mapDispatchToProps)(ClePoints);





