import Axios from "axios";
import {toast} from "react-toastify";

export function CreateAccont(value, history) {
    const data = {
        name: value.name,
        username:'MemberTrial',
        email: value.email,
        role: 'MemberTrial',
        password: value.password,
    };
    return async (dispatch) =>
        Axios.post(window.$name + "/auth/register/", data)
            .then(function (response) {
                if (response.data) {
                    toast.success("Your Registration successfully");
                    history.push("/sign-in");
                }
            })
            .catch(function (error) {
                // console.log(error.response.data.errors)
                if (error.response.data.errors) {
                    if (error.response.data.errors.name) {
                        toast.error("Full name - " +" "+ error.response.data.errors.name[0]);
                    } else if (error.response.data.errors.username) {
                        toast.error("Username - " +" "+ error.response.data.errors.username[0]);
                    } else if (error.response.data.errors.email) {
                        toast.error("Email - " +" "+ error.response.data.errors.email[0]);
                    } else if (error.response.data.errors.password) {
                        toast.error("Password - " +" "+ error.response.data.errors.password[0]);
                    } else {
                        toast.error(error.response);
                    }
                } else {
                    toast.error(error.response.data.detail);
                }
            });
}



