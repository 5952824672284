export const Routes = {
    // pages
    Presentation: {path: "/"},
    DashboardOverview: {path: "/dashboard/overview"},
    Transactions: {path: "/transactions"},
    Settings: {path: "/settings"},
    Profile: {path: "/profile"},
    Approval: {path: "/request/approval"},
    seminar: {path: "/seminar"},
    annualFee: {path: "/annual/fee"},
    payment: {path: "/payment"},
    Upgrade: {path: "/upgrade"},
    BootstrapTables: {path: "/tables/bootstrap-tables"},
    Billing: {path: "/billing"},
    BillingReceipt: {path: "/billing/receipt"},
    CashPayment: {path: "/billing/cash/payment"},
    // Invoice: {path: "/invoice"},
    Support: {path: "/support"},
    Signin: {path: "/sign-in"},
    Signup: {path: "/sign-up"},
    ForgotPassword: {path: "/forgot-password"},
    ResetPassword: {path: "/reset-password"},
    ChangePassword: {path: "/change-password"},
    ResendToken: {path: "/resend-token"},
    Lock: {path: "/lock"},
    NotFound: {path: "/404"},
    CancelUrl: {path: "/cancel/payment"},
    redirectPlanUrl: {path: "/ask/logout"},
    ServerError: {path: "/500"},

    // settings

    Roles: {path: "/roles"},
    Plan: {path: "/plans"},
    Setting: {path: "/setting-index"},

    // billing

    MemberPlan: {path: "/user/plan"},
    Subscription: {path: "/subscription"},
    Invoice: {path: "/invoice"},
    BillList: {path: "/member/bills"},
    AllBills: {path: "/bills/list"},
    InvoiceList: {path: "/invoice/list"},

    //staff
    Staff:{path:'/staff'},
  //events and training managements
    Event:{path:'/event'},
    Training:{path:'/training'},
    MyTraining:{path:'/training/by/user'},
    MyEvent:{path:'/event/by/user'},
    ListEvent:{path:'/list/event'},
    ListTraining:{path:'/list/training'},
    ClePoints:{path:'/cle/points'},
    SpeakerList:{path:'/speaker/list'},
    SpeakerAssigned:{path:'/speaker/assigned'},
    Claims:{path:'/claims/request'},
    Cle: {path: "/cle"},
    invitation: {path: "/invitation"},

    // docs
    DocsOverview: {path: "/documentation/overview"},
    DocsDownload: {path: "/documentation/download"},
    DocsQuickStart: {path: "/documentation/quick-start"},
    DocsLicense: {path: "/documentation/license"},
    DocsFolderStructure: {path: "/documentation/folder-structure"},
    DocsBuild: {path: "/documentation/build-tools"},
    DocsChangelog: {path: "/documentation/changelog"},

    // components
    Accordions: {path: "/components/accordions"},
    Alerts: {path: "/components/alerts"},
    Badges: {path: "/components/badges"},
    Widgets: {path: "/widgets"},
    Breadcrumbs: {path: "/components/breadcrumbs"},
    Buttons: {path: "/components/buttons"},
    Forms: {path: "/components/forms"},
    Modals: {path: "/components/modals"},
    Navs: {path: "/components/navs"},
    Navbars: {path: "/components/navbars"},
    Pagination: {path: "/components/pagination"},
    Popovers: {path: "/components/popovers"},
    Progress: {path: "/components/progress"},
    Tables: {path: "/components/tables"},
    Tabs: {path: "/components/tabs"},
    Tooltips: {path: "/components/tooltips"},
    Toasts: {path: "/components/toasts"},
    WidgetsComponent: {path: "/components/widgets"}
};