import {BILL_STATUS_DATA} from "./BillStatusActionTypes";

export default function billStatusReducer(state = [], action) {
    switch (action.type) {
        case BILL_STATUS_DATA:
            return action.payload;
        default:
            return state;
    }
}
