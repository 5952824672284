import Axios from "axios";
import {CASH_DATA} from "./CashActionTypes";
import {toast} from "react-toastify";

export function FetchCashPayment() {
    return async (dispatch) =>
        Axios.get(window.$name + '/payment/cash/request', {})
            .then(function (response) {
                // console.log("DATA", response.data)
                dispatch({type: CASH_DATA, payload: response.data});
                // console.log(response.data);
            })
            .catch(function (error) {
                if (error.response) {
                    toast.error(
                        `"${error.response.data}" Error Status  "${error.response.status}" `
                    );
                } else if (error.request) {
                    toast.error(`"${error.request}" `);
                } else {
                    toast.error(`"${error.message}" `);
                }
            });
}

export function ApproveCash(order) {
    const data = {
        bill_id: Number(order.bill_id.order_id),
        id: order.id,
        proccesed_by: order.proccesed_by
    };
    // console.log(data)
    return async (dispatch) =>
        Axios.post(window.$name + '/payment/cash/approve', data)
            .then(function (response) {
                if (response.status === 200) {
                    dispatch(FetchCashPayment())
                    toast.success(response.data.message);
                }
            })
            .catch(function (error) {
                if (error.response) {
                    toast.error("Something is wrong,Please contact us");
                }
            });
}

