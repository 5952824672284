import {EDIT_PLAN_DATA, PLAN_DATA} from "./planActionTypes";
export default function planReducer(state = [], action) {
  switch (action.type) {
    case PLAN_DATA:
      return action.payload;
    case EDIT_PLAN_DATA:
      return action.payload;
    default:
      return state;
  }
}
