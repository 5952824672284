import {DASHBOARD_DATA} from "./DashboardActionTypes";

export default function dashboardReducer(state = [], action) {
    switch (action.type) {
        case DASHBOARD_DATA:
            return action.payload;
        default:
            return state;
    }
}
