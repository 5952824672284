import Axios from "axios";
import {toast} from "react-toastify";
import {LOADING_DATA, LOGIN_DATA} from "./LoginActionTypes";
import {WORK_DATA} from "../workProfile/workActionTypes";
import {FetchUser} from "../AccountProfiles";
import {FetchRolesByName} from "../permissions";
import {PERMISSION_DATA} from "../permissions/permissionActionTypes";
import {Loader} from "../loader";


// import {FetchUserProfiles} from "../AccountProfiles";

export function userToken() {
    let information;
    information = {
        accessToken: localStorage.getItem("accessToken"),
        refreshToken: localStorage.getItem("refreshToken"),
        userId: localStorage.getItem("userId"),
        role: localStorage.getItem("role")
    }
    return information;
}

export function UserLogin(value, history, fromRoute) {
    const data = {
        email: value.email,
        password: value.password,
    };
    return async (dispatch) =>
        Axios.post(window.$name + "/auth/login/", data)
            .then(function (response) {
                console.log("response", response);
                if (response.status === 200) {
                    const LoginStatus = {
                        isLogin: true,
                    };
                    toast.success(
                        "Hi" +
                        " " +
                        `${response.data.email}` +
                        " " +
                        ",welcome to ZLS Wakili."
                    );
                    // console.log("response", response.data)
                    localStorage.setItem("refreshToken", response.data.tokens.refresh);
                    localStorage.setItem("userId", response.data.id);
                    localStorage.setItem("refreshToken", response.data.tokens.refresh);
                    localStorage.setItem("accessToken", response.data.tokens.refresh);
                    localStorage.setItem("role", response.data.username);
                    // localStorage.setItem("tokens", response.data.tokens.refresh);
                    userToken();
                    dispatch({type: LOGIN_DATA, payload: LoginStatus});
                    dispatch(Loader(false));
                    if (userToken()) {
                        // console.log("redirection here", userToken().userId)
                        // dispatch(FetchSettingProfile());
                        dispatch(FetchUser());
                        Axios.get(window.$name + `/auth/role/by-user/${userToken().role}`, {
                        })
                            .then(function (response) {
                                if (response.status === 200) {
                                    // console.log("DATA", response.data)
                                    dispatch({type: PERMISSION_DATA, payload: response.data});
                                    // dispatch(FetchUserPlan());
                                    if (fromRoute !== undefined) {
                                        let CurrentUrl = fromRoute.pathname;
                                        history.push(CurrentUrl);
                                    } else {
                                        history.push("/dashboard/overview");
                                    }
                                }
                                // console.log(response.data);
                            })
                            .catch(function (error) {
                                    if (error.response) {
                                        toast.error(
                                            `"${error.response.data}" Error Status  "${error.response.status}" `
                                        );
                                    } else if (error.request) {
                                        toast.error(`"${error.request}" `);
                                    } else {
                                        toast.error(`"${error.message}" `);
                                    }
                                }
                            );
                        // dispatch(FetchRolesByName(userToken().role))

                    }
                }
            })
            .catch(function (error) {
                if (error.response) {
                    console.log(error.response)
                    if (error.response.data) {
                        if (error.response.data.email) {
                            toast.error(error.response.data.email[0]);
                        } else if (error.response.data.password) {
                            toast.error(error.response.data.password[0]);
                        } else {
                            toast.error(error.response);
                        }
                    } else {
                        toast.error(error.response.data.detail);
                    }
                    toast.error(error.response.data.detail);
                }
            });
}


export function UserForgotPassword(email) {
    const data = {
        email: email,
        // redirect_url: "http://159.223.14.48:8001/Reset-password"
        // redirect_url: "https://zls-wakili-test.herokuapp.com/Reset-password"
        redirect_url: "https://wakili.zls.or.tz/Reset-password"
    };
    // console.log(data)
    return async (dispatch) =>
        Axios.post(window.$name + "/auth/request-reset-email/", data)
            .then(function (response) {
                // console.log("response", response);
                if (response.status === 200) {
                    toast.success(response.data.success);
                }
            })
            .catch(function (error) {
                if (error.response) {
                    // console.log(error.response)
                    if (error.response.data) {
                        if (error.response.data.email) {
                            toast.error(error.response.data.email[0]);
                        } else if (error.response.data.password) {
                            toast.error(error.response.data.password[0]);
                        } else {
                            toast.error(error.response);
                        }
                    } else {
                        toast.error(error.response.data.detail);
                    }
                    toast.error(error.response.data.detail);
                }
            });
}

export function UserResendVerificationToken(email) {
    const data = {
        email: email
    };
    // console.log(data)
    return async (dispatch) =>
        Axios.post(window.$name + "/auth/email-resend/", data)
            .then(function (response) {
                console.log("response", response);
                if (response.status === 200) {
                    toast.success(response.data.message);
                }
            })
            .catch(function (error) {
                // console.log("response", error.response.data.error);
                if (error.response) {
                    if (error.response.data) {
                            toast.error(error.response.data.error);
                    } else {
                        toast.error(error.response.data.detail);
                    }
                    toast.error(error.response.data.detail);
                }
            });
}

export function Logout(history) {
    Loader(false);
    history.push('/sign-in');
    window.localStorage.clear();

    return async (dispatch) =>
        dispatch({type: LOGIN_DATA, payload: { isLogin: false}});

}


