import React, {useEffect, useState} from 'react';
import {Button, Card, Dropdown, Image, Modal, Nav} from "@themesberg/react-bootstrap";
import Profile3 from "../assets/img/zls_default.png";
import {Link, useHistory} from "react-router-dom";
import {Routes} from "../routes";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUserCircle} from "@fortawesome/free-regular-svg-icons";
import {faSignOutAlt, faUserShield} from "@fortawesome/free-solid-svg-icons";
import {connect, useDispatch, useSelector} from "react-redux";
import {FetchPersonalProfiles} from "../store/personalProfile";
import {FetchUser} from "../store/AccountProfiles";
import {Logout} from "../store/Login";
import AsyncAction from "../AsyncAction";


function NavBarDropdown(props) {
    const {Logout} = props
    const dispatch = useDispatch();
    const history = useHistory();
    useEffect(() => {
        dispatch(FetchPersonalProfiles());
        dispatch(FetchUser());

    }, [dispatch]);
    const userProfile = useSelector((state) => state.userData);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    function LogoutUser() {
        setShow(true)
    }

    return (
        <div>
            <Dropdown as={Nav.Item}>
                <Dropdown.Toggle as={Nav.Link} className="pt-1 px-0">
                    <div className="media d-flex align-items-center">
                        {userProfile.image_url != null ?
                            <Image src={userProfile.image_url} className="user-avatar md-avatar rounded-circle"/>
                            :
                            <Image src={Profile3} className="user-avatar md-avatar rounded-circle"/>
                        }
                        <div className="media-body ms-2 text-dark align-items-center d-none d-lg-block">
                            <span className="mb-0 font-small fw-bold">{userProfile.name}</span>
                        </div>
                    </div>
                </Dropdown.Toggle>
                <Dropdown.Menu className="user-dropdown dropdown-menu-right mt-2">
                    <Dropdown.Item className="fw-bold">
                        <Card.Link as={Link} to={Routes.Profile.path} className="text-gray-700">
                            <FontAwesomeIcon icon={faUserCircle} className="me-2"/> My Profile
                        </Card.Link>
                    </Dropdown.Item>
                    <Dropdown.Item className="fw-bold">
                        <Card.Link as={Link} to={Routes.ChangePassword.path} className="text-gray-700">
                            <FontAwesomeIcon icon={faUserShield} className="me-2"/> Change Password
                        </Card.Link>
                    </Dropdown.Item>

                    <Dropdown.Divider/>

                    <Dropdown.Item className="fw-bold" onClick={() => LogoutUser()}>
                        <FontAwesomeIcon icon={faSignOutAlt} className="text-danger me-2"/>
                        Logout
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
            {/* <!-- Modal confirm logout --> */}
            <Modal
                show={show}
                onHide={handleClose}
            >
                <Modal.Body>
                    Are you sure want to logout ?
                </Modal.Body>
                <Modal.Footer>
                    <Button size="sm" variant="danger" onClick={handleClose}>
                        Close
                    </Button>
                    <AsyncAction
                        action={() => Logout(history)}
                        render={({run}) => (
                            <Button
                                size="sm" variant="success"
                                type="submit"
                                onClick={run}
                            >
                                Accept
                            </Button>
                        )}
                    />
                </Modal.Footer>
            </Modal>
        </div>


    )
}

const mapStateToProps = (state) => ({
    auth: state.auth.isLogin,
    personalProfile: state.personalProfile
});
const mapDispatchToProps = {
    Logout, FetchPersonalProfiles
};
export default connect(mapStateToProps, mapDispatchToProps)(NavBarDropdown);

