import Axios from "axios";
import {APPLY_EVENT_DATA} from "./MyEventActionTypes";
import {toast} from "react-toastify";
import {userToken} from "../Login";
import {decode as base64_decode, encode as base64_encode} from 'base-64';

export function FetchEventsByUser() {
    return async (dispatch) =>
        Axios.get(window.$name + `/events/by/user/${userToken().userId}`, {})
            .then(function (response) {
                // console.log("DATA", response.data)
                dispatch({type: APPLY_EVENT_DATA, payload: response.data});
                // console.log(response.data);
            })
            .catch(function (error) {
                if (error.response) {
                    toast.error(
                        `"${error.response.data}" Error Status  "${error.response.status}" `
                    );
                } else if (error.request) {
                    toast.error(`"${error.request}" `);
                } else {
                    toast.error(`"${error.message}" `);
                }
            });
}

export function ApplyEvents(data, date, auth, redirectUrl,current_url) {
    const billData = {
        item_id: data.id,
        type: "Event",
        amount: data.amount,
        status: "Not paid",
        created_by: userToken().userId,
        timestamp: date,
        redirectUrl: redirectUrl,
        key: auth
    }
    // console.log(billData);
    // /events/training/by/user/54
    return async (dispatch) =>
        Axios.post(window.$name + `/payment/create/bill/${userToken().userId}`, billData, {
            headers: {}
        }, {})
            .then(function (response) {
                if (response.status === 201) {
                    if (response.data.length > 0) {
                        // const EventData={
                        //     "status": "Not paid",
                        //     "user_id": userToken().userId,
                        //     "event_id":data.id,
                        //     "bill_id":response.data[0].id,
                        // }
                        localStorage.setItem('payment_url', response.data[1].data[0].payment_gateway_url);
                        localStorage.setItem('current_url', base64_encode(current_url));
                        toast.success("Event application ,successfully  we redirect to payment page");
                        window.location.href = base64_decode(response.data[1].data[0].payment_gateway_url);
                        // console.log(EventData)
                        // Axios.post(window.$name + `/events/add/by/user/${userToken().userId}`, EventData, {
                        //     headers: {}
                        // }, {})
                        //     .then(function (response) {
                        //         if (response.status === 201) {
                        //             dispatch(FetchEventsByUser())
                        //             // dispatch({type: APPLY_TRAINING_DATA, payload: response.data})
                        //         }
                        //     })
                        //     .catch(function (error) {
                        //         if (error.response) {
                        //             toast.error("Something is wrong,Please contact us");
                        //         }
                        //     });
                    }
                }
            })
            .catch(function (error) {
                if (error.response) {
                    toast.error("Something is wrong,Please contact us");
                }
            });
}