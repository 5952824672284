import React from "react";
import {Header} from "../components/Header";


function Support() {

    return (
        <main>
            <Header/>
            {/*<p>Your can reach us by providing your input here</p>*/}
            <section className="vh-100 d-flex align-items-center justify-content-center">
                <iframe width="600" height="850"
                        src="https://getcore.getcrm.co.tz/forms/ticket?styled=1&department_id=1" frameBorder="0"
                        allowFullScreen></iframe>
            </section>
        </main>
    );
}

export default Support;
