import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleLeft, faUnlockAlt} from "@fortawesome/free-solid-svg-icons";
import {Button, Card, Col, Container, Form, InputGroup, Row} from '@themesberg/react-bootstrap';
import {Link, useHistory, useLocation} from 'react-router-dom';

import {Routes} from "../../routes";
import {Header} from "../components/Header";
import Axios from "axios";
import {toast} from "react-toastify";
import {useFormik} from "formik";
import * as yup from "yup";

const validationSchema = yup.object().shape({
    password: yup
        .string()
        .required("Please enter your New password")
        .matches(/^.*(?=.{8,}).*$/, "Password must contain at least 8 characters"),
    confirm_password: yup
        .string()
        .required("Please confirm your New password")
        .when("password", {
            is: (password) =>
                password && password.length > 0 ? true : false,
            then: yup
                .string()
                .oneOf([yup.ref(" password")], "Password doesn't match"),
        }),
});

export default () => {
    const sampleLocation = useLocation();
    // console.log(sampleLocation.search.substring(66))
    const token = sampleLocation.search.substring(66)
    const uid = sampleLocation.search.substring(59, 53)

    const history = useHistory();
    const formik = useFormik({
        initialValues: {
            password: "",
            confirm_password: "",
        },
        validationSchema,
    });

    console.log(uid)
    console.log(token)

    function ChangePassword(value) {
        //
        const data = {
            password: value,
            token: token,
            uidb64: uid
        }
        console.log(data)
        Axios.patch(window.$name + `/auth/password-reset-complete`, data, {
            headers: {}
        }, {})
            .then(res => {
                if (res.status === 200) {
                    toast.success("Password reset success")
                  history.push("sign-in")
                }
            }) .catch(function (error) {
          if (error.response) {
            // console.log(error.response)
            toast.error(error.response.data.detail);
          }
        });
    }

    return (
        <main>
            <Header></Header>
            <section className="bg-soft d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
                <Container>
                    <Row className="justify-content-center">
                        <p className="text-center">
                            <Card.Link as={Link} to={Routes.Signin.path} className="text-gray-700">
                                <FontAwesomeIcon icon={faAngleLeft} className="me-2"/> Back to sign in
                            </Card.Link>
                        </p>
                        <Col xs={12} className="d-flex align-items-center justify-content-center">
                            <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                                <h3 className="mb-4">Reset password</h3>
                                {/*<Form>*/}

                                <Form.Group id="password" className="mb-4">
                                    <Form.Label> Password</Form.Label>
                                    <InputGroup>
                                        <InputGroup.Text>
                                            <FontAwesomeIcon icon={faUnlockAlt}/>
                                        </InputGroup.Text>
                                        <input
                                            type="password"
                                            className="form-control mb-0"
                                            placeholder="Password"
                                            id="password"
                                            name="password"
                                            value={formik.values.password}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                    </InputGroup>
                                    {formik.touched.password && formik.errors.password ? (
                                        <div className="text-danger">
                                            {formik.errors.password}
                                        </div>
                                    ) : null}
                                </Form.Group>
                                <Form.Group id="confirmPassword" className="mb-4">
                                    <Form.Label>Confirm Password</Form.Label>
                                    <InputGroup>
                                        <InputGroup.Text>
                                            <FontAwesomeIcon icon={faUnlockAlt}/>
                                        </InputGroup.Text>
                                        <input
                                            type="password"
                                            className="form-control mb-0"
                                            placeholder="Password"
                                            id="confirm_password"
                                            name="confirm_password"
                                            value={formik.values.confirm_password}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                    </InputGroup>
                                    {formik.touched.confirm_password && formik.errors.confirm_password ? (
                                        <div className="text-danger">
                                            {formik.errors.confirm_password}
                                        </div>
                                    ) : null}
                                </Form.Group>
                                <Button variant="primary" type="submit" className="w-100"
                                        disabled={!(formik.isValid && formik.dirty)}
                                        onClick={() => ChangePassword(formik.values.password)}>
                                    Reset password
                                </Button>
                                {/*</Form>*/}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </main>
    );
};
