import {Button, Card, Col, Form, InputGroup, Row} from "@themesberg/react-bootstrap";
import React, {useState} from "react";
import {useFormik} from "formik";
import * as yup from "yup";
import AsyncAction from "../../../AsyncAction";
import classNames from "classnames";
import {EditPersonalProfile} from "../../../store/personalProfile";
import {connect, useDispatch, useSelector} from "react-redux";
import Axios from "axios";
import {userToken} from "../../../store/Login";
import {FetchUser} from "../../../store/AccountProfiles";
import {Loader} from "../../../store/loader";

const validationSchema = yup.object().shape({
    Date_birth: yup.string().required("This field is required"),
    Phone: yup.string().required("This field is required"),
    gender: yup.string().required("This field is required"),
    level: yup.string().required("This field is required"),
    marital: yup.string().required("This field is required"),
    chapter: yup.string().required("This field is required"),
    Spouse_name: yup.string().required("This field is required"),
    Passport_no: yup.string(),
    health_challenges: yup.string(),
    National_id_no: yup.string().required("This field is required"),
    specialization: yup.string().required("This field is required"),
    name: yup.string().required("This field is required"),
    email: yup.string().required("This field is required"),
});

function EditPersonalDetails(props) {
    const {EditPersonalProfile, personalInfo, userProfile, setActive,planData} = props
    const dispatch = useDispatch()
    console.log("new",personalInfo.level);
    //using useFormik
    const formik = useFormik({
        initialValues: {
            Date_birth: personalInfo.Date_of_Birth,
            gender: personalInfo.Gender,
            marital: personalInfo.Marital_status,
            Phone: personalInfo.Phone,
            chapter: personalInfo.Chapter,
            level: personalInfo.level,
            status: personalInfo.status,
            Spouse_name: personalInfo.Spouse_name,
            Passport_no: personalInfo.Passport_no,
            health_challenges: personalInfo.health_challenges,
            National_id_no: personalInfo.National_id_no,
            specialization: personalInfo.specialization,
            id: personalInfo.id,
            name: userProfile.name,
            username: userProfile.username,
            email: personalInfo.email
        },
        validationSchema,
    });
    const [data, setData] = useState({
        image_url: "",
    });

    const handleImageChange = (e) => {
        let newData = {...data};
        newData["image_url"] = e.target.files[0];
        setData(newData);
    };
    const isLoading = useSelector((state) => state.isLoading);

// console.log(userProfile)
    function EditProfile() {
        dispatch(Loader(true));
        let form_data = new FormData();
        if (data.image_url)
            form_data.append("image_url", data.image_url, data.image_url.name);
        form_data.append("email", userProfile.email);
        form_data.append("username", formik.values.username);
        // form_data.append("image_url", data.image_url);
        form_data.append("name", formik.values.name);

        const Request = Axios.patch(window.$name + `/auth/user/${userToken().userId}`, form_data, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        }).then((res) => {
            // console.log(res);
            if (res.status === 200) {
                // toast.success("Profile update success fully");
                // handleShowEditForm(false);
                dispatch(FetchUser())
                EditPersonalProfile(formik.values);
                setActive(false);
                formik.resetForm({})
            }
            return res;
        }).catch((error) => {
            return error.response;
        });
        return Request;
    }

    return (
        <>
            <Card border="light" className="bg-white shadow-sm mt-3">
                <Card.Body>
                    <Row>

                        <Col md={6} className="mb-3">
                            <div className="form-group">
                                <Form.Label>Full Name</Form.Label>
                                <input
                                    type="text"
                                    className="form-control mb-0"
                                    autoComplete="off"
                                    id="name"
                                    name="name"
                                    value={formik.values.name}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.name && formik.errors.name ? (
                                    <div className="text-danger">
                                        {formik.errors.name}
                                    </div>
                                ) : null
                                }
                            </div>
                        </Col>
                        <Col md={6} className="mb-3">
                            <div className="form-group">
                                <Form.Label>Upload Profile Photo</Form.Label>
                                <Form.Control
                                    type="file"
                                    name="image_url"
                                    accept="image/jpeg,image/png,image/gif"
                                    onChange={(e) => {
                                        handleImageChange(e);
                                    }}
                                />

                            </div>
                        </Col>

                        <Col md={6} className="mb-3">
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Email</Form.Label>
                                <input
                                    type="text"
                                    className="form-control mb-0"
                                    autoComplete="off"
                                    id="email"
                                    name="email"
                                    disabled
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.email && formik.errors.email ? (
                                    <div className="text-danger">
                                        {formik.errors.email}
                                    </div>
                                ) : null
                                }
                            </Form.Group>
                        </Col>
                        <Col md={6} className="mb-3">
                            <div className="form-group">
                                <Form.Label>Phone</Form.Label>
                                <input
                                    type="number"
                                    className="form-control mb-0"
                                    autoComplete="off"
                                    id="Phone"
                                    name="Phone"
                                    placeholder="eg.255 767 456 546"
                                    value={formik.values.Phone}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.Phone && formik.errors.Phone ? (
                                    <div className="text-danger">
                                        {formik.errors.Phone}
                                    </div>
                                ) : null
                                }
                            </div>
                        </Col>
                        <Col md={6} className="mb-3">
                            <div className="form-group">
                                <Form.Label>Date of Birth</Form.Label>
                                <input
                                    type="date"
                                    className="form-control mb-0"
                                    autoComplete="off"
                                    id="Date_birth"
                                    name="Date_birth"
                                    value={formik.values.Date_birth}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.Date_birth && formik.errors.Date_birth ? (
                                    <div className="text-danger">
                                        {formik.errors.Date_birth}
                                    </div>
                                ) : null
                                }
                            </div>
                        </Col>
                        <Col md={6} className="mb-3">
                            <div className="form-group">
                                <Form.Label>Gender</Form.Label>
                                <Form.Select
                                    className="form-control mb-0"
                                    onChange={formik.handleChange}
                                    id="gender"
                                    name="gender"
                                    onBlur={formik.handleBlur}
                                    value={formik.values.gender}
                                >
                                    <option value="">Select gender</option>
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                </Form.Select>
                                {formik.touched.gender && formik.errors.gender ? (
                                    <div className="text-danger">{formik.errors.gender}</div>
                                ) : null}
                            </div>
                        </Col>
                        <Col md={6} className="mb-3">
                            <div className="form-group">
                                <Form.Label>Marital Status</Form.Label>
                                <Form.Group id="marital">
                                    <Form.Select
                                        defaultValue=""
                                        onChange={formik.handleChange}
                                        id="marital"
                                        name="marital"
                                        onBlur={formik.handleBlur}
                                        value={formik.values.marital}

                                    >
                                        <option value="">
                                            Select Marital status
                                        </option>
                                        <option value="Divorced">Divorced</option>
                                        <option value="Single">Single</option>
                                        <option value="Widow">Widow</option>
                                        <option value="Married">Married</option>
                                        <option value="Widower">Widower</option>
                                    </Form.Select>
                                </Form.Group>
                                {formik.touched.marital && formik.errors.marital ? (
                                    <div className="text-danger">{formik.errors.marital}</div>
                                ) : null}
                            </div>
                        </Col>
                        <Col md={6} className="mb-3">
                            <div className="form-group">
                                <Form.Label>Spouse Name</Form.Label>
                                <input
                                    type="text"
                                    className="form-control mb-0"
                                    autoComplete="off"
                                    id="Spouse_name"
                                    name="Spouse_name"
                                    placeholder="eg. John Doe"
                                    value={formik.values.Spouse_name}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.Spouse_name && formik.errors.Spouse_name ? (
                                    <div className="text-danger">
                                        {formik.errors.Spouse_name}
                                    </div>
                                ) : null
                                }
                            </div>
                        </Col>
                        <Col md={12} className="mb-3">


                            <div className="form-group">
                                <Form.Label>Member Level</Form.Label>
                                <Form.Select
                                    className="form-control mb-0"
                                    onChange={formik.handleChange}
                                    disabled={personalInfo.level !== 1 && personalInfo.status !=='False'}
                                    id="level"
                                    name="level"
                                    onBlur={formik.handleBlur}
                                    value={formik.values.level}
                                >
                                    <option value="">Select your level</option>
                                    {planData.map((level) => (
                                        <option value={level.id}>{level.name}</option>
                                    ))
                                    }
                                </Form.Select>
                                {formik.touched.level && formik.errors.level ? (
                                    <div className="text-danger">{formik.errors.level}</div>
                                ) : null}
                            </div>
                        </Col>
                        <Col md={6} className="mb-3">
                            <div className="form-group">
                                <Form.Label>National Id / Zanzibar Id &nbsp;(No dashes)
                                </Form.Label>
                                <input
                                    type="text"
                                    className="form-control mb-0"
                                    autoComplete="off"
                                    id="National_id_no"
                                    name="National_id_no"
                                    placeholder="eg. 655465475745745"
                                    value={formik.values.National_id_no}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.tin && formik.errors.National_id_no ? (
                                    <div className="text-danger">
                                        {formik.errors.National_id_no}
                                    </div>
                                ) : null
                                }
                            </div>
                        </Col>

                        <Col md={6} className="mb-3">
                            <div className="form-group">
                                <Form.Label>Passport No (Optional)</Form.Label>
                                <input
                                    type="text"
                                    className="form-control mb-0"
                                    autoComplete="off"
                                    id="Passport_no"
                                    name="Passport_no"
                                    placeholder="eg. TAR040547"
                                    value={formik.values.Passport_no}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.Passport_no && formik.errors.Passport_no ? (
                                    <div className="text-danger">
                                        {formik.errors.Passport_no}
                                    </div>
                                ) : null
                                }
                            </div>
                        </Col>

                        <Col md={6} className="mb-3">
                            <div className="form-group">
                                <Form.Label>Chapter</Form.Label>
                                <select
                                    className="form-control mb-0"
                                    onChange={formik.handleChange}
                                    id="chapter"
                                    name="chapter"
                                    onBlur={formik.handleBlur}
                                    value={formik.values.chapter}
                                >
                                    <option value="">Select chapter</option>
                                    <optgroup label="Zanzibar Zone">
                                        <option value="Pemba">Pemba</option>
                                        <option value="Unguja">Unguja</option>
                                    </optgroup>
                                </select>
                                {formik.touched.chapter && formik.errors.chapter ? (
                                    <div className="text-danger">{formik.errors.chapter}</div>
                                ) : null}
                            </div>
                        </Col>

                        <Col md={6} className="mb-3">
                            <div className="form-group">
                                <Form.Label>Area of specialization</Form.Label>
                                <input
                                    type="text"
                                    className="form-control mb-0"
                                    autoComplete="off"
                                    id="specialization"
                                    name="specialization"
                                    placeholder="eg. Intellectual Property Law"
                                    value={formik.values.specialization}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.specialization && formik.errors.specialization ? (
                                    <div className="text-danger">
                                        {formik.errors.specialization}
                                    </div>
                                ) : null
                                }
                            </div>
                        </Col>
                        <Col md={12} className="mb-3">
                            <div className="form-group">
                                <Form.Label>State any health challenges/chronic disease/disability you wish to
                                    disclose
                                    (optional)</Form.Label>
                                <textarea
                                    className="form-control mb-0"
                                    autoComplete="off"
                                    id="health_challenges"
                                    name="health_challenges"
                                    placeholder="eg. Diabetic"
                                    value={formik.values.health_challenges}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.health_challenges && formik.errors.health_challenges ? (
                                    <div className="text-danger">
                                        {formik.errors.health_challenges}
                                    </div>
                                ) : null
                                }
                            </div>
                        </Col>
                    </Row>
                    <div className="mt-2" style={{float: 'right'}}>
                        {
                            !isLoading.isLoading ?
                                <Button
                                    type="submit"
                                    size="sm"
                                    data-toggle="tooltip"
                                    data-placement="right"
                                    title="sign-in"
                                    variant="dark"
                                    onClick={() => EditProfile()}
                                    className="w-100"
                                >
                                    Save changes
                                </Button>
                                :
                                <Button
                                    type="submit"
                                    size="sm"
                                    data-toggle="tooltip"
                                    data-placement="right"
                                    title="sign-in"
                                    variant="dark"
                                    disabled
                                    className="w-100"
                                >
                                    Saving....
                                </Button>
                        }


                    </div>
                </Card.Body>
            </Card>
        </>
    );

}

const mapDispatchToProps =
    {
        EditPersonalProfile, Loader
    }
;

export default connect(() => (
    {}
), mapDispatchToProps)(EditPersonalDetails);



