import React, {Fragment} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope, faUnlockAlt} from "@fortawesome/free-solid-svg-icons";
import {Link, useHistory, useLocation} from "react-router-dom";
import {Button, Card, Col, Container, Form, FormCheck, InputGroup, Row} from '@themesberg/react-bootstrap';
import {Routes} from "../../routes";
import {Header} from "../components/Header";
import * as yup from "yup";
import {useFormik} from "formik";
import {connect} from "react-redux";
import AsyncAction from "../../AsyncAction";
// import classNames from "classnames";
import {UserLogin} from "../../store/Login";
import Moment from "moment";

const validationSchema = yup.object().shape({
    email: yup.string().required("This field is required"),
    password: yup.string().required("This field is required"),
});

function Login(props) {
    const location = useLocation();
    // console.log(location)
    const history = useHistory();
    const {UserLogin} = props
    //using useFormik
    const formik = useFormik({
        initialValues: {
            email: "",
            password: "",
        },
        validationSchema,
    });
    return (
        <main>
            <Header/>
            <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">

                <Container>
                    <Row className="justify-content-center form-bg-image">
                        <Col xs={12} className="d-flex align-items-center justify-content-center">
                            <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                                <div className="text-center text-md-center mb-4 mt-md-0">
                                    <h2 className="text-3xl font-extrabold leading-9 text-center text-gray-900 lg:text-5xl">Sign
                                        in Below </h2>
                                </div>
                                {/*<Form className="mt-4">*/}
                                <Form.Group id="email" className="mb-4">
                                    <Form.Label>Your Email</Form.Label>
                                    <InputGroup>
                                        <InputGroup.Text>
                                            <FontAwesomeIcon icon={faEnvelope}/>
                                        </InputGroup.Text>
                                        <input
                                            type="email"
                                            className="form-control mb-0"
                                            placeholder="Enter email"
                                            autoComplete="off"
                                            id="email"
                                            name="email"
                                            value={formik.values.email}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                    </InputGroup>
                                    {formik.touched.email && formik.errors.email ? (
                                        <div className="text-danger">
                                            {formik.errors.email}
                                        </div>
                                    ) : null}
                                </Form.Group>
                                <Form.Group>
                                    <Form.Group id="password" className="mb-4">
                                        <Form.Label>Your Password</Form.Label>
                                        <InputGroup>
                                            <InputGroup.Text>
                                                <FontAwesomeIcon icon={faUnlockAlt}/>
                                            </InputGroup.Text>
                                            <input
                                                type="password"
                                                className="form-control mb-0"
                                                placeholder="Password"
                                                id="password"
                                                name="password"
                                                value={formik.values.password}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        </InputGroup>
                                        {formik.touched.password && formik.errors.password ? (
                                            <div className="text-danger">
                                                {formik.errors.password}
                                            </div>
                                        ) : null}
                                    </Form.Group>
                                    <div className="d-flex justify-content-between align-items-center mb-4">
                                        <Form.Check type="checkbox">
                                            <FormCheck.Input id="defaultCheck5" className="me-2"/>
                                            <FormCheck.Label htmlFor="defaultCheck5" className="mb-0">Remember
                                                me</FormCheck.Label>
                                        </Form.Check>
                                        <Card.Link className="small text-end" as={Link} to={Routes.ForgotPassword.path}>Lost
                                            password?</Card.Link>
                                    </div>
                                </Form.Group>
                                {
                                    location.pathname === "/login" && location.state !== undefined ?

                                        <AsyncAction
                                            action={() => UserLogin(formik.values, history, location.state.from)}
                                            render={({run, loading}) => (
                                                <Button
                                                    type="submit"
                                                    data-toggle="tooltip"
                                                    data-placement="right"
                                                    title="login"
                                                    onClick={run}
                                                    disabled={!(formik.isValid && formik.dirty)}
                                                    className="w-100"
                                                >
                                                    Sign in
                                                </Button>
                                            )}
                                        />
                                        :
                                        <AsyncAction
                                            action={() => UserLogin(formik.values, history, location.state)}
                                            render={({run}) => (
                                                <Button
                                                    type="submit"
                                                    data-toggle="tooltip"
                                                    data-placement="right"
                                                    title="login"
                                                    onClick={run}
                                                    disabled={!(formik.isValid && formik.dirty)}
                                                    className="w-100"
                                                >
                                                    Sign in
                                                </Button>
                                            )}
                                        />
                                }
                                <div className="d-flex mt-4">
                  <span className="fw-normal">
                    Not registered?
                    <Card.Link as={Link} to={Routes.Signup.path} className="fw-bold">
                      {` Create account `}
                    </Card.Link>
                  </span>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </main>
    );
}

const mapDispatchToProps = {
    UserLogin
};

export default connect(() => ({}), mapDispatchToProps)(Login);


