import React, {useEffect, useState} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCog, faHome, faSearch } from '@fortawesome/free-solid-svg-icons';
import {
    Col,
    Row,
    Form,
    Button,
    ButtonGroup,
    Breadcrumb,
    InputGroup,
    Dropdown,
    Card,
    Table
} from '@themesberg/react-bootstrap';
import {Logout} from "../../../store/Login";
import {FetchPersonalProfiles} from "../../../store/personalProfile";
import {connect, useDispatch, useSelector} from "react-redux";
import {pageTraffic} from "../../../data/tables";
import {Routes} from "../../../routes";
import {Link} from "react-router-dom";
import {FetchPlans} from "../../../store/plan/planActions";
import {FetchBillByUser} from "../../../store/Bills";
import Grid from "@mui/material/Grid";
import Moment from "moment";
import CurrencyFormat from "react-currency-format";
import {FetchSpeakerById} from "../../../store/Speaker";


function MyPoints() {
    const dispatch =useDispatch();

    useEffect(() => {
        dispatch(FetchSpeakerById());

    }, [dispatch]);

    const speakerData = useSelector((state) => state.speakers);
    console.log(speakerData)
    //Search Filter Data
    const [searchQuery, setSearchQuery] = useState("")

    function search() {
        if(speakerData.length >0){
            return  speakerData.filter(row => row.cle_points.toLowerCase().indexOf(searchQuery) > -1)
        }
        else {
            return []
        }

    }
    return (
        <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="d-block mb-4 mb-md-0">
                    <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
                        <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
                        <Breadcrumb.Item>Home</Breadcrumb.Item>
                        <Breadcrumb.Item active>Speaker</Breadcrumb.Item>
                    </Breadcrumb>
                    <h4>Speaker</h4>
                    {/*<p className="mb-0">Your web analytics dashboard template.</p>*/}
                </div>
                <div className="btn-toolbar mb-2 mb-md-0">
                    <ButtonGroup>
                        <Button variant="outline-primary" size="sm">Share</Button>
                        <Button variant="outline-primary" size="sm">Export</Button>
                    </ButtonGroup>
                </div>
            </div>

            <div className="table-settings mb-4">
                <Row className="justify-content-between align-items-center">
                    <Col xs={8} md={6} lg={3} xl={4}>
                        <InputGroup>
                            <InputGroup.Text>
                                <FontAwesomeIcon icon={faSearch} />
                            </InputGroup.Text>
                            <Form.Control
                                type="text"
                                placeholder="Search"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />
                        </InputGroup>
                    </Col>
                    <Col xs={4} md={2} xl={1} className="ps-md-0 text-end">
                        <Dropdown as={ButtonGroup}>
                            <Dropdown.Toggle split as={Button} variant="link" className="text-dark m-0 p-0">
                <span className="icon icon-sm icon-gray">
                  <FontAwesomeIcon icon={faCog} />
                </span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dropdown-menu-xs dropdown-menu-right">
                                <Dropdown.Item className="fw-bold text-dark">Show</Dropdown.Item>
                                <Dropdown.Item className="d-flex fw-bold">
                                    10 <span className="icon icon-small ms-auto"><FontAwesomeIcon icon={faCheck} /></span>
                                </Dropdown.Item>
                                <Dropdown.Item className="fw-bold">20</Dropdown.Item>
                                <Dropdown.Item className="fw-bold">30</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                </Row>
            </div>

            <Card border="light" className="shadow-sm mb-4">
                <Card.Body className="pb-0">
                    <Table  responsive className="table-centered  table-nowrap rounded mb-0">
                        <thead className="thead-light">
                        <tr>
                            <th className="border-0">#</th>
                            <th className="border-0">Name</th>
                            <th className="border-0">Points</th>
                            <th className="border-0">Sources</th>
                            <th className="border-0">Created date</th>
                        </tr>
                        </thead>
                        <tbody>
                        {search(speakerData).map((index,key) => (
                            <tr>
                                <td>
                                    {
                                        key + 1
                                    }
                                </td>
                                    <td className="text-uppercase">{index.speaker_id.name}</td>
                                    <td>{index.event_id.cle_points}</td>
                                    <td>{index.event_id.title}</td>
                                    <td>{Moment(index.created_at).format('DD-MM-YYYY')}</td>
                                </tr>
                            )
                        )}

                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        </>
    );
};

const mapDispatchToProps = {
    Logout, FetchPersonalProfiles
};
export default connect(() => ({}), mapDispatchToProps)(MyPoints);





