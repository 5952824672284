import {EDIT_SPEAKER_DATA, SPEAKER_DATA} from "./speakerActionTypes";
export default function speakerReducer(state = [], action) {
  switch (action.type) {
    case SPEAKER_DATA:
      return action.payload;
    case EDIT_SPEAKER_DATA:
      return action.payload;
    default:
      return state;
  }
}
