import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheck, faCheckDouble, faCog, faHome, faSearch} from '@fortawesome/free-solid-svg-icons';
import {
    Col,
    Row,
    Form,
    Button,
    ButtonGroup,
    Breadcrumb,
    InputGroup,
    Dropdown,
    Card,
    Table
} from '@themesberg/react-bootstrap';
import {Logout, userToken} from "../../../store/Login";
import {FetchPersonalProfiles} from "../../../store/personalProfile";
import {connect, useDispatch, useSelector} from "react-redux";
import {pageTraffic} from "../../../data/tables";
import {Routes} from "../../../routes";
import {Link} from "react-router-dom";
import {FetchPlans} from "../../../store/plan/planActions";
import {FetchBillByUser} from "../../../store/Bills";
import Grid from "@mui/material/Grid";
import Moment from "moment";
import CurrencyFormat from "react-currency-format";
import {decode as base64_decode} from "base-64";
import {forEach} from "@themesberg/react-bootstrap/lib/cjs/ElementChildren";
import {FetchBillByStatus} from "../../../store/BillReceipt";
import BillTable from "./BillTable";

function ReceiptList() {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(FetchBillByStatus(userToken().userId,'COMPLETED'));

    }, [dispatch]);

    function Payment(data) {
        console.log("data", data)
        window.location.href = base64_decode(data)
    }

    const billData = useSelector((state) => state.billReceipt);
    //Search Filter Data
    const [searchQuery, setSearchQuery] = useState("")

    function search() {
        return billData.filter(row => row.order_id.toLowerCase().indexOf(searchQuery) > -1)

    }

    return (
        <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="d-block mb-4 mb-md-0">
                    <Breadcrumb className="d-none d-md-inline-block"
                                listProps={{className: "breadcrumb-dark breadcrumb-transparent"}}>
                        <Breadcrumb.Item><FontAwesomeIcon icon={faHome}/></Breadcrumb.Item>
                        <Breadcrumb.Item>Home</Breadcrumb.Item>
                        <Breadcrumb.Item active>Payments</Breadcrumb.Item>
                    </Breadcrumb>
                    <h4>Receipts </h4>

                    {/*<p className="mb-0">Your web analytics dashboard template.</p>*/}
                </div>
                <div className="btn-toolbar mb-2 mb-md-0">
                    <ButtonGroup>
                        <Button variant="outline-primary" size="sm">Share</Button>
                        <Button variant="outline-primary" size="sm">Export</Button>
                    </ButtonGroup>
                </div>
            </div>

            <BillTable BillData={billData}/>
        </>
    );
};

const mapDispatchToProps = {
    Logout, FetchPersonalProfiles
};
export default connect(() => ({}), mapDispatchToProps)(ReceiptList);





