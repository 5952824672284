import React, {useEffect, useState} from "react";
import {AddEducationProfile, DeleteEducation} from "../../../store/eductionProfile";
import {connect, useDispatch, useSelector} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBook, faCalendarAlt, faSearch} from "@fortawesome/free-solid-svg-icons";
import {FetchEvents} from "../../../store/Events";
import CurrencyFormat from 'react-currency-format';
import {ApplyEvents} from "../../../store/MyEvents";
import Moment from "moment";
import {Button, Col, Form, InputGroup, Row} from "@themesberg/react-bootstrap";
import ReactReadMoreReadLess from "react-read-more-read-less";
import {useHistory} from "react-router-dom";

function ListEvent() {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(FetchEvents());

    }, [dispatch]);

    const eventData = useSelector((state) => state.event);

    const auth = ''
    const current = new Date();
    const date = current;
    const formated_date = Moment(date).toISOString()
    const history = useHistory();
    // console.log("hidtory",);
    const redirectUrl = 'https://wakili.zls.or.tz/cancel/payment';
    const current_url = window.$nameweb + history.location.pathname;

    function Apply(value) {
        dispatch(ApplyEvents(value, formated_date, auth, redirectUrl, current_url));
    }

//Search Filter Data
    const [searchQuery, setSearchQuery] = useState("")

    function search() {
        return eventData.filter(row => row.title.toLowerCase().indexOf(searchQuery) > -1)

    }

    return (
        <div className='mt-5'>
            <div className="table-settings mb-4">
                <Row className="justify-content-between align-items-center">
                    <Col xs={6} md={6} xl={4} className="ps-md-0">
                        <h4>
                            <FontAwesomeIcon icon={faBook} className="icon icon-xs text-dark w-30"/>
                            Zanzibar Law Society Event
                        </h4>
                    </Col>
                    <Col xs={6} md={6} lg={3} xl={4}>
                        <InputGroup>
                            <InputGroup.Text>
                                <FontAwesomeIcon icon={faSearch}/>
                            </InputGroup.Text>
                            <Form.Control
                                type="text"
                                placeholder="Search"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />
                        </InputGroup>
                    </Col>
                </Row>
            </div>
            <div>
                <div id="cards_landscape_wrap-2">
                    <div className="container-fluid">
                        <div className="row">
                            {search(eventData).map((index) => (
                                <div
                                    key={index.id}
                                    className="col-sm-12 col-md-6 mt-2"
                                >
                                    <div className="card">
                                        <div className="text-box">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-6">
                                                        <img
                                                            className="card-img-top"
                                                            src={index.image_url}
                                                            style={{height: 160}}
                                                            alt=""/>
                                                    </div>
                                                    <div className="col-6">
                                                        <p className="text-capitalize">
                                                            <h6>
                                                                <b>
                                                                    <ReactReadMoreReadLess
                                                                        charLimit={45}
                                                                        readMoreText={"Read more▼"}
                                                                        readLessText={"Read less▲"}
                                                                    >
                                                                        {index.title}
                                                                    </ReactReadMoreReadLess>
                                                                </b>
                                                            </h6>


                                                        </p>

                                                        {/*<h5 className="card-title text-capitalize">*/}
                                                        {/*    {index.title.length > 40 ?*/}
                                                        {/*        `${index.title.substring(0, 40)}...` : index.title*/}
                                                        {/*    }</h5>*/}
                                                        <ReactReadMoreReadLess
                                                            charLimit={70}
                                                            readMoreText={"Read more▼"}
                                                            readLessText={"Read less▲"}
                                                        >
                                                            {index.description}
                                                        </ReactReadMoreReadLess>
                                                        {/*<p className="card-text">*/}
                                                        {/*    {index.description.length > 80 ?*/}
                                                        {/*        `${index.description.substring(0, 80)}...` : index.description*/}
                                                        {/*    }*/}
                                                        {/*    <span className="text-info">Read More</span>*/}
                                                        {/*</p>*/}

                                                        <p>
                                                        <span className='text-sm-start text-dark'><b>Venue : </b>
                                                            &nbsp;
                                                            {index.type === 'online' ?
                                                                <>
                                                                    webinar(online)
                                                                </>
                                                                :
                                                                <>  {index.location}</>
                                                            }
                                                    </span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-footer">
                                            <div className="row">
                                                <div className="col-md-9 flex float-start">
                                                    <span className='text-sm-start text-primary'> <FontAwesomeIcon
                                                        icon={faCalendarAlt}/>    &nbsp; {index.event_date}
                                                        </span>
                                                    &nbsp;
                                                    &nbsp;
                                                    <span className='text-sm-start text-dark'><b>Fee : </b></span>
                                                    &nbsp;
                                                    {index.is_free === 'false' ?
                                                        <span
                                                            className="badge badge-lg bg-success text-dark"> FREE </span>
                                                        :
                                                        <CurrencyFormat
                                                            value={index.amount}
                                                            displayType={'text'}
                                                            thousandSeparator={true}
                                                            prefix={' TZS '}
                                                            renderText={value => <span>{value}</span>}/>
                                                    }
                                                </div>
                                                <div className="col flex float-end">
                                                    {index.is_free === 'false' ?
                                                        <>
                                                            {index.type === 'online' ?
                                                                <Button size="sm" variant="outline-dark">
                                                                    <a target="_blank"
                                                                       href={index.link}> Register</a>
                                                                </Button>
                                                                : null
                                                            }
                                                        </>
                                                        :
                                                        <Button size="sm" variant="outline-dark"
                                                                onClick={() => Apply(index)}>
                                                            Register
                                                        </Button>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapDispatchToProps = {
    AddEducationProfile, DeleteEducation
};

export default connect(() => ({}), mapDispatchToProps)(ListEvent);
