import Axios from "axios";
import {WORK_DATA} from "./workActionTypes";
import {toast} from "react-toastify";
import {userToken} from "../Login";

export function AddWork(value) {
    const data = {
        user_id: userToken().userId,
        business_location: value.business_location,
        employer: value.employer,
        number_of_advocates: value.number_of_advocates,
        tin: value.tin,
        telephone: value.telephone,
        email: value.email,
        address: value.address,
        category: value.category,
        website: value.website,
    };
    return async (dispatch) =>
        Axios.post(window.$name + `/profile/work/information/${userToken().userId}`, data, {
            headers: {
                // Authentication: `Bearer ${userToken().accessToken}`,
            },
        })
            .then(function (response) {
                if (response.status === 201) {
                    toast.success("Work details have been added,successfully");
                    dispatch(FetchWork())
                }
            })
            .catch(function (error) {
                if (error.response) {
                    toast.error("Something is wrong,Please contact us");
                }
            });
}

export function FetchWork() {
    return async (dispatch) =>
        Axios.get(window.$name + `/profile/work/information/${userToken().userId}`, {})
            .then(function (response) {
                // console.log("DATA",response.data)
                dispatch({type: WORK_DATA, payload: response.data});
                // console.log(response.data);
            })
            .catch(function (error) {
                if (error.response) {
                    toast.error(
                        `"${error.response.data}" Error Status  "${error.response.status}" `
                    );
                } else if (error.request) {
                    toast.error(`"${error.request}" `);
                } else {
                    toast.error(`"${error.message}" `);
                }
            });
}

export function EditWork(value) {
    // console.log(value)
    const data = {
        user_id: userToken().userId,
        business_location: value.business_location,
        employer: value.employer,
        number_of_advocates: value.number_of_advocates,
        tin: value.tin,
        telephone: value.telephone,
        email: value.email,
        address: value.address,
        category: value.category,
        website: value.website,
    };
    return async (dispatch) =>
        Axios.put(window.$name + `/profile/work/${value.id}`, data, {
            headers: {}
        }, {})
            .then(function (response) {
                // console.log(response.data)
                if (response.status === 200) {
                    dispatch(FetchWork())
                    toast.success("Work Profile Editing ,successfully");
                    // dispatch({type: EDIT_EDUCATION_PROFILES_DATA, payload: response.data})
                }
            })
            .catch(function (error) {
                console.log(error.response.data)
                if (error.response.data) {
                    if (error.response.data.business_location) {
                        toast.error("business location - " + " " + error.response.data.business_location[0]);
                    }
                    else if (error.response.data.address) {
                        toast.error("Address - " + " " + error.response.data.address[0]);
                    }
                    else if (error.response.data.employer) {
                        toast.error("employer - " + " " + error.response.data.employer[0]);
                    }
                    else {
                        toast.error(error.response);
                    }
                } else {
                    toast.error(`"${error.message}" `);
                }
            });
}

export function DeleteWork(id) {

    return async (dispatch) =>
        Axios.delete(window.$name + `/profile/datf/information/${id}`, {
            headers: {},
        }, {})
            .then(function (response) {
                if (response.status === 204) {
                    toast.success(" Next Kin Deleting ,successfully");
                    dispatch(FetchWork())
                }
            })
            .catch(function (error) {
                if (error.response) {
                    toast.error("Something is wrong,Please contact us");
                }
            });
}