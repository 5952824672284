import {Button, Card, Col, Form, Modal, Row} from "@themesberg/react-bootstrap";
import React, {useState} from "react";
import {useFormik} from "formik";
import * as yup from "yup";
import {AddEducationProfile, DeleteEducation} from "../../../store/eductionProfile";
import AsyncAction from "../../../AsyncAction";
import {connect} from "react-redux";
import EducationEditForm from "./editEducation";
import NotFoundPage from "./notFound";

const validationSchema = yup.object().shape({
    award: yup.string().required("This field is required"),
    year_of_completion: yup.string().required("This field is required"),
    school_name: yup.string().required("This field is required"),
    education_level: yup.string().required("This field is required"),
});

function Education(props) {
    const [show, setShow] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);
    const [value, setValues] = useState({});

    const handleClose = () => setShow(false);
    const handleCloseEdit = () => setShowEditForm(false);
    const handleShow = () => setShow(true);
    const {AddEducationProfile, EditEducation, DeleteEducation, educationData} = props;
    //using useFormik
    const formik = useFormik({
        initialValues: {
            award: "",
            year_of_completion: "",
            education_level: "",
            school_name: "",
        },
        validationSchema,
    });
    const handleShowEditForm = (value) => {
        // const Values = formik.values = value;
        // console.log("Values", formik.values)
        if (value) {
            setShowEditForm(true);
            setValues(value);
        } else {
            setShowEditForm(false);
        }
    };

    function handleOnclickOption() {
        AddEducationProfile(formik.values)
        setShow(false);
        formik.resetForm({})
    }

    return (
        <>
            <Row>
                <Col className="col-md-6 d-flex justify-content-start">
                    <h5>Education Information </h5>
                    &nbsp;
                    &nbsp;
                    {
                        educationData.length >= 1 ?
                            <Button onClick={handleShow} size="sm" variant="outline-dark">Add Education</Button>
                            :
                            <></>
                    }
                </Col>
            </Row>
            {
                educationData.length > 0 ?
                    <Row className="mt-3">
                        {educationData.map((index) => (
                            <Col className="col-md-6">
                                <Card border="light" className="shadow-none">
                                    <Card.Body>
                                        <div
                                            className="d-flex align-items-center justify-content-between border-bottom border-light">
                                            <div>
                                                <h6 className="text-uppercase">
                                                    {index.year_of_completion} - {index.education_level}
                                                    &nbsp;
                                                    <span
                                                        className="text-capitalize">({index.award})</span>
                                                </h6>
                                            </div>
                                            <div>
                                                <Card.Link href="#" className="text-primary fw-bold">
                                                </Card.Link>
                                            </div>
                                        </div>
                                        <div
                                            className="d-flex align-items-center justify-content-between border-bottom border-light">
                                            <div>
                                                <h6> {index.school_name}</h6>
                                            </div>
                                        </div>
                                        <div className="mt-1" style={{float: 'right'}}>
                                            <Button
                                                size="sm"
                                                onClick={() => handleShowEditForm(index)}
                                                variant="outline-primary">
                                                Edit
                                            </Button>
                                            &nbsp; &nbsp;
                                            <AsyncAction
                                                action={() => DeleteEducation(index.id)}
                                                render={({run, loading}) => (
                                                    <Button
                                                        size="sm"
                                                        variant="outline-danger"
                                                        type="submit"
                                                        data-toggle="tooltip"
                                                        data-placement="right"
                                                        title="sign-in"
                                                        onClick={run}
                                                        // disabled={!(formik.isValid && formik.dirty)}
                                                    >
                                                        Remove
                                                    </Button>
                                                )}
                                            />
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))
                        }
                    </Row>
                    :
                    <Row>
                        <NotFoundPage/>
                        <div className="d-flex justify-content-center">
                            <Button onClick={handleShow} variant="primary mt-2">Add Education</Button>
                        </div>
                    </Row>


            }
            {/*model for adding education */}
            <Modal show={show} onHide={handleClose}>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Education level</Form.Label>
                            <Form.Select
                                className="form-control mb-0"
                                onChange={formik.handleChange}
                                id="education_level"
                                name="education_level"
                                onBlur={formik.handleBlur}
                                value={formik.values.education_level}
                            >
                                <option value="">Select Level</option>
                                <option value="Degree">Degree</option>
                                <option value="Masters">Masters</option>
                                <option value="Doctorate">Doctorate</option>
                                <option value="PHD">PHD</option>
                                <option value="Post graduate">Post graduate</option>
                            </Form.Select>
                            {formik.touched.education_level && formik.errors.education_level ? (
                                <div className="text-danger">{formik.errors.education_level}</div>
                            ) : null}
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>School/College/University</Form.Label>
                            <input
                                type="text"
                                className="form-control mb-0"
                                autoComplete="off"
                                id="school_name"
                                name="school_name"
                                value={formik.values.school_name}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            {formik.touched.school_name && formik.errors.school_name ? (
                                <div className="text-danger">
                                    {formik.errors.school_name}
                                </div>
                            ) : null
                            }
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Year of completion</Form.Label>
                            <input
                                type="text"
                                className="form-control mb-0"
                                autoComplete="off"
                                id="year_of_completion"
                                name="year_of_completion"
                                value={formik.values.year_of_completion}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            {formik.touched.year_of_completion && formik.errors.year_of_completion ? (
                                <div className="text-danger">
                                    {formik.errors.year_of_completion}
                                </div>
                            ) : null
                            }
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Awards</Form.Label>
                            <input
                                type="text"
                                className="form-control mb-0"
                                autoComplete="off"
                                id="award"
                                name="award"
                                value={formik.values.award}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            {formik.touched.award && formik.errors.award ? (
                                <div className="text-danger">
                                    {formik.errors.award}
                                </div>
                            ) : null
                            }
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button size="sm" variant="outline-dark" onClick={handleClose}>
                        Close
                    </Button>
                    <Button  disabled={!(formik.isValid && formik.dirty)}
                             size="sm"
                             onClick={() => handleOnclickOption()}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
            {/*end here modal for adding education*/}

            {/*model for adding education */}
            <Modal show={showEditForm} onHide={handleClose}>
                <EducationEditForm educationData={value} handleShowEditForm={handleShowEditForm}/>
            </Modal>
            {/*end here modal for adding education*/}
        </>
    );
}

const mapDispatchToProps = {
    AddEducationProfile, DeleteEducation
};

export default connect(() => ({}), mapDispatchToProps)(Education);


