import Axios from "axios";
import {USER_PROFILES_DATA} from "./personalProfileActionTypes";
import {toast} from "react-toastify";
import {userToken} from "../Login";
import {Loader} from "../loader";
// import {EDIT_PROFILE_DATA} from "../editProfile/EditProfileActionTypes";

export function AddPersonalProfile(value) {
    // console.log(image);
    const data = {
        Date_of_Birth: value.Date_birth,
        employer: value.employer,
        Number_of_Advocates: value.Number_of_Advocates,
        tin: value.tin,
        Phone: value.Phone,
        email: value.email,
        Postal_address: value.Postal_address,
        work_category: value.work_category,
        Gender: value.gender,
        Marital_status: value.marital,
        level: value.level,
        Chapter: value.chapter,
        Spouse_name: value.Spouse_name,
        Passport_no: value.Passport_no,
        National_id_no: value.National_id_no,
        health_challenges: value.health_challenges,
        specialization: value.specialization,
        user_id: userToken().userId,
        // image:image
    };
    return async (dispatch) =>
        Axios.post(window.$name + `/profile/personal/${userToken().userId}`, data, {
            // headers: {
            //     Authentication: `Bearer ${userToken().accessToken}`,
            // },
        })
            .then(function (response) {
                if (response.status === 201) {
                    dispatch(Loader(false))
                    toast.success("Your Personal details have been added,successfully");
                    dispatch(FetchPersonalProfiles())

                }
            })
            .catch(function (error) {
                if (error.response) {
                    toast.error("Something is wrong,Please contact us");
                }
            });
}

export function FetchPersonalProfiles() {
    return async (dispatch) =>
        Axios.get(window.$name + `/profile/personal/data/${userToken().userId}`, {
        })
            .then(function (response) {
                // console.log(response.data)
                if (response.status === 200) {
                    dispatch({type: USER_PROFILES_DATA, payload: response.data});
                }
                // console.log(response.data);
            })
            .catch(function (error) {
                if (error.response) {
                    toast.error(
                        `"${error.response.data}" Error Status  "${error.response.status}" `
                    );
                } else if (error.request) {
                    toast.error(`"${error.request}" `);
                } else {
                    toast.error(`"${error.message}" `);
                }
            });
}

export function EditPersonalProfile(value) {
    const data = {
        // user_id: userToken().userId,
        // Date_birth: value.Date_birth,
        // employer: value.employer,
        // Number_of_Advocates: value.Number_of_Advocates,
        // tin: value.tin,
        // Phone: value.Phone,
        // Email: value.Email,
        // Postal_address: value.Postal_address,
        // work_category: value.work_category,
        // gender: value.gender,
        // marital_st: value.marital,
        // Chapter: value.chapter,
        // Spouse_name: value.Spouse_name,
        // Passport_no: value.Passport_no,
        // National_id_no: value.National_id_no,
        // health_challenges: value.health_challenges,
        // specialization: value.specialization,
        Date_of_Birth: value.Date_birth,
        employer: value.employer,
        Number_of_Advocates: value.Number_of_Advocates,
        tin: value.tin,
        Phone: value.Phone,
        email: value.email,
        Postal_address: value.Postal_address,
        work_category: value.work_category,
        Gender: value.gender,
        Marital_status: value.marital,
        level: value.level,
        Chapter: value.chapter,
        Spouse_name: value.Spouse_name,
        Passport_no: value.Passport_no,
        National_id_no: value.National_id_no,
        health_challenges: value.health_challenges,
        specialization: value.specialization,
        user_id: userToken().userId,
    };
    return async (dispatch) =>
        Axios.put(window.$name + `/profile/personal/information/${value.id}`, data, {
            headers: {},
        })
            .then(function (response) {
                if (response.status === 200) {
                   dispatch(Loader(false));
                    toast.success("profile Editing ,successfully");
                    dispatch(FetchPersonalProfiles())
                    // dispatch({type: EDIT_PROFILE_DATA, payload: data})
                }
            })
            .catch(function (error) {
                if (error.response) {
                    toast.error("Something is wrong,Please contact us");
                }
            });
}
