import React, {useEffect} from "react";
import {Button, Card, Col, Row} from '@themesberg/react-bootstrap';
import {Routes} from '../../routes';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faGenderless,
    faGift, faLocationArrow, faLockOpen,
    faMoneyCheck,
    faMoneyCheckAlt,
    faUserAlt, faUserFriends,
    faUsers
} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {FetchDashboard, FetchUserDashboard} from "../../store/dashboard";
import CurrencyFormat from "react-currency-format";
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons/faWhatsapp";

function Dashboard() {
    const dispatch = useDispatch();
    const rolesData = useSelector((state) => state.permission[0]);
    const isAllowed = JSON.parse(rolesData.permission)

    useEffect(() => {
        if (isAllowed.includes('Add user')) {
            dispatch(FetchDashboard());
        } else {
            dispatch(FetchUserDashboard());
        }
    }, [dispatch]);

    const statistics = useSelector((state) => state.statistics);
    // console.log(statistics)
    return (
        <div className="mt-5">
            <Row className="">

                <Col className="col-md-6 mb-4">
                    <Card border="light" className="shadow-sm">
                        <Card.Header>
                            <div className="d-block">
                                <div className="d-flex align-items-center pt-3 me-5">
                                    <div className="icon icon-shape icon-sm icon-shape-danger rounded me-3">
                                        <FontAwesomeIcon icon={faUserAlt}/>
                                    </div>
                                    <div className="d-block">
                                        <label className="mb-0">Welcome to your Dashboard</label>
                                        <p className="mb-0">Learn more below</p>
                                    </div>
                                </div>
                            </div>
                        </Card.Header>
                        <Card.Body className="mb-4">
                            <p className="mt-2">This is your <Link
                                to={Routes.DashboardOverview.path}>Dashboard</Link> you will be receiving all the
                                updates here.</p>
                            <p className="mt-2">Click the button below to open your Profile settings</p>
                            <Button size="sm" variant="outline-primary" as={Link} to={Routes.Profile.path}>
                                Update your profile
                            </Button>
                        </Card.Body>
                    </Card>
                </Col>
                <Col className="col-md-6 mb-4">
                    <Card border="light" className="shadow-sm">
                        <Card.Header>
                            <div className="d-block">
                                <div className="d-flex align-items-center pt-3 me-5">
                                    <div className="icon icon-shape icon-sm icon-shape-danger rounded me-3">
                                        <FontAwesomeIcon icon={faUserAlt}/>
                                    </div>
                                    <div className="d-block">
                                        <label className="mb-0">Wakili Database</label>
                                        <p className="mb-0">Learn more below</p>
                                    </div>
                                </div>
                            </div>
                        </Card.Header>
                        <Card.Body className="mb-4">
                            <p>Click the link below to join the WhatsApp community.</p>
                            <div className="row mt-4">
                                <div className="col-md-12 d-flex justify-content-between">
                                    <a href="https://chat.whatsapp.com/HnHEjk3MkHu19BqN4dfkKo" target="_blank">
                                        <Button className="btn btn-success">
                                            JOIN COMMUNITY
                                        </Button>
                                    </a>
                                    <FontAwesomeIcon color="green" size="4x" style={{marginTop: -15}} icon={faWhatsapp}/>
                                </div>
                                {/*<div className="col-md-4" >*/}

                                {/*</div>*/}
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
                {
                    isAllowed.includes('Add user') ?
                        <>
                            <Col xs={12} sm={6} xl={4} className="mb-4">
                                <Card border="light" className="shadow-sm">
                                    <Card.Body>
                                        <Row className="d-block d-xl-flex align-items-center">
                                            <Col xl={5}
                                                 className="text-xl-center d-flex align-items-center justify-content-xl-center mb-3 mb-xl-0">
                                                <div
                                                    className={`icon icon-shape icon-md icon-green rounded me-4 me-sm-0`}>
                                                    <FontAwesomeIcon icon={faUsers}/>
                                                </div>
                                                <div className="d-sm-none">
                                                    <h5>Account</h5>
                                                    <h6 className="mb-1">Verified - {statistics.account_verified}</h6>
                                                    <h6 className="mb-1">Not Verified - {statistics.account_not_verified}</h6>
                                                    <h6 className="mb-1">Total  :  {statistics.account_verified + statistics.account_not_verified }</h6>
                                                </div>
                                            </Col>

                                            <Col xs={12} xl={7} className="px-xl-0">
                                                <div className="d-none d-sm-block">
                                                    <h5>Account</h5>
                                                    <h6 className="mb-1">Verified : {statistics.account_verified}</h6>
                                                    <h6 className="mb-1">Not Verified
                                                        : {statistics.account_not_verified}</h6>
                                                    <h6 className="mb-1">Total  :  {statistics.account_verified + statistics.account_not_verified }</h6>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xs={12} sm={6} xl={4} className="mb-4">
                                <Card border="light" className="shadow-sm">
                                    <Card.Body>
                                        <Row className="d-block d-xl-flex align-items-center">
                                            <Col xl={5}
                                                 className="text-xl-center d-flex align-items-center justify-content-xl-center mb-3 mb-xl-0">
                                                <div
                                                    className={`icon icon-shape icon-md icon-green rounded me-4 me-sm-0`}>
                                                    <FontAwesomeIcon icon={faUserAlt}/>
                                                </div>
                                                <div className="d-sm-none">
                                                    <h5>Profiles</h5>
                                                    <h6 className="mb-1">Updated - {statistics.active_member}</h6>
                                                    <h6 className="mb-1">Not Updated - {statistics.not_active_member}</h6>
                                                    <h6 className="mb-1">Total - {statistics.not_active_member + statistics.active_member}</h6>
                                                </div>
                                            </Col>

                                            <Col xs={12} xl={7} className="px-xl-0">
                                                <div className="d-none d-sm-block">
                                                    <h5>Profiles</h5>
                                                    <h6 className="mb-1">Updated : {statistics.active_member}</h6>
                                                    <h6 className="mb-1">Not Updated
                                                        : {statistics.not_active_member}</h6>
                                                    <h6 className="mb-1">Total  :  {statistics.not_active_member + statistics.active_member }</h6>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xs={12} sm={6} xl={4} className="mb-4">
                                <Card border="light" className="shadow-sm">
                                    <Card.Body>
                                        <Row className="d-block d-xl-flex align-items-center">
                                            <Col xl={5}
                                                 className="text-xl-center d-flex align-items-center justify-content-xl-center mb-3 mb-xl-0">
                                                <div
                                                    className={`icon icon-shape icon-md icon-green rounded me-4 me-sm-0`}>
                                                    <FontAwesomeIcon icon={faUserFriends}/>
                                                </div>
                                                <div className="d-sm-none">
                                                    <h5>Gender</h5>
                                                    <h6 className="mb-1">Male - {statistics.male}</h6>
                                                    <h6 className="mb-1">Female - {statistics.female}</h6>
                                                    <h6 className="mb-1">Total  :  {statistics.female + statistics.male }</h6>

                                                </div>
                                            </Col>

                                            <Col xs={12} xl={7} className="px-xl-0">
                                                <div className="d-none d-sm-block">
                                                    <h5>Gender</h5>
                                                    <h6 className="mb-1">Male : {statistics.male}</h6>
                                                    <h6 className="mb-1">Female
                                                        : {statistics.female}</h6>
                                                    <h6 className="mb-1">Total  :  {statistics.female + statistics.male }</h6>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xs={12} sm={6} xl={4} className="mb-4">
                                <Card border="light" className="shadow-sm">
                                    <Card.Body>
                                        <Row className="d-block d-xl-flex align-items-center">
                                            <Col xl={5}
                                                 className="text-xl-center d-flex align-items-center justify-content-xl-center mb-3 mb-xl-0">
                                                <div
                                                    className={`icon icon-shape icon-md icon-green rounded me-4 me-sm-0`}>
                                                    <FontAwesomeIcon icon={faLocationArrow}/>
                                                </div>
                                                <div className="d-sm-none">
                                                    <h5>Location</h5>
                                                    <h6 className="mb-1">Unguja : {statistics.unguja}</h6>
                                                    <h6 className="mb-1">Pemba : {statistics.pemba}</h6>
                                                    <h6 className="mb-1">Total  :  {statistics.pemba + statistics.unguja }</h6>
                                                </div>
                                            </Col>

                                            <Col xs={12} xl={7} className="px-xl-0">
                                                <div className="d-none d-sm-block">
                                                    <h5>Location</h5>
                                                    <h6 className="mb-1">Unguja : {statistics.unguja}</h6>
                                                    <h6 className="mb-1">Pemba : {statistics.pemba}</h6>
                                                    <h6 className="mb-1">Total  :  {statistics.pemba + statistics.unguja }</h6>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xs={12} sm={6} xl={4} className="mb-4">
                                <Card border="light" className="shadow-sm">
                                    <Card.Body>
                                        <Row className="d-block d-xl-flex align-items-center">
                                            <Col xl={5} className="text-xl-center d-flex align-items-center justify-content-xl-center mb-3 mb-xl-0">
                                                <div
                                                    className={`icon icon-shape icon-md icon-green rounded me-4 me-sm-0`}>
                                                    <FontAwesomeIcon icon={faMoneyCheckAlt}/>
                                                </div>
                                                <div className="d-sm-none mb-5">
                                                    <h5>Payments</h5>
                                                    <h6 className="mb-1">Paid :
                                                        ({statistics.no_of_paid})
                                                        <CurrencyFormat
                                                            value={statistics.total_online_transaction_amount + statistics.total_cash_transaction_amount}
                                                            displayType={'text'}
                                                            thousandSeparator={true}
                                                            prefix={' TZS '}
                                                            renderText={value => <span>{value} </span>}/>
                                                    </h6>
                                                    <h6 className="mb-1">Not Paid :
                                                        ({statistics.no_of_debits})
                                                        <CurrencyFormat
                                                            value={statistics.total_debits_amount}
                                                            displayType={'text'}
                                                            thousandSeparator={true}
                                                            prefix={' TZS '}
                                                            renderText={value => <span>{value} </span>}/>
                                                    </h6>
                                                    <h6 className="mb-1">
                                                        Total :
                                                        ({statistics.no_of_debits + statistics.no_of_paid})
                                                        <CurrencyFormat
                                                            value={statistics.total_debits_amount + statistics.total_online_transaction_amount + statistics.total_cash_transaction_amount}
                                                            displayType={'text'}
                                                            thousandSeparator={true}
                                                            prefix={' TZS '}
                                                            renderText={value => <span>{value} </span>}/>
                                                    </h6>
                                                </div>
                                            </Col>

                                            <Col xs={12} xl={7} className="px-xl-0">
                                                <div className="d-none d-sm-block">
                                                    <h5>Payments</h5>
                                                    <h6 className="mb-1">Paid :
                                                         ({statistics.no_of_paid})
                                                        <CurrencyFormat
                                                            value={statistics.total_online_transaction_amount + statistics.total_cash_transaction_amount}
                                                            displayType={'text'}
                                                            thousandSeparator={true}
                                                            prefix={' TZS '}
                                                            renderText={value => <span>{value} </span>}/>
                                                    </h6>
                                                    <h6 className="mb-1">Not Paid :
                                                        ({statistics.no_of_debits})
                                                        <CurrencyFormat
                                                            value={statistics.total_debits_amount}
                                                            displayType={'text'}
                                                            thousandSeparator={true}
                                                            prefix={' TZS '}
                                                            renderText={value => <span>{value} </span>}/>
                                                    </h6>
                                                    <h6 className="mb-1">
                                                        Total :
                                                        ({statistics.no_of_debits + statistics.no_of_paid})
                                                        <CurrencyFormat
                                                            value={statistics.total_debits_amount + statistics.total_online_transaction_amount + statistics.total_cash_transaction_amount}
                                                            displayType={'text'}
                                                            thousandSeparator={true}
                                                            prefix={' TZS '}
                                                            renderText={value => <span>{value} </span>}/>
                                                    </h6>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xs={12} sm={6} xl={4} className="mb-4">
                                <Card border="light" className="shadow-sm">
                                    <Card.Body>
                                        <Row className="d-block d-xl-flex align-items-center">
                                            <Col xl={5} className="text-xl-center d-flex align-items-center justify-content-xl-center mb-3 mb-xl-0">
                                                <div
                                                    className={`icon icon-shape icon-md icon-green rounded me-4 me-sm-0`}>
                                                    <FontAwesomeIcon icon={faMoneyCheckAlt}/>
                                                </div>
                                                <div className="d-sm-none mb-5">
                                                    <h5>Transactions</h5>
                                                    <h6 className="mb-1">Online :
                                                        ({statistics.no_of_online_transaction})
                                                        <CurrencyFormat
                                                            value={statistics.total_online_transaction_amount}
                                                            displayType={'text'}
                                                            thousandSeparator={true}
                                                            prefix={' TZS '}
                                                            renderText={value => <span>{value} </span>}/>
                                                    </h6>
                                                    <h6 className="mb-1">Offline :
                                                        ({statistics.no_of_cash_transaction})
                                                        <CurrencyFormat
                                                            value={statistics.total_cash_transaction_amount}
                                                            displayType={'text'}
                                                            thousandSeparator={true}
                                                            prefix={' TZS '}
                                                            renderText={value => <span>{value} </span>}/>
                                                    </h6>
                                                    <h6 className="mb-1">
                                                        Total :
                                                        ({statistics.no_of_online_transaction + statistics.no_of_cash_transaction})
                                                        <CurrencyFormat
                                                            value={statistics.total_online_transaction_amount + statistics.total_cash_transaction_amount}
                                                            displayType={'text'}
                                                            thousandSeparator={true}
                                                            prefix={' TZS '}
                                                            renderText={value => <span>{value} </span>}/>
                                                    </h6>
                                                </div>
                                            </Col>
                                            <Col xs={12} xl={7} className="px-xl-0">
                                                <div className="d-none d-sm-block">
                                                    <h5>Transactions</h5>
                                                    <h6 className="mb-1">Online :
                                                         ({statistics.no_of_online_transaction})
                                                        <CurrencyFormat
                                                            value={statistics.total_online_transaction_amount}
                                                            displayType={'text'}
                                                            thousandSeparator={true}
                                                            prefix={' TZS '}
                                                            renderText={value => <span>{value} </span>}/>
                                                    </h6>
                                                    <h6 className="mb-1"> Offline :
                                                        ({statistics.no_of_cash_transaction})
                                                        <CurrencyFormat
                                                            value={statistics.total_cash_transaction_amount}
                                                            displayType={'text'}
                                                            thousandSeparator={true}
                                                            prefix={' TZS '}
                                                            renderText={value => <span>{value} </span>}/>
                                                    </h6>
                                                    <h6 className="mb-1">
                                                        Total :
                                                        ({statistics.no_of_online_transaction + statistics.no_of_cash_transaction})
                                                        <CurrencyFormat
                                                            value={statistics.total_cash_transaction_amount + statistics.total_online_transaction_amount}
                                                            displayType={'text'}
                                                            thousandSeparator={true}
                                                            prefix={' TZS '}
                                                            renderText={value => <span>{value} </span>}/>
                                                    </h6>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </> :
                        <>
                            <Col xs={12} sm={6} xl={4} className="mb-4">
                                <Card border="light" className="shadow-sm">
                                    <Card.Body>
                                        <Row className="d-block d-xl-flex align-items-center">
                                            <Col xl={5}
                                                 className="text-xl-center d-flex align-items-center justify-content-xl-center mb-3 mb-xl-0">
                                                <div
                                                    className={`icon icon-shape icon-md icon-green rounded me-4 me-sm-0`}>
                                                    <FontAwesomeIcon icon={faGift}/>
                                                </div>
                                                <div className="d-sm-none">
                                                    <h5> Total CLE Points </h5>
                                                    <span className="text-center"> {statistics.total_point}</span>
                                                </div>
                                            </Col>

                                            <Col xs={12} xl={7} className="px-xl-0">
                                                <div className="d-none d-sm-block">
                                                    <h5>Total CLE Points </h5>
                                                    <h6><span> {statistics.total_point}</span></h6>

                                                </div>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xs={12} sm={6} xl={4} className="mb-4">
                                <Card border="light" className="shadow-sm">
                                    <Card.Body>
                                        <Row className="d-block d-xl-flex align-items-center">
                                            <Col xl={5}
                                                 className="text-xl-center d-flex align-items-center justify-content-xl-center mb-3 mb-xl-0">
                                                <div
                                                    className={`icon icon-shape icon-md icon-green rounded me-4 me-sm-0`}>
                                                    <FontAwesomeIcon icon={faMoneyCheckAlt}/>
                                                </div>
                                                <div className="d-sm-none mb-5">
                                                    <h5>Total Payments</h5>

                                                    <CurrencyFormat
                                                        value={statistics.total_transaction_amount}
                                                        displayType={'text'}
                                                        thousandSeparator={true}
                                                        prefix={' TZS '}
                                                        renderText={value => <span> <h6>{value}</h6> </span>}/>

                                                </div>
                                            </Col>
                                            <Col xs={12} xl={7} className="px-xl-0">
                                                <div className="d-none d-sm-block">
                                                    <h5>Total Payments</h5>

                                                    <CurrencyFormat
                                                        value={statistics.total_transaction_amount}
                                                        displayType={'text'}
                                                        thousandSeparator={true}
                                                        prefix={' TZS '}
                                                        renderText={value => <span> <h6>{value}</h6> </span>}/>

                                                </div>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>

                            </Col>
                            <Col xs={12} sm={6} xl={4} className="mb-4">
                                <Card border="light" className="shadow-sm">
                                    <Card.Body>
                                        <Row className="d-block d-xl-flex align-items-center">
                                            <Col xl={5}
                                                 className="text-xl-center d-flex align-items-center justify-content-xl-center mb-3 mb-xl-0">
                                                <div
                                                    className={`icon icon-shape icon-md icon-green rounded me-4 me-sm-0`}>
                                                    <FontAwesomeIcon icon={faMoneyCheck}/>
                                                </div>
                                                <div className="d-sm-none mb-5">
                                                    <h5>Total Debt</h5>

                                                    <CurrencyFormat
                                                        value={statistics.total_debits_amount}
                                                        displayType={'text'}
                                                        thousandSeparator={true}
                                                        prefix={' TZS '}
                                                        renderText={value => <span> <h6>{value} </h6></span>}/>

                                                </div>
                                            </Col>
                                            <Col xs={12} xl={7} className="px-xl-0">
                                                <div className="d-none d-sm-block">
                                                    <h5>Total Debt</h5>

                                                    <CurrencyFormat
                                                        value={statistics.total_debits_amount}
                                                        displayType={'text'}
                                                        thousandSeparator={true}
                                                        prefix={' TZS '}
                                                        renderText={value => <span> <h6>{value}</h6> </span>}/>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </>
                }
                {/*<Col xs={12} sm={6} xl={4} className="mb-4">*/}
                {/*    <Card border="light" className="shadow-sm">*/}
                {/*        <Card.Body>*/}
                {/*            <div>*/}
                {/*                <h3><p><b>ZLS WAKILI DATABASE</b></p></h3>*/}
                {/*                <p>Click the link below to join the WhatsApp community</p>*/}
                {/*                <div className="row">*/}
                {/*                    <div className="col-md-9">*/}
                {/*                        <a href="https://chat.whatsapp.com/HnHEjk3MkHu19BqN4dfkKo" target="_blank">*/}
                {/*                        <Button className="btn btn-block btn-success">*/}
                {/*                            JOIN COMMUNITY*/}
                {/*                        </Button>*/}
                {/*                        </a>*/}
                {/*                    </div>*/}
                {/*                    <div className="col-md-3" style={{marginTop: -40}}>*/}
                {/*                        <FontAwesomeIcon color="green" size="5x" icon={faWhatsapp}/>*/}
                {/*                    </div>*/}
                {/*                </div>*/}


                {/*            </div>*/}
                {/*        </Card.Body>*/}
                {/*    </Card>*/}
                {/*</Col>*/}
            </Row>
        </div>
    );
}

export default Dashboard;
