import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faCheck,
    faCheckDouble,
    faCog,
    faHome,
    faReceipt,
    faSearch,
    faTimesCircle
} from '@fortawesome/free-solid-svg-icons';
import {
    Breadcrumb,
    Button,
    ButtonGroup,
    Card,
    Col,
    Dropdown,
    Form,
    InputGroup,
    Row,
    Table
} from '@themesberg/react-bootstrap';
import {Logout} from "../../../store/Login";
import {FetchPersonalProfiles} from "../../../store/personalProfile";
import {connect, useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {CancelOrder, FetchAllBill} from "../../../store/Bills";
import Moment from "moment";
import CurrencyFormat from "react-currency-format";
import {decode as base64_decode} from "base-64";

function BillList() {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(FetchAllBill());

    }, [dispatch]);


    const current = new Date();
    const date = current;
    const formated_date = Moment(date).toISOString()

    function Payment(data) {
        console.log("data", data)
        // data.forEach(index => {
        // console.log(index)
        // total += Number(index.book.price);
        // console.log(index.book.price);
        // })
        window.location.href = base64_decode(data)
    }

    function Cancel(data) {
        const value = {
            order_id: data.order_id,
            formated_date: formated_date
        }
        console.log(value)
        dispatch(CancelOrder(value))
    }

    const billData = useSelector((state) => state.Bill);
    //Search Filter Data
    const [searchQuery, setSearchQuery] = useState("")

    function search() {
        return billData.filter(row => row.order_id.toLowerCase().indexOf(searchQuery) > -1)

    }

    return (
        <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="d-block mb-4 mb-md-0">
                    <Breadcrumb className="d-none d-md-inline-block"
                                listProps={{className: "breadcrumb-dark breadcrumb-transparent"}}>
                        <Breadcrumb.Item><FontAwesomeIcon icon={faHome}/></Breadcrumb.Item>
                        <Breadcrumb.Item>Home</Breadcrumb.Item>
                        <Breadcrumb.Item active>Payments</Breadcrumb.Item>
                    </Breadcrumb>
                    <h4>Bills </h4>

                    {/*<p className="mb-0">Your web analytics dashboard template.</p>*/}
                </div>
                <div className="btn-toolbar mb-2 mb-md-0">
                    <ButtonGroup>
                        <Button variant="outline-primary" size="sm">Share</Button>
                        <Button variant="outline-primary" size="sm">Export</Button>
                    </ButtonGroup>
                </div>
            </div>

            <div className="table-settings mb-4">
                <p>
                    {/*{dataTest}*/}
                </p>
                <Row className="justify-content-between align-items-center">
                    <Col xs={8} md={6} lg={3} xl={4}>
                        <InputGroup>
                            <InputGroup.Text>
                                <FontAwesomeIcon icon={faSearch}/>
                            </InputGroup.Text>
                            <Form.Control
                                type="text"
                                placeholder="Search"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />
                        </InputGroup>
                    </Col>
                    <Col xs={4} md={2} xl={1} className="ps-md-0 text-end">
                        <Dropdown as={ButtonGroup}>
                            <Dropdown.Toggle split as={Button} variant="link" className="text-dark m-0 p-0">
                <span className="icon icon-sm icon-gray">
                  <FontAwesomeIcon icon={faCog}/>
                </span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dropdown-menu-xs dropdown-menu-right">
                                <Dropdown.Item className="fw-bold text-dark">Show</Dropdown.Item>
                                <Dropdown.Item className="d-flex fw-bold">
                                    10 <span className="icon icon-small ms-auto"><FontAwesomeIcon
                                    icon={faCheck}/></span>
                                </Dropdown.Item>
                                <Dropdown.Item className="fw-bold">20</Dropdown.Item>
                                <Dropdown.Item className="fw-bold">30</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                </Row>
            </div>

            <Card border="light" className="shadow-sm mb-4">
                <Card.Body className="pb-0">
                    <Table responsive className="table-centered  table-nowrap rounded mb-0">
                        <thead className="thead-light">
                        <tr>
                            <th className="border-0">#</th>
                            <th className="border-0">Details</th>
                            <th className="border-0">Issued Date</th>
                            <th className="border-0">Due Date</th>
                            <th className="border-0">Total</th>
                            <th className="border-0">Status</th>
                            {/* <th className="border-0">Actions</th> */}
                        </tr>
                        </thead>
                        <tbody>
                        {search(billData).map((index) => (
                                <tr>
                                    <td>
                                        <Link to={`/invoice/` + index.order_id}
                                              className="text-blue fw-bold">{index.order_id}</Link>
                                    </td>
                                    <td>{index.type}</td>
                                    <td>{Moment(index.created_at).format('DD-MM-YYYY')}</td>
                                    <td>{Moment(index.updated_at).format('DD-MM-YYYY')}</td>
                                    <td>
                                        {index.amount === 'null' ?
                                            <span className="badge bg-warning badge-lg text-dark ">FREE</span>
                                            :
                                            <CurrencyFormat
                                                value={index.amount}
                                                displayType={'text'}
                                                thousandSeparator={true}
                                                prefix={' TZS '}
                                                renderText={value => <span> {value} </span>}/>
                                        }
                                    </td>
                                    <td>
                                        {index.status === 'COMPLETED' ?
                                            <span className="badge-md badge bg-success">Paid</span>
                                            : index.status === 'COMPLETED-CASH' ?
                                                <span className="badge-md badge bg-success">Paid cash</span>
                                                :
                                                index.status === 'CANCELLED' ?
                                                    <span className="badge-md badge bg-danger">Cancelled</span>
                                                    :
                                                    <span className="badge-md badge bg-danger">Not Paid</span>
                                        }
                                    </td>

                                    {/* <td>
                                        {
                                            index.status === 'Not paid' ?
                                                <>
                                                    <Button size="sm" onClick={() => Payment(index.selcom_response)}
                                                            variant="outline-primary">
                                                        <FontAwesomeIcon icon={faCheckDouble}
                                                                         className="icon icon-xs text-dark w-30"/>
                                                        Pay now
                                                    </Button>
                                                    &nbsp;
                                                    <Button size="sm" onClick={() => Cancel(index)}
                                                            variant="outline-primary">
                                                        <FontAwesomeIcon icon={faTimesCircle}
                                                                         className="icon icon-xs text-danger w-30"/>
                                                        Cancel order
                                                    </Button>
                                                </>
                                                :
                                                index.status === 'CANCELLED' ?
                                                    <span className="badge badge-lg bg-gradient-warning text-dark">
                                                     No action
                                                    </span>

                                                    :
                                                    <span className="badge badge-lg bg-gradient-warning text-dark">
                                                  No action
                                                </span>
                                        }
                                    </td> */}
                                </tr>
                            )
                        )}

                        </tbody>
                    </Table>
                </Card.Body>

            </Card>
        </>
    );
};

const mapDispatchToProps = {
    Logout, FetchPersonalProfiles
};
export default connect(() => ({}), mapDispatchToProps)(BillList);





