import React, {useEffect} from "react";
import {Button, Col, Image, Row,} from '@themesberg/react-bootstrap';
import Landing from "../assets/img/pages/landing.png";
import {Header} from "./components/Header";
import {FooterLanding} from "./components/FooterLanding";
import {HashLink} from "react-router-hash-link";
import {Routes} from "../routes";
import {connect, useDispatch, useSelector} from "react-redux";
import {FetchWebsite} from "../store/website";

function Presentation(){
   const  dispatch=useDispatch();
    useEffect(() => {
        dispatch(FetchWebsite());

    }, [dispatch]);

    const WebsiteData = useSelector((state) => state.website);
   console.log(WebsiteData)
    return (
        <div>
            <Header/>
            <section className="section section-md bg-soft pt-lg-3" id="features">
                {/*<Container>*/}
                <Row className="justify-content-between align-items-center mt-6 mb-lg-7">
                    <Col lg={5}>
                        <div className="container mb-5">
                            <div className="container">
                            <div className="container">
                                <p className="mb-3 lead fw-bold">
                                    Welcome To ZLS Wakili Database.
                                </p>
                                <h1 className="d-flex align-items-center">
                                    <b>{WebsiteData.title}</b>
                                </h1>
                                <p className="mb-4">
                                    {WebsiteData.description}
                                </p>
                                {/*<Button*/}
                                {/*    as={HashLink} to={Routes.Signin.path} className="me-3 text-white"*/}
                                {/*        variant="dark">*/}
                                {/*    /!*<FontAwesomeIcon icon={faCalendarAlt} className="me-2"/>*!/*/}
                                {/*    <span style={{color: 'darkgoldenrod'}}>Sign in</span>*/}
                                {/*</Button>*/}
                                <Button
                                    as={HashLink} to={Routes.Signin.path} className="me-3 text-white"
                                        variant="dark">
                                    {/*<FontAwesomeIcon icon={faCalendarAlt} className="me-2"/>*/}
                                    <span>Get Started</span>
                                </Button>
                            </div>
                            </div>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <Image src={Landing} alt="Calendar Preview"/>
                    </Col>
                </Row>
                {/*</Container>*/}
            </section>
            <FooterLanding/>
        </div>
    );
};
const mapStateToProps = (state) => ({
    authentication: state.auth,
});
export default connect(mapStateToProps)(Presentation);



