import Axios from "axios";
import {toast} from "react-toastify";
import {SPEAKER_DATA} from "./speakerActionTypes";
import {FetchEvents} from "../Events";
import {userToken} from "../Login";
import {BILL_DATA} from "../Bills/BillActionTypes";


export function AddSpeaker(data) {

    // console.log(value);
    return async (dispatch) =>
        Axios.post(window.$name + "/events/speaker", data)
            .then(function (response) {
                if (response.data) {
                    toast.success("Speaker added successfully");
                    dispatch(FetchSpeaker())
                }
            })
            .catch(function (error) {
                if (error.response.data.errors) {
                    if (error.response.data.errors.event_id) {
                        toast.error("Event name - " + " " + error.response.data.errors.event_id[0]);
                    } else if (error.response.data.errors.speaker_id) {
                        toast.error("user - " + " " + error.response.data.errors.speaker_id[0]);
                    }
                    else {
                        toast.error(error.response);
                    }
                } else {
                    toast.error(error.response.data.detail);
                }
            });
}
export function  FetchSpeakerById() {
    return async (dispatch) =>
        Axios.get(window.$name +  `/events/speaker/point/227`, {})
            .then(function (response) {
                console.log("DATA", response.data)
                dispatch({type: SPEAKER_DATA, payload: response.data});
                console.log(response.data);
            })
            .catch(function (error) {
                if (error.response) {
                    toast.error(
                        `"${error.response.data}" Error Status  "${error.response.status}" `
                    );
                } else if (error.request) {
                    toast.error(`"${error.request}" `);
                } else {
                    toast.error(`"${error.message}" `);
                }
            });
}
export function FetchSpeaker() {
    return async (dispatch) =>
        Axios.get(window.$name + '/events/speaker/list', {})
            .then(function (response) {
                console.log("DATA", response.data)
                dispatch({type: SPEAKER_DATA, payload: response.data});
                // console.log(response.data);
            })
            .catch(function (error) {
                if (error.response) {
                    toast.error(
                        `"${error.response.data}" Error Status  "${error.response.status}" `
                    );
                } else if (error.request) {
                    toast.error(`"${error.request}" `);
                } else {
                    toast.error(`"${error.message}" `);
                }
            });
}

export function EditSpeaker(data,id) {

    return async (dispatch) =>
        Axios.put(window.$name + `/events/event/${id}`, data, {
            headers: {}
        }, {})
            .then(function (response) {
                console.log("api")
                if (response.status === 200) {
                    dispatch(FetchEvents())
                    toast.success("Event Editing ,successfully");
                    // dispatch({type: EDIT_EVENT_DATA, payload: response.data})
                }
            })
            .catch(function (error) {
                if (error.response) {
                    toast.error("Something is wrong,Please contact us");
                }
            });
}
