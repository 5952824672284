import React, {useEffect, useState} from "react";
import {Route,Redirect}from "react-router-dom";
import {connect}from "react-redux";
import Preloader from "./components/Preloader";
import Sidebar from "./components/Sidebar";
import Navbar from "./components/Navbar";

// export const PrivateRoute = ({authentication, component: Component, ...rest}) => (

    // <Route {...rest} component={props => (
    //     authentication.isLogin ? (
    //         <Component {...props}/>
    //     ) : (
    //         <Redirect to={{
    //             pathname: '/sign-in',
    //             state: { from: props.location }
    //         }}/>
    //     )
    // )}/>

// )



const  ProtectedRoute = ({authentication,component: Component, ...rest}) => {
    const [loaded, setLoaded] = useState(false);
    // const {authentication}=this.props
    console.log("hello",authentication)

    useEffect(() => {
        const timer = setTimeout(() => setLoaded(true), 1000);
        return () => clearTimeout(timer);
    }, []);

    const localStorageIsSettingsVisible = () => {
        return localStorage.getItem('settingsVisible') === 'false' ? false : true
    }

    const [showSettings, setShowSettings] = useState(localStorageIsSettingsVisible);

    const toggleSettings = () => {
        setShowSettings(!showSettings);
        localStorage.setItem('settingsVisible', !showSettings);
    }

    return (

        <Route {...rest} render={props => (
            authentication.isLogin ? (
            <>
                <Preloader show={loaded ? false : true}/>
                <Sidebar/>
                <main className="content">
                    <Navbar/>
                    <Component {...props} />
                    {/*<Footer toggleSettings={toggleSettings} showSettings={showSettings}/>*/}
                </main>
            </>
            ) : (
                <Redirect to={{
                    pathname: '/sign-in',
                    state: { from: props.location }
                }}/>
            )
        )}
        />

    );
};

const mapStateToProps = (state) => ({
    authentication: state.auth,
});

export default connect(mapStateToProps)(ProtectedRoute);