import {APPLY_TRAINING_DATA} from "./MytrainingActionTypes";

export default function mytrainingReducer(state = [], action) {
    switch (action.type) {
        case APPLY_TRAINING_DATA:
            return action.payload;
        default:
            return state;
    }
}
