import {LOADING_DATA, LOGIN_DATA} from "./LoginActionTypes";

let initialState = {
    isLogin: false,
}
export default function LoginReducer(state = initialState,action) {
    switch (action.type) {

        case LOGIN_DATA:

            return action.payload
        default:
            return state;
    }
}
