import React, {Fragment, useEffect, useState} from "react"
import {Breadcrumb, Button, ButtonGroup, Card, Col, Form, InputGroup, Row, Table} from "@themesberg/react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Moment from 'moment';
import {faCalendarAlt, faHome, faPrint, faSearch,} from "@fortawesome/free-solid-svg-icons";
import {connect, useDispatch, useSelector} from "react-redux";
import {FetchTrainingsByUser} from "../../../store/MyTraining";
import {FetchEventsByUser} from "../../../store/MyEvents";
import CurrencyFormat from "react-currency-format";

function MyEvent(props) {
    // const {DeleteTraining} = props
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(FetchEventsByUser());

    }, [dispatch]);

    const EventData = useSelector((state) => state.MyEvent); //Search Filter Data
    const [searchQuery, setSearchQuery] = useState("")

    function search() {
        if (EventData.length > 0) {
            // return EventData.filter(row => row.id.toLowerCase().indexOf(searchQuery) > - 1)
        } else {
            return null
        }


    }

    const columns = [
        {
            key: "title",
            text: "Title",
            className: "id",
            align: "left",
            sortable: false,
            cell: record => {
                return (
                    <Fragment>
                        {record.event_id.title}
                    </Fragment>
                );
            }
        },
        {
            key: "type",
            text: "Type",
            className: "type",
            align: "left",
            sortable: false,
            cell: record => {
                return (
                    <Fragment>
                        {record.event_id.type}
                        {record.event_id.type === 'online' ?
                            <span>-><a target='_blank' href={record.event_id.link}>Click to join</a></span> :
                            null
                        }
                    </Fragment>
                );
            }
        },
        {
            key: "location",
            text: "Location",
            className: "location",
            align: "left",
            sortable: false,
            cell: record => {
                return (
                    <Fragment>
                        {record.event_id.location}
                    </Fragment>
                );
            }
        },
        {
            key: "amount",
            text: "Amount",
            className: "amount",
            align: "left",
            sortable: false,
            cell: record => {
                return (
                    <Fragment>
                        {record.bill_id.amount}
                    </Fragment>
                );
            }
        },
        {
            key: "status",
            text: "Status",
            className: "status",
            sortable: false,
            cell: record => {
                return (
                    <Fragment>
                        {record.status === 'Paid' ?
                            <span className="badge-md badge bg-success">Paid</span> :
                            <span className="badge-md badge bg-danger">Not Paid</span>
                        }

                    </Fragment>
                );
            }
        },
        {
            key: "created_at",
            text: "Application Date",
            className: "created_at",
            sortable: false,
            cell: record => {
                return (
                    <Fragment>
                        {Moment(record.created_at).format('DD-MM-YYYY')}
                    </Fragment>
                );
            }
        },
    ];
    const config = {
        page_size: 5,
        length_menu: [5, 10, 20, 50, 100],
        button: {
            excel: true,
            print: true,
            extra: true,
        }
    }


    const extraButtons = [
        {
            className: "btn btn-primary buttons-pdf",
            title: "Export TEst",
            children: [
                <span>
                    <FontAwesomeIcon icon={faPrint}/>
                    {/*<i className="glyphicon glyphicon-print " aria-hidden="true"></i>*/}
                    </span>
            ],
            onClick: (event) => {
                console.log(event);
            },
        },
        {
            className: "btn btn-primary buttons-pdf",
            title: "Export TEst",
            children: [
                <span>
                   <FontAwesomeIcon icon={faPrint}/>
                    </span>
            ],
            onClick: (event) => {
                console.log(event);
            },
            onDoubleClick: (event) => {
                console.log("doubleClick")
            }
        },
    ]

    return (
        <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="d-block mb-4 mb-md-0">
                    <Breadcrumb className="d-none d-md-inline-block"
                                listProps={{className: "breadcrumb-dark breadcrumb-transparent"}}>
                        <Breadcrumb.Item><FontAwesomeIcon icon={faHome}/></Breadcrumb.Item>
                        <Breadcrumb.Item>Home</Breadcrumb.Item>
                        <Breadcrumb.Item active>Event</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <div className="btn-toolbar mb-2 mb-md-0">
                    <ButtonGroup>
                        <Button variant="outline-primary" size="sm">Export</Button>
                        <Button variant="outline-primary" size="sm">Share</Button>
                    </ButtonGroup>
                </div>
            </div>

            <div className="table-settings mb-4">
                <Row className="justify-content-between align-items-center">
                    <Col xs={6} md={6} xl={4} className="ps-md-0">
                        <h4>
                            <FontAwesomeIcon icon={faCalendarAlt} className="icon icon-xs text-dark w-30"/>
                            Zanzibar Law Society Event
                        </h4>
                    </Col>
                    <Col xs={6} md={6} lg={3} xl={4}>
                        <InputGroup>
                            <InputGroup.Text>
                                <FontAwesomeIcon icon={faSearch}/>
                            </InputGroup.Text>
                            <Form.Control
                                type="text"
                                placeholder="Search"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />
                        </InputGroup>
                    </Col>
                </Row>
            </div>

            <Card border="light" className="shadow-sm mb-4">
                <Card.Body className="pb-0">
                    <Table responsive className="table-centered  table-nowrap rounded mb-0">
                        <thead className="thead-light">
                        <tr>
                            <th className="border-0">#</th>
                            <th className="border-0">Title</th>
                            <th className="border-0">Type</th>
                            <th className="border-0">Amount</th>
                            <th className="border-0">Duration</th>
                            <th className="border-0">Event date</th>
                            {/*<th className="border-0 text-center">Actions</th>*/}
                        </tr>
                        </thead>
                        <tbody>{EventData.map((post, key) => (
                            <tr>
                                <td>
                                    {key + 1}
                                </td>
                                <td> {post.event_id.title}</td>
                                <td>{post.event_id.type}</td>
                                <td>
                                    {post.event_id.is_free === 'true' ?
                                        <CurrencyFormat
                                            value={post.event_id.amount}
                                            displayType={'text'}
                                            thousandSeparator={true}
                                            prefix={' TZS '}
                                            renderText={value => <span>{value} </span>}/>
                                        :
                                        <span className="badge-md badge bg-success"> Free </span>
                                    }
                                </td>
                                <td>{post.event_id.duration}</td>
                                <td> {Moment(post.event_id.event_date).format('DD-MM-YYYY')}</td>

                            </tr>

                        ))}

                        {/*<Tabledata posts={search(EventData)}/>*/}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        </>
    )

}

const mapDispatchToProps =
    {
        FetchTrainingsByUser
    };

export default connect(() => ({}), mapDispatchToProps)(MyEvent);

