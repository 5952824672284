import {WEBSITE_DATA} from "./websiteActionTypes";

export default function websiteReducer(state = [], action) {
    switch (action.type) {
        case WEBSITE_DATA:
            return action.payload;
        default:
            return state;
    }
}
