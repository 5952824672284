import React, {useEffect, useState} from "react";
import {AddEducationProfile, DeleteEducation} from "../../../store/eductionProfile";
import {connect, useDispatch, useSelector} from "react-redux";
import "../../../scss/invoice.css";
import {useParams} from "react-router";
import {FetchReceipts} from "../../../store/Receipt";
import Axios from "axios";
import {toast} from "react-toastify";
import Moment from "moment";

function Invoice() {
    const dispatch = useDispatch();
    const [invoiceDetail, setinvoiceDetail] = useState({});
    // console.log(invoiceDetail)
    const {order_id} = useParams();
    console.log(order_id)

    function BillByOrderId(order_id) {
        // console.log(data);
        Axios.get(window.$name + `/payment/bill/${order_id}`, {
            headers: {}
        }, {})
            .then(function (response) {
                console.log(response)
                if (response.status === 200) {
                    setinvoiceDetail(response.data[0])
                }

            })
            .catch(function (error) {
                if (error.response) {
                    toast.error("Something is wrong,Please contact us");
                }
            });
    }

    useEffect(() => {
        dispatch(FetchReceipts());
        BillByOrderId(order_id);
    }, [dispatch]);

    const ReceiptData = useSelector((state) => state.receipt);
    // console.log(WebsiteData)
    const today = new Date();

    return (
        <section className="wrapper-invoice">
            <div className="invoice">
                <div className="invoice-information">
                    <p>{ReceiptData.name}</p>
                    <p>{ReceiptData.Phone}</p>
                    <p>{ReceiptData.email}</p>
                    <p>{ReceiptData.address}</p>
                </div>
                <div>
                    <img src={ReceiptData.logo} alt="image-src" height="160"/>
                </div>
                <div className="text-center mt-6">
                    <h1>
                        {invoiceDetail.status === 'Not paid' ? <span>Invoice</span>
                            :
                            <span>Receipt</span>
                        }
                        <span>  # {invoiceDetail.order_id}</span>
                        &nbsp;
                        {invoiceDetail.status === 'Not paid' ?
                            <span className="badge-md badge bg-danger">Pending</span>
                            :
                            invoiceDetail.status === 'COMPLETED' ?
                                <span className="badge-md badge bg-success">Paid</span>
                                :
                                invoiceDetail.status === 'COMPLETED-CASH' ?
                                    <span className="badge-md badge bg-success">Paid cash</span>
                                    :
                                    <span className="badge-md badge bg-danger">Cancelled</span>
                        }
                    </h1>
                </div>
                <div className="invoice-head">
                    {invoiceDetail.created_by ?
                        <div className="head client-info">
                            <p><b>Member name</b>: {invoiceDetail.created_by.name}</p>
                            <p><b>Member number</b> : 000{invoiceDetail.created_by.id}</p>
                        </div>
                        : null
                    }
                    <div className="head client-data">
                        <p><b>Created Date </b> : {Moment(invoiceDetail.created_at).format('DD-MM-YYYY')}</p>
                        <p><b>Due Date</b> : {Moment(invoiceDetail.updated_at).format('DD-MM-YYYY')}</p>
                    </div>
                </div>
                <div className="invoice-body">
                    <table className="table">
                        <thead>
                        <tr>
                            <th className="text-start">Item Description</th>
                            <th className="text-start">Amount</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td className="text-start">{invoiceDetail.type}</td>
                            <td className="text-start">TZS {invoiceDetail.amount}</td>
                        </tr>
                        </tbody>
                    </table>
                    <div className="invoice-total-amount">
                        <p>Total : TZS {invoiceDetail.amount} </p>
                    </div>
                </div>
                <div>
                    <center>
                        <div><b>Finance Manager</b></div>
                        <img src={ReceiptData.signature} width="90" height="90"/>
                    </center>
                </div>
                <div className="invoice-footer text-center">
                    <br/>
                    © {today.getFullYear()} ZLS Wakili. All rights reserved.
                </div>
            </div>
        </section>
    )
}

const mapDispatchToProps = {
    AddEducationProfile, DeleteEducation
};

export default connect(() => ({}), mapDispatchToProps)(Invoice);

