import Axios from "axios";
import {EMAIL_DATA} from "./emailActionTypes";
import {toast} from "react-toastify";

export function FetchEmail() {
    return async (dispatch) =>
        Axios.get(window.$name + '/settings/custom/email/list', {
        })
            .then(function (response) {
                // console.log(response.data)
                if (response.status === 200) {
                    dispatch({type: EMAIL_DATA, payload: response.data});
                }
                // console.log(response.data);
            })
            .catch(function (error) {
                if (error.response) {
                    toast.error(
                        `"${error.response.data}" Error Status  "${error.response.status}" `
                    );
                } else if (error.request) {
                    toast.error(`"${error.request}" `);
                } else {
                    toast.error(`"${error.message}" `);
                }
            });
}

