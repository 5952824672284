import React from "react";
import {Col,Row, Container} from "@themesberg/react-bootstrap";

export function FooterLanding() {
    const today = new Date();
    return (
        <footer className="footer bg-white text-dark">
            <Container>
                <hr className="bg-gray my-5"/>
                <Row className="d-flex justify-content-between">
                    <Col md={4}>
                        <div className="mb-md-2">
                            © {today.getFullYear()} ZLS Wakili. All rights reserved.
                        </div>
                    </Col>
                    <Col className="mb-md-2" md={{span: 4, offset: 4}}>
                        <div className="mb-md-2">
                            Developed by <a href="https://getcoregroup.com/" target="_blank">
                            <span style={{color: 'gold'}}>GetCore Group Ltd</span>
                        </a>
                        </div>
                    </Col>
                </Row>
            </Container>
        </footer>
)
}