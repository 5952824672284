import Axios from "axios";
import {EDIT_ROLES_DATA, ROLES_DATA} from "./rolesActionTypes";
import {toast} from "react-toastify";
import {userToken} from "../Login";
// import {EDIT_PROFILE_DATA} from "../editProfile/EditProfileActionTypes";

export function AddRoles(value) {
    const data = {
        award: value.award,
        year_of_completion:value.year_of_completion,
        education_level: value.education_level,
        school_name: value.school_name,
        user_id: userToken().userId,
        name: value.name,
        display_name: value.display_name,
        permission: value.permission
    };
    return async (dispatch) =>
        Axios.post(window.$name + '/auth/roles/', data, {
            // headers: {
            //     Authentication: `Bearer ${userToken().accessToken}`,
            // },
        })
            .then(function (response) {
                if (response.status === 201) {
                    toast.success("Role have been added,successfully");
                    dispatch(FetchRoless())
                }
            })
            .catch(function (error) {
                if (error.response) {
                    toast.error("Something is wrong,Please contact us");
                }
            });
}

export function FetchRoless() {
    return async (dispatch) =>
        Axios.get(window.$name + '/auth/roles/', {
        })
            .then(function (response) {
                console.log("DATA",response.data)
                dispatch({type: ROLES_DATA, payload: response.data});
                // console.log(response.data);
            })
            .catch(function (error) {
                if (error.response) {
                    toast.error(
                        `"${error.response.data}" Error Status  "${error.response.status}" `
                    );
                } else if (error.request) {
                    toast.error(`"${error.request}" `);
                } else {
                    toast.error(`"${error.message}" `);
                }
            }
            );
}

export function EditRoles(value) {

    return async (dispatch) =>
        Axios.put(window.$name + `/profile/personal/information/${value.id}`, value, {
            headers: {
                Token: `Bearer ${userToken().accessToken}`,
            },
        })
            .then(function (response) {
                if (response.status === 200) {
                    toast.success("Education Editing ,successfully");
                    dispatch(FetchRoless())
                    dispatch({type: EDIT_ROLES_DATA, payload: response.data})
                }
            })
            .catch(function (error) {
                if (error.response) {
                    toast.error("Something is wrong,Please contact us");
                }
            });
}


export function EditEducation(value,id) {
    // console.log(value)
    const data = {
        user_id: userToken().userId,
        education_level: value.education_level,
        school_name: value.school_name,
        year_of_completion: value.year_of_completion,
        award:value.award
    };
    return async (dispatch) =>
        Axios.put(window.$name +`/profile/education/information/${id}`, data,{
            headers:{}
        },{})
            .then(function (response) {
                // console.log(response.data)
                if (response.status === 200) {
                    dispatch(FetchRoless())
                    toast.success("Education Editing ,successfully");
                    // dispatch({type: EDIT_ROLES_DATA, payload: response.data})
                }
            })
            .catch(function (error) {
                if (error.response) {
                    toast.error("Something is wrong,Please contact us");
                }
            });
}

export function DeleteEducation(id) {

    return async (dispatch) =>
        Axios.delete(window.$name + `/profile/education/information/${id}`,{
            headers: {},
        },{})
            .then(function (response) {
                if (response.status === 204) {
                    toast.success("Education Deleting ,successfully");
                    dispatch(FetchRoless())
                }
            })
            .catch(function (error) {
                if (error.response) {
                    toast.error("Something is wrong,Please contact us");
                }
            });
}