import {CLE_POINTS_TOTAL_DATA, EDIT_CLE_POINTS_TOTAL_DATA} from "./clePointsTotalActionTypes";

export default function clePointsTotalReducer(state = [], action) {
    switch (action.type) {
        case CLE_POINTS_TOTAL_DATA:
            return action.payload;
        case EDIT_CLE_POINTS_TOTAL_DATA:
            return action.payload;
        default:
            return state;
    }
}
