import React, {useEffect, useState} from "react";
import {Card, Col, Modal, Nav, Row, Tab} from '@themesberg/react-bootstrap';
import Work from "./profileComponents/work";
import Education from "./profileComponents/education";
import Datf from "./profileComponents/datf";
import PersonalDetails from "./profileComponents/personalDetails";
import {connect, useDispatch, useSelector} from "react-redux";
import {FetchPersonalProfiles} from "../../store/personalProfile";
import {FetchEducationProfiles} from "../../store/eductionProfile";
import {FetchKin} from "../../store/Kin";
import {FetchWork} from "../../store/workProfile";
import ProfileCover from "../../assets/img/zls-cover1.jpeg";
import Profile1 from "../../assets/img/logo/logo.png";
import {FetchUser} from "../../store/AccountProfiles";
import {faEdit} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {EditProfileForm} from "./profileComponents/editProfile";

function Profile(props) {
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);
    const [value, setValues] = useState({});

    const handleClose = () => setShow(false);
    const handleCloseEdit = () => setShowEditForm(false);
    const handleShow = () => setShow(true);
    useEffect(() => {
        dispatch(FetchUser());
        dispatch(FetchPersonalProfiles());
        dispatch(FetchEducationProfiles());
        dispatch(FetchKin());
        dispatch(FetchWork());

    }, [dispatch]);

    // const {}
    const personalData = useSelector((state) => state.personalProfile);
    const userProfile = useSelector((state) => state.userData);
    const educationData = useSelector((state) => state.educationProfile);
    const kinData = useSelector((state) => state.NextKin);
    const workData = useSelector((state) => state.WorkProfile);

    // console.log("workData", userProfile)

    const handleShowEditForm = (value) => {
        // const Values = formik.values = value;
        // console.log("Values", formik.values)
        if (value) {
            setShowEditForm(true);
            setValues(value);
        } else {
            setShowEditForm(false);
        }
    };

    function handleOnclickOption() {
        // AddEducationProfile(formik.values)
        setShow(false);
    }

    return (
        <>
            <div className="mt-5">
                <Row>
                    <Col xs={12} xl={4}>
                        <Row>
                            <Col xs={12}>
                                <Card border="light" className="text-center p-0 mb-4">
                                    <div style={{backgroundImage: `url(${ProfileCover})`}}
                                         className="profile-cover rounded-top"/>

                                    <Card.Body className="pb-5">
                                        {userProfile.image_url != null ?

                                            <Card.Img src={userProfile.image_url} alt="Neil Portrait"
                                                      className="user-avatar large-avatar rounded-circle mx-auto mt-n7  mb-4"/>
                                            :
                                            <Card.Img src={Profile1} alt="Neil Portrait"
                                                      className="user-avatar large-avatar rounded-circle mx-auto mt-n7 mb-4"/>

                                        }
                                        {personalData.length > 0 ?
                                            <>
                                                <Card.Title className="text-uppercase">
                                                    {userProfile.name}
                                                    {/*&nbsp;*/}
                                                    <FontAwesomeIcon
                                                        onClick={() => handleShowEditForm(userProfile)}
                                                        size="sm"
                                                        icon={faEdit}
                                                        className="me-1 text-warning"/>
                                                </Card.Title>
                                                {/*<span> </span>*/}
                                                <Card.Text>{userProfile.email}</Card.Text>
                                                {
                                                    personalData.length > 0 ?
                                                        <div>
                                                            {personalData.map((index) => (
                                                                <>
                                                                    {
                                                                        index.level !== 1 && index.status !== 'False' ?
                                                                            <div>
                                                                                <Card.Subtitle
                                                                                    className="fw-normal">Member
                                                                                    Number &nbsp;
                                                                                    <span
                                                                                        className="badge bg-warning badge-lg">  <b
                                                                                        style={{color: 'black'}}>000{index.id}</b></span></Card.Subtitle>
                                                                                <Card.Text
                                                                                    className="text-gray mb-2">{index.Chapter} Chapter</Card.Text>
                                                                                <Card.Text
                                                                                    className="text-gray mb-2">MEMBER</Card.Text>
                                                                                <Card.Text
                                                                                    className="text-gray mb-4">{index.Date_of_Birth}</Card.Text>
                                                                            </div>
                                                                            : userProfile.is_staff ?
                                                                            <div>
                                                                                <Card.Subtitle
                                                                                    className="fw-normal">Member
                                                                                    Number &nbsp;
                                                                                    <span
                                                                                        className="badge bg-warning badge-lg">  <b
                                                                                        style={{color: 'black'}}>000{index.id}</b></span></Card.Subtitle>
                                                                                <Card.Text
                                                                                    className="text-gray mb-2">{index.Chapter} Chapter</Card.Text>
                                                                                <Card.Text
                                                                                    className="text-gray mb-2">MEMBER</Card.Text>
                                                                                <Card.Text
                                                                                    className="text-gray mb-4">{index.Date_of_Birth}</Card.Text>
                                                                            </div>
                                                                            :
                                                                            <div>
                                                                                <span className="badge badge-lg bg-gradient-warning text-dark text-uppercase">
                                                                                    Account is inactive
                                                                                </span>
                                                                            </div>
                                                                    }

                                                                </>
                                                            ))}


                                                        </div>
                                                        :
                                                        <div/>
                                                }

                                                {/*<Button variant="primary" size="sm" className="me-2">*/}
                                                {/*    <FontAwesomeIcon icon={faUserPlus} className="me-1"/> Connect*/}
                                                {/*</Button>*/}
                                                {/*<Button variant="secondary" size="sm">Send Message</Button>*/}
                                            </>
                                            :
                                            null
                                        }
                                    </Card.Body>


                                </Card>
                            </Col>
                        </Row>
                    </Col>

                    <Col xs={12} xl={8}>
                        <div>
                            <Row>
                                <Col>
                                    <Tab.Container defaultActiveKey="personal">
                                        <Row>
                                            <Col className="col-12">
                                                <Nav className="nav-tabs">
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="personal" className="mb-sm-3 mb-md-0">
                                                            Personal
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="datf" className="mb-sm-3 mb-md-0">
                                                            Next of Kin
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="education" className="mb-sm-3 mb-md-0">
                                                            Education
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="work" className="mb-sm-3 mb-md-0">
                                                            Work
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                </Nav>
                                            </Col>
                                            <Col className="col-12">
                                                <Tab.Content>
                                                    <Tab.Pane eventKey="personal" className="py-4">
                                                        <PersonalDetails personalData={personalData}
                                                                         userProfile={userProfile}/>
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="datf" className="py-4">
                                                        <Datf kinData={kinData}/>
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="education" className="py-4">

                                                        <>
                                                            <Education educationData={educationData}/>
                                                        </>

                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="work" className="py-4">
                                                        <Work workData={workData}/>
                                                    </Tab.Pane>
                                                </Tab.Content>
                                            </Col>
                                        </Row>
                                    </Tab.Container>
                                </Col>
                            </Row>
                        </div>
                    </Col>

                </Row>

            </div>
            {/*model for adding NEXT OF KIN */}
            <Modal show={showEditForm} onHide={handleClose}>
                {/* eslint-disable-next-line react/jsx-no-undef */}
                <EditProfileForm profileData={value} handleShowEditForm={handleShowEditForm}/>
            </Modal>
            {/*end here modal for adding NEXT OF KIN*/}


        </>

    )

}

const mapDispatchToProps = {
    FetchUser
};

export default connect(() => ({}), mapDispatchToProps)(Profile);

