import React, {useEffect, useState} from "react";
import {Button, Col, Form, Modal} from "@themesberg/react-bootstrap";
import {connect, useDispatch, useSelector} from "react-redux";
import * as yup from "yup";
import {useFormik} from "formik";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAddressBook, faMicrophone} from "@fortawesome/free-solid-svg-icons";
import {AddEvent, EditEvent, FetchEvents} from "../../../store/Events";
import {AddSpeaker, EditSpeaker} from "../../../store/Speaker";
import {FetchStaffs} from "../../../store/staff";
import {userToken} from "../../../store/Login";
import {FetchApprovalRequest} from "../../../store/ApprovalProfile";

const validationSchema = yup.object().shape({
    cle_points: yup.string().required("This field is required"),
    speaker_id: yup.string().required("This field is required"),
});

function AddSpeakerForm(props) {
    const {AddSpeaker, EditSpeaker, handleShow, data} = props;
    const dispatch = useDispatch()
    useEffect(() => {

        dispatch(FetchEvents());
        dispatch(FetchApprovalRequest());

    }, [dispatch]);
    console.log(data)
    const userList = useSelector((state) => state.approvalRequest);

    //using useFormik
    const formik = useFormik({
        initialValues: {
            speaker_id: data.speaker_id,
            event_id: data.id,
            cle_points: data.cle_points
        },
        validationSchema,
    });

    function handleOnclickOption() {
        let form_data = new FormData();
        form_data.append("cle_points", formik.values.cle_points);
        form_data.append("speaker_id", formik.values.speaker_id);
        form_data.append("created_by", userToken().userId);
        form_data.append("event_id", formik.values.event_id);
        form_data.append("status", 0);
        AddSpeaker(form_data)
        handleShow(false);
        formik.resetForm({})
    }

    return (
        <div>
            <Modal.Header>
                <h4>
                    <FontAwesomeIcon icon={faMicrophone} className="icon"/>&nbsp;
                    <span> Add Speaker</span>
                </h4>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    {/*<Form.Select>*/}
                    {/*    {userList.map((index, value) => (*/}
                    {/*        <option value={index.id} key={value}>*/}
                    {/*            {index.user_id.name} (Roll no 000{index.id})*/}
                    {/*        </option>*/}
                    {/*    ))}*/}
                    {/*</Form.Select>*/}
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Speaker</Form.Label>
                        <Form.Select
                            className="form-control mb-0 text-uppercase"
                            onChange={formik.handleChange}
                            id="speaker_id"
                            name="speaker_id"
                            onBlur={formik.handleBlur}
                            value={formik.values.speaker_id}
                        >
                            <option value="" className="text-capitalize">Select speaker</option>
                            {userList.map((index) => (
                                <option value={index.id}>{index.user_id.name} (Roll no 000{index.id})</option>
                            ))
                            }
                        </Form.Select>
                        {formik.touched.speaker_id && formik.errors.speaker_id ? (
                            <div className="text-danger">{formik.errors.speaker_id}</div>
                        ) : null}
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>CLE points</Form.Label>
                        <input
                            type="text"
                            className="form-control mb-0"
                            autoComplete="off"
                            id="cle_points"
                            name="cle_points"
                            value={formik.values.cle_points}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.cle_points && formik.errors.cle_points ? (
                            <div className="text-danger">
                                {formik.errors.cle_points}
                            </div>
                        ) : null
                        }
                    </Form.Group>

                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button size="sm" variant="outline-dark" onClick={() => handleShow(false)}>
                    Close
                </Button>

                <Button
                    size="sm"
                    disabled={!(formik.isValid && formik.dirty)}
                    onClick={() => handleOnclickOption()}>
                    Save Changes
                </Button>

            </Modal.Footer>
        </div>
    )
}

const mapDispatchToProps = {
    AddSpeaker, EditSpeaker
};

export default connect(() => ({}), mapDispatchToProps)(AddSpeakerForm);

