import React, {Fragment, useEffect, useState} from "react"
import {
    Breadcrumb,
    Button,
    ButtonGroup,
    Card,
    Col,
    Dropdown,
    Form,
    InputGroup,
    Row,
    Table
} from "@themesberg/react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAddressBook, faCheck, faCog, faHome, faSearch} from "@fortawesome/free-solid-svg-icons";
import {connect, useDispatch, useSelector} from "react-redux";
import {FetchSubscription} from "../../../store/subscription";
import Moment from "moment";
import CurrencyFormat from "react-currency-format";

function Subscription(props) {
    const dispatch=useDispatch()
    useEffect(() => {
        dispatch(FetchSubscription());

    }, [dispatch]);

    const subscriptionData = useSelector((state) => state.subscription);
    const [searchQuery, setSearchQuery] = useState("")
    function search() {
        return subscriptionData.filter(row => row.name.toLowerCase().indexOf(searchQuery) > - 1)

    }
    const PageTrafficTable = () => {

        return (
            <Card border="light" className="shadow-sm ">
                <Card.Body className="pb-0">
                    <Table responsive className="table-centered text-center  table-nowrap rounded mb-0">
                        <thead className="thead-light">
                        <tr>
                            <th className="border-0">#</th>
                            <th className="border-0">Bill Number</th>
                            <th className="border-0">Name </th>
                            <th className="border-0">Features</th>
                            <th className="border-0">Price</th>
                            <th className="border-0">created at</th>
                        </tr>
                        </thead>
                        <tbody>
                        {search(subscriptionData).map((index,key)=>(
                            <tr className="text-center">
                                <td>
                                    <Card.Link href="#" className="text-primary fw-bold">{key + 1}</Card.Link>
                                </td>
                                <td>{index.bill_id}</td>
                                <td>{index.name}</td>
                                <td>{index.features}</td>
                                <td>
                                    {index.price === 'null' ?
                                        <span className="badge bg-warning badge-lg text-dark ">FREE</span>
                                        :
                                        <CurrencyFormat
                                            value={index.price}
                                            displayType={'text'}
                                            thousandSeparator={true}
                                            prefix={' TZS '}
                                            renderText={value => <span>{value} </span>}/>
                                    }
                                </td>
                                <td>  { Moment(index.created_at).format('DD-MM-YYYY')}</td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        );
    };

    return (
        <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="d-block mb-4 mb-md-0">
                    <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
                        <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
                        <Breadcrumb.Item>Home</Breadcrumb.Item>
                        <Breadcrumb.Item active>Subscription History</Breadcrumb.Item>
                    </Breadcrumb>
                    <h4> <FontAwesomeIcon icon={faAddressBook} className="icon icon-xs text-dark w-30"/> Subscription History</h4>
                    {/*<p className="mb-0">Your web analytics dashboard template.</p>*/}
                </div>
                <div className="btn-toolbar mb-2 mb-md-0">
                    <ButtonGroup>
                        <Button variant="outline-primary" size="sm">Share</Button>
                        <Button variant="outline-primary" size="sm">Export</Button>
                    </ButtonGroup>
                </div>
            </div>

            <div className="table-settings mb-4">
                <Row className="justify-content-between align-items-center">
                    <Col xs={8} md={6} lg={3} xl={4}>
                        <InputGroup>
                            <InputGroup.Text>
                                <FontAwesomeIcon icon={faSearch} />
                            </InputGroup.Text>
                            <Form.Control
                                type="text"
                                placeholder="Search"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)} />
                        </InputGroup>
                    </Col>
                    <Col xs={4} md={2} xl={1} className="ps-md-0 text-end">
                        <Dropdown as={ButtonGroup}>
                            <Dropdown.Toggle split as={Button} variant="link" className="text-dark m-0 p-0">
                <span className="icon icon-sm icon-gray">
                  <FontAwesomeIcon icon={faCog} />
                </span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dropdown-menu-xs dropdown-menu-right">
                                <Dropdown.Item className="fw-bold text-dark">Show</Dropdown.Item>
                                <Dropdown.Item className="d-flex fw-bold">
                                    10 <span className="icon icon-small ms-auto"><FontAwesomeIcon icon={faCheck} /></span>
                                </Dropdown.Item>
                                <Dropdown.Item className="fw-bold">20</Dropdown.Item>
                                <Dropdown.Item className="fw-bold">30</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                </Row>
            </div>

            <PageTrafficTable/>
        </>
    )

}

const mapDispatchToProps = {
    FetchSubscription
};

export default connect(() => ({}), mapDispatchToProps)(Subscription);

