import {CASH_DATA} from "./CashActionTypes";

export default function cashReducer(state = [], action) {
    switch (action.type) {
        case CASH_DATA:
            return action.payload;
        default:
            return state;
    }
}
