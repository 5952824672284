
import React, {useEffect} from "react";
import {Nav, Navbar, Container, Image} from '@themesberg/react-bootstrap';
import {Button} from "@themesberg/react-bootstrap";
import {HashLink} from "react-router-hash-link";
import {Routes} from "../../routes";
import Logo from "../../assets/img/logo/newLogo.png";
import {useDispatch, useSelector} from "react-redux";
import {FetchWebsite} from "../../store/website";

export  function  Header(){
    const  dispatch=useDispatch();
    useEffect(() => {
        dispatch(FetchWebsite());

    }, [dispatch]);

    const WebsiteData = useSelector((state) => state.website);
    // console.log(WebsiteData)
    const faq="https://docs.google.com/presentation/d/16j3dmF_D9yyiB1rMSzZ28-nL1YLTExw-23gM5pnQIOw/edit#slide=id.p"
return(
        <Navbar variant="white" expand="lg" bg="white" className="navbar-transparent navbar-theme-primary sticky-top">
            <Container className="position-relative px-3">
                <Navbar.Brand as={HashLink} to={Routes.Presentation.path} className=" d-flex align-items-start">
                    <Image className="avatar-logo" style={{ height:50}}  src={WebsiteData.logo}/>
                </Navbar.Brand>
                <div className="d-flex align-items-end">
                    {/*<Navbar.Collapse id="navbar-default-primary">*/}
                    <Nav className="navbar-nav-hover align-items-lg-center">
                            <Nav.Link as={HashLink} to={Routes.Signin.path} >
                                <Button size="sm" as={HashLink} to={Routes.Signin.path} variant="dark text-white" className="ms-3">
                                    Sign in
                                </Button>
                            </Nav.Link>
                        </Nav>
                    {/*</Navbar.Collapse>*/}
                    <Nav.Link as={HashLink} to={Routes.Signup.path}>
                        <Button size="sm" as={HashLink} to={Routes.Signup.path} variant="dark text-white" className="ms-3"> Sign up</Button>
                    </Nav.Link>
                    <Nav className="navbar-nav-hover align-items-lg-center">
                        <a href={faq}  target='_blank'>
                            <Button   size="sm" variant="dark text-white" className="ms-3 mb-2">
                                FAQ
                            </Button>
                        </a>
                    </Nav>
                    <Nav className="navbar-nav-hover align-items-lg-center">
                        <Nav.Link as={HashLink} to={Routes.Support.path} >
                            <Button size="sm" as={HashLink} to={Routes.Support.path} variant="dark text-white" className="ms-3">
                                Support
                            </Button>
                        </Nav.Link>
                    </Nav>
                </div>
            </Container>
</Navbar>
)
}