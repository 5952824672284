import {LOADING_DATA} from "./LoaderActionTypes";

export function Loader(value) {
    return async (dispatch) =>
        dispatch({type: LOADING_DATA, payload: { isLoading: value}});
    // if(value){
    //       toast.warning("Please wait while we are saving your data")
    //     }

}


