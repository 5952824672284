import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faUnlockAlt} from "@fortawesome/free-solid-svg-icons";
import {Button, Form, InputGroup} from '@themesberg/react-bootstrap';
// import {useHistory} from 'react-router-dom';
import Axios from "axios";
import {toast} from "react-toastify";
import {useFormik} from "formik";
import * as yup from "yup";
import {userToken} from "../../store/Login";
import {connect} from "react-redux";

const validationSchema = yup.object().shape({
    password: yup
        .string()
        .required("Please enter your New password")
        .matches(/^.*(?=.{8,}).*$/, "Password must contain at least 8 characters"),
    confirm_password: yup
        .string()
        .required("Please confirm your New password")
        .when("password", {
            is: (password) =>
                password && password.length > 0 ? true : false,
            then: yup
                .string()
                .oneOf([yup.ref(" password")], "Password doesn't match"),
        }),
});

function ChangePassword (props) {
    // const history = useHistory();
    // const {Logout}=props
    // const dispatch =useDispatch()
    const formik = useFormik({
        initialValues: {
            password: "",
            confirm_password: "",
        },
        validationSchema,
    });
    function NewPassword(value) {
        Axios.patch(window.$name + '/auth/change-password/', {
            password: value.password,
            user_id: userToken().userId,
        }, {
            headers: {}
        }).then(res => {
                // console.log(data)
                if (res.status === 200) {
                    console.log(res)
                    toast.success(res.data.message)
                    // dispatch(Logout(history));
                }
            }).catch(function (error) {
            if (error.response) {
                console.log(error.response)
                toast.error(error.response.data.detail);
            }
        });
    }

    return (
        <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 mt-5">
            <h3 className="mb-4">Change password</h3>
            <Form.Group id="password" className="mb-4">
                <Form.Label>New Password</Form.Label>
                <InputGroup>
                    <InputGroup.Text>
                        <FontAwesomeIcon icon={faUnlockAlt}/>
                    </InputGroup.Text>
                    <input
                        type="password"
                        className="form-control mb-0"
                        placeholder="New password"
                        id="password"
                        name="password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                </InputGroup>
                {formik.touched.password && formik.errors.password ? (
                    <div className="text-danger">
                        {formik.errors.password}
                    </div>
                ) : null}
            </Form.Group>
            <Form.Group id="confirmPassword" className="mb-4">
                <Form.Label>Confirm Password</Form.Label>
                <InputGroup>
                    <InputGroup.Text>
                        <FontAwesomeIcon icon={faUnlockAlt}/>
                    </InputGroup.Text>
                    <input
                        type="password"
                        className="form-control mb-0"
                        placeholder="Password"
                        id="confirm_password"
                        name="confirm_password"
                        value={formik.values.confirm_password}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                </InputGroup>
                {formik.touched.confirm_password && formik.errors.confirm_password ? (
                    <div className="text-danger">
                        {formik.errors.confirm_password}
                    </div>
                ) : null}
            </Form.Group>
            <Button variant="primary" className="w-100"
                    disabled={!(formik.isValid && formik.dirty)}
                    onClick={() => NewPassword(formik.values)}>
                Change password
            </Button>
        </div>
    );
};

const mapStateToProps = (state) => ({
    authentication: state.auth,
});
export default connect(mapStateToProps)( ChangePassword);

