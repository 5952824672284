import React, {useState} from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheck, faCheckDouble, faCog, faReceipt, faSearch} from '@fortawesome/free-solid-svg-icons';
import {Button, ButtonGroup,  Modal, Card, Col, Dropdown, Form, InputGroup, Row, Table} from '@themesberg/react-bootstrap';
import {Logout} from "../../../store/Login";
import {FetchPersonalProfiles} from "../../../store/personalProfile";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import Moment from "moment";
import CurrencyFormat from "react-currency-format";
import {decode as base64_decode} from "base-64";
import {CashPayment} from "./cashPayment";

function BillTable(props) {
    const {BillData} = props;
    const [bill, setBill] = useState({});
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    function Payment(data) {
        console.log("data", data)
        window.location.href = base64_decode(data)
    }

    //Search Filter Data
    const [searchQuery, setSearchQuery] = useState("")

    function search() {
        return BillData.filter(row => row.order_id.toLowerCase().indexOf(searchQuery) > -1)

    }
    function CashPaymentShow(data) {

        if (data) {
            setShow(true);
            setBill(data)
        } else {
            setShow(false);
        }
    }

    return (
        <>
            <div className="table-settings mb-4">
                <p>
                    {/*{dataTest}*/}
                </p>
                <Row className="justify-content-between align-items-center">
                    <Col xs={8} md={6} lg={3} xl={4}>
                        <InputGroup>
                            <InputGroup.Text>
                                <FontAwesomeIcon icon={faSearch}/>
                            </InputGroup.Text>
                            <Form.Control
                                type="text"
                                placeholder="Search"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />
                        </InputGroup>
                    </Col>
                    <Col xs={4} md={2} xl={1} className="ps-md-0 text-end">
                        <Dropdown as={ButtonGroup}>
                            <Dropdown.Toggle split as={Button} variant="link" className="text-dark m-0 p-0">
                <span className="icon icon-sm icon-gray">
                  <FontAwesomeIcon icon={faCog}/>
                </span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dropdown-menu-xs dropdown-menu-right">
                                <Dropdown.Item className="fw-bold text-dark">Show</Dropdown.Item>
                                <Dropdown.Item className="d-flex fw-bold">
                                    10 <span className="icon icon-small ms-auto"><FontAwesomeIcon
                                    icon={faCheck}/></span>
                                </Dropdown.Item>
                                <Dropdown.Item className="fw-bold">20</Dropdown.Item>
                                <Dropdown.Item className="fw-bold">30</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                </Row>
            </div>

            <Card border="light" className="shadow-sm mb-4">
                <Card.Body className="pb-0">
                    <Table responsive className="table-centered  table-nowrap rounded mb-0">
                        <thead className="thead-light">
                        <tr>
                            <th className="border-0">#</th>
                            <th className="border-0">Details</th>
                            <th className="border-0">Issued Date</th>
                            <th className="border-0">Total</th>
                            <th className="border-0">Status</th>
                            {/* <th className="border-0">Actions</th> */}
                        </tr>
                        </thead>
                        <tbody>
                        {search(BillData).map((index) => (
                                <tr>
                                    <td>
                                        <Link to={`/invoice/` + index.order_id}
                                              className="text-blue fw-bold">{index.order_id}</Link>
                                    </td>
                                    <td>{index.type}</td>
                                    <td>{Moment(index.created_at).format('DD-MM-YYYY')}</td>
                                    <td>
                                        {index.amount === 'null' ?
                                            <span className="badge bg-warning badge-lg text-dark ">FREE</span>
                                            :
                                            <CurrencyFormat
                                                value={index.amount}
                                                displayType={'text'}
                                                thousandSeparator={true}
                                                prefix={' TZS '}
                                                renderText={value => <span> {value} </span>}/>
                                        }
                                    </td>
                                    <td>
                                        {index.status === 'COMPLETED' ?
                                            <span className="badge-md badge bg-success">Paid</span>
                                            :
                                            index.status === 'CANCELLED' ?
                                                <span className="badge-md badge bg-danger">Cancelled</span>
                                                :
                                                <span className="badge-md badge bg-danger">Not Paid</span>
                                        }
                                    </td>

                                    {/* <td>
                                        {
                                            index.status !== 'COMPLETED' || index.status === 'CANCELLED' ?
                                                <>
                                                    <Button size="sm" onClick={() => Payment(index.selcom_response)}
                                                            variant="outline-primary">
                                                        <FontAwesomeIcon icon={faCheckDouble}
                                                                         className="icon icon-xs text-dark w-30"/>
                                                        Pay now
                                                    </Button>
                                                    &nbsp;
                                                    <Button size="sm" onClick={() => CashPaymentShow(index)}
                                                            variant="warning">
                                                        <FontAwesomeIcon icon={faReceipt}
                                                                         className="icon icon-xs text-dark w-30"/>
                                                        Cash payment
                                                    </Button>
                                                </>

                                                :
                                                <span className="badge badge-lg bg-gradient-warning text-dark">
                                                  No action
                                                </span>
                                        }
                                    </td> */}
                                </tr>
                            )
                        )}

                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
            {/*model for adding cash payment */}
            <Modal show={show} onHide={handleClose}>
                {/* eslint-disable-next-line react/jsx-no-undef */}
                <CashPayment billData={bill} handleShow={CashPaymentShow}/>
            </Modal>
            {/*end here modal for adding cash payment*/}


        </>
    );
}

const mapDispatchToProps = {
    Logout, FetchPersonalProfiles
};
export default connect(() => ({}), mapDispatchToProps)(BillTable);





