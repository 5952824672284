import React, {useEffect} from "react";
import {Breadcrumb, Button, ButtonGroup, Col, Nav, Row, Tab} from '@themesberg/react-bootstrap';
import {connect, useDispatch, useSelector} from "react-redux";
import {FetchBillByStatus} from "../../../store/BillReceipt";
import UnpaidBills from "./UnpaidBills";
import PaidBills from "./PaidBills";
import {useParams} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHome} from "@fortawesome/free-solid-svg-icons";

function AllBillsByUser(props) {
    const {name,user_id} = useParams();
    // const {name} = useParams();
    return (
        <div>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="d-block  mb-md-0">
                    <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
                        <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
                        <Breadcrumb.Item>Home</Breadcrumb.Item>
                        <Breadcrumb.Item active>Bill</Breadcrumb.Item>
                    </Breadcrumb>
                    <h4>Bills for {name} </h4>

                    {/*<p className="mb-0">Your web analytics dashboard template.</p>*/}
                </div>
                <div className="btn-toolbar mb-2 mb-md-0">
                    <ButtonGroup>
                        <Button variant="outline-primary" size="sm">Share</Button>
                        <Button variant="outline-primary" size="sm">Export</Button>
                    </ButtonGroup>
                </div>
            </div>
            <div className="mt-1">
                <Row>
                    <Col xs={12} xl={12}>
                        <div>
                            <Row>
                                <Col>
                                    <Tab.Container defaultActiveKey="unpaid">
                                        <Row>
                                            <Col className="col-12">
                                                <Nav className="nav-tabs">
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="unpaid" className="mb-sm-3 mb-md-0">
                                                            UNPAID
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="paid" className="mb-sm-3 mb-md-0">
                                                            PAID
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="cancelled" className="mb-sm-3 mb-md-0">
                                                            CANCELLED
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                </Nav>
                                            </Col>
                                            <Col className="col-12">
                                                <Tab.Content>
                                                    <Tab.Pane eventKey="unpaid" className="py-4">
                                                        <UnpaidBills user_id={user_id}/>
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="paid" className="py-4">
                                                        <PaidBills user_id={user_id}/>
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="cancelled" className="py-4">

                                                        <UnpaidBills user_id={user_id}/>

                                                    </Tab.Pane>
                                                </Tab.Content>
                                            </Col>
                                        </Row>
                                    </Tab.Container>
                                </Col>
                            </Row>
                        </div>
                    </Col>

                </Row>

            </div>
        </div>
    )
}

const mapDispatchToProps = {
    FetchBillByStatus
};

export default connect(() => ({}), mapDispatchToProps)(AllBillsByUser);

