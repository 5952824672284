import React, {useEffect, useState} from "react"
import {Button, Card, Modal, Table} from "@themesberg/react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faGlobe, faTrash} from "@fortawesome/free-solid-svg-icons";
import {connect, useDispatch, useSelector} from "react-redux";
import CustomEmailForm from "./CustomEmailForm";
import AsyncAction from "../../../../AsyncAction";
import {DeleteWeb, FetchWebsite} from "../../../../store/website";
import {FetchEmail} from "../../../../store/Email";

function CustomEmail(props) {
    const {DeleteWeb} = props
    const dispatch = useDispatch()
    const [Show, setShow] = useState(false);
    const [Value, setId] = useState(0);
    const handleClose = () => setShow(false);
    const handleShow = (value) => {
        if (Show) {
            setShow(false);
        } else {
            setShow(true);
            setId(value)

        }
    };
    useEffect(() => {
        dispatch(FetchEmail());

    }, [dispatch]);

    const emailData = useSelector((state) => state.email);

    const PageTrafficTable = () => {
        return (
            <Card border="light" className="shadow-sm ">
                <Card.Body className="pb-0">
                    <Table responsive className="table-centered text-center  table-nowrap rounded mb-0">
                        <thead className="thead-light">
                        <tr>
                            <th className="border-0">#</th>
                            <th className="border-0">To</th>
                            <th className="border-0">subject</th>
                            <th className="border-0">Message</th>
                            <th className="border-0">Attachment</th>
                            <th className="border-0 text-center">Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {emailData.map((index, key) => (
                            <tr className="text-center">
                                <td>
                                    {1 + key}
                                </td>
                                <td>{index.subject}</td>
                                <td>{index.message}</td>
                                <td>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        );
    };

    return (
        <>
            <div className="">
                <div className="d-block">
                    <div>
                        <h4><FontAwesomeIcon icon={faGlobe} className="icon icon-xs text-dark w-30"/>Email</h4>
                        <div className="container-fluid mb-2">
                            <Button size="sm" variant="outline-dark" onClick={() => handleShow(0)}>
                                Compose
                            </Button>
                        </div>
                    </div>
                </div>
                <PageTrafficTable/>
            </div>

            {/*model for adding education */}
            <Modal show={Show} onHide={handleClose}>
                <CustomEmailForm id={Value.id} data={Value} handleShow={handleShow}/>
            </Modal>
            {/*end here modal for adding education*/}
        </>
    )

}

const mapDispatchToProps = {
    FetchWebsite, DeleteWeb
};

export default connect(() => ({}), mapDispatchToProps)(CustomEmail);

