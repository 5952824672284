import React from "react";
import CancelImage from '../../assets/img/cancel.webp';
import {Col, Row, Card, Image, Button, Container} from '@themesberg/react-bootstrap';

import {Link} from 'react-router-dom';

import {Routes} from "../../routes";
import NotFoundImage from "../../assets/img/illustrations/404.svg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBan, faChevronLeft, faCross, faLightbulb} from "@fortawesome/free-solid-svg-icons";
import {decode as base64_decode} from "base-64";


function CancelPage() {
    const payment_url = localStorage.getItem('payment_url');
    const redirect_url = localStorage.getItem('current_url');

    function RedirectUrl() {
        window.location.href = base64_decode(redirect_url);
    }

    function Pay() {
        window.location.href = base64_decode(payment_url)
    }

    return (
        <main>
            <section className="vh-100 d-flex align-items-center justify-content-center">
                <div className="card">
                    <Container>
                        <div className="card-body">
                            {/*<Row>*/}
                            <div className="text-center d-flex align-items-center justify-content-center">
                                <div>
                                    <Card.Link as={Link} to={Routes.DashboardOverview.path}>
                                        <FontAwesomeIcon icon={faBan} style={{width: '40%', height: '40%'}}
                                                         className="text-danger"/>
                                    </Card.Link>
                                    <h2 className="text-dark mt-5">
                                        Cancelling <span className="fw-bolder"> Payment </span>
                                    </h2>
                                    <p className="lead my-4">
                                        Do you really want to cancel this payment ?
                                    </p>
                                    <div className="row">
                                        <div className="col-6">
                                            <button style={{width: '100%'}} onClick={() => RedirectUrl()}
                                                    className="btn btn-warning">
                                                Yes
                                            </button>
                                        </div>
                                        <div className="col-6">
                                            <button onClick={() => Pay()} style={{width: '100%'}}
                                                    className="btn btn-dark">
                                                No
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/*</Row>*/}
                        </div>
                    </Container>
                </div>
            </section>
        </main>
    );
}

export default CancelPage;
