import {BILL_DATA} from "./BillActionTypes";

export default function billReducer(state = [], action) {
    switch (action.type) {
        case BILL_DATA:
            return action.payload;
        default:
            return state;
    }
}
