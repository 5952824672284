import {LOADING_DATA} from "./LoaderActionTypes";

let initialState = {
    isLoading: false,
}
export default function LoaderReducer(state = initialState,action) {
    switch (action.type) {
        case LOADING_DATA:

            return action.payload
        default:
            return state;
    }
}
