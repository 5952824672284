import {Button, Card, Col, Form, Row} from "@themesberg/react-bootstrap";
import React from "react";
import {useFormik} from "formik";
import * as yup from "yup";
import AsyncAction from "../../../AsyncAction";
import classNames from "classnames";
import {connect} from "react-redux";
import {EditWork} from "../../../store/workProfile";
import {userToken} from "../../../store/Login";

const validationSchema = yup.object().shape({
    business_location: yup.string().required("This field is required"),
    employer: yup.string().required("This field is required"),
    number_of_advocates: yup.string().required("This field is required"),
    tin: yup.string().required("This field is required"),
    email: yup.string().required("This field is required"),
    address: yup.string().required("This field is required"),
    telephone: yup.string().required("This field is required"),
    category: yup.string().required("This field is required"),
    website: yup.string(),
});

function EditWorkProfile(props) {
    const {EditWork, workInfo ,setActive} = props
    // console.log("new");
    //using useFormik
    const formik = useFormik({
        initialValues: {
            user_id: userToken().userId,
            id: workInfo.id,
            business_location:workInfo.business_location,
            employer:workInfo.employer,
            number_of_advocates:workInfo.number_of_advocates,
            tin:workInfo.tin,
            telephone:workInfo.telephone,
            email:workInfo.email,
            address:workInfo.address,
            category:workInfo.category,
            website:workInfo.website,
        },
        validationSchema,
    });
    function EditAndClose(){
        EditWork(formik.values)
        setActive(false)
    }
    return (
        <div>
            <Card border="light" className="bg-white shadow-sm mt-3">
                <Card.Body>
                    <Row>
                        <Col md={6} className="mb-3">
                            <div className="form-group">
                                <Form.Label>Business Location</Form.Label>
                                <input
                                    type="text"
                                    className="form-control mb-0"
                                    autoComplete="off"
                                    id="business_location"
                                    name="business_location"
                                    placeholder="eg. 4th Flr Jangid Plaza, Chabruma Street,Dar es salaam"
                                    value={formik.values.business_location}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.location && formik.errors.business_location ? (
                                    <div className="text-danger">
                                        {formik.errors.business_location}
                                    </div>
                                ) : null
                                }
                            </div>
                        </Col>
                        <Col md={6} className="mb-3">
                            <div className="form-group">
                                <Form.Label>Law Firm/Employer</Form.Label>
                                <input
                                    type="text"
                                    className="form-control mb-0"
                                    autoComplete="off"
                                    id="employer"
                                    name="employer"
                                    placeholder="eg. ABC Attorneys"
                                    value={formik.values.employer}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.employer && formik.errors.employer ? (
                                    <div className="text-danger">
                                        {formik.errors.employer}
                                    </div>
                                ) : null
                                }
                            </div>
                        </Col>
                        <Col md={6} className="mb-3">
                            <div className="form-group">
                                <Form.Label>Number of Advocates at work Place</Form.Label>
                                <input
                                    type="number"
                                    className="form-control mb-0"
                                    autoComplete="off"
                                    id="number_of_advocates"
                                    name="number_of_advocates"
                                    placeholder="eg. 4"
                                    value={formik.values.number_of_advocates}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.number_of_advocates && formik.errors.number_of_advocates ? (
                                    <div className="text-danger">
                                        {formik.errors.number_of_advocates}
                                    </div>
                                ) : null
                                }
                            </div>
                        </Col>
                        <Col md={6} className="mb-3">
                            <div className="form-group">
                                <Form.Label>TIN</Form.Label>
                                <input
                                    type="number"
                                    className="form-control mb-0"
                                    autoComplete="off"
                                    id="tin"
                                    name="tin"
                                    placeholder="eg. 4565466"
                                    value={formik.values.tin}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.tin && formik.errors.tin ? (
                                    <div className="text-danger">
                                        {formik.errors.tin}
                                    </div>
                                ) : null
                                }
                            </div>
                        </Col>
                    </Row>
                    <Row>

                        <Col md={6} className="mb-3">
                            <div className="form-group">
                                <Form.Label>Phone</Form.Label>
                                <input
                                    type="number"
                                    className="form-control mb-0"
                                    autoComplete="off"
                                    id="telephone"
                                    name="telephone"
                                    placeholder="eg.255 767 456 546"
                                    value={formik.values.telephone}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.telephone && formik.errors.telephone ? (
                                    <div className="text-danger">
                                        {formik.errors.telephone}
                                    </div>
                                ) : null
                                }
                            </div>
                        </Col>
                        <Col md={6} className="mb-3">
                            <div className="form-group">
                                <Form.Label>Email</Form.Label>
                                <input
                                    type="email"
                                    className="form-control mb-0"
                                    autoComplete="off"
                                    id="email"
                                    name="email"
                                    placeholder="eg. name@company.go.tz"
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.email && formik.errors.email ? (
                                    <div className="text-danger">
                                        {formik.errors.email}
                                    </div>
                                ) : null
                                }
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6} className="mb-3">
                            <div className="form-group">
                                <Form.Label>Postal address</Form.Label>
                                <input
                                    type="text"
                                    className="form-control mb-0"
                                    autoComplete="off"
                                    id="address"
                                    name="address"
                                    placeholder="eg. Box 1052, dar es salaam"
                                    value={formik.values.address}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.address && formik.errors.address ? (
                                    <div className="text-danger">
                                        {formik.errors.address}
                                    </div>
                                ) : null
                                }
                            </div>
                        </Col>
                        <Col md={6} className="mb-3">
                            <div className="form-group">
                                <Form.Label>Work Category</Form.Label>
                                <select
                                    className="form-control mb-0"
                                    onChange={formik.handleChange}
                                    id="category"
                                    name="category"
                                    onBlur={formik.handleBlur}
                                    value={formik.values.category}
                                >
                                    <option value="">Select work category</option>
                                    <option value="Firm">Firm</option>
                                    <option value="Association">Association</option>
                                    <option value="Judiciary">Judiciary</option>
                                    <option value="Member of Parliament">Member of Parliament</option>
                                    <option value="Financial Institution">Financial Institution</option>
                                    <option value="Civil society organization">Civil society organization
                                    </option>
                                    <option value="Company">Company</option>
                                    <option value="Police Force or Prison Department">Police Force or Prison
                                        Department
                                    </option>
                                    <option value="Office of the attorney General">Office of the attorney
                                        General
                                    </option>
                                    <option value="Political Parties">Political Parties</option>
                                    <option value="Judge/Magistrate">Judge/Magistrate</option>
                                    <option value="Military">Military</option>
                                    <option value="Other">Other</option>
                                </select>
                                {formik.touched.category && formik.errors.category ? (
                                    <div className="text-danger">{formik.errors.category}</div>
                                ) : null}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="col-12">
                            <div className="form-group">
                                <Form.Label>Website  (Optional)</Form.Label>
                                <input
                                    type="text"
                                    className="form-control mb-0"
                                    autoComplete="off"
                                    id="website"
                                    name="website"
                                    placeholder="eg. name@company.com"
                                    value={formik.values.website}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.website && formik.errors.website ? (
                                    <div className="text-danger">
                                        {formik.errors.website}
                                    </div>
                                ) : null
                                }
                            </div>
                        </Col>
                    </Row>
                    <div className="mt-2" style={{float: 'right'}}>
                        {/*<Button disabled={!(formik.isValid && formik.dirty)} variant="outline-warning"*/}
                        {/*        type="submit">Submit</Button>*/}

                                <Button
                                    type="submit"
                                    size="sm"
                                    data-toggle="tooltip"
                                    data-placement="right"
                                    title="sign-in"
                                    variant="dark"
                                    onClick={()=>EditAndClose()}
                                    disabled={!(formik.isValid && formik.dirty)}
                                    className="w-100"
                                >
                                    Save changes
                                </Button>

                    </div>
                </Card.Body>
            </Card>
        </div>
    );

}

const mapDispatchToProps =
    {
        EditWork
    }
;

export default connect(() => (
    {}
), mapDispatchToProps)(EditWorkProfile);



