import React, {useState} from "react";
import {Button, Form, Modal} from "@themesberg/react-bootstrap";
import {useFormik} from "formik";
import * as yup from "yup";
import {connect} from "react-redux";
import {EditKin} from "../../../store/Kin";
import {Gender, Relations} from "../../../data/nextKin";

const validationSchema = yup.object().shape({
    address: yup.string().required("This field is required"),
    name: yup.string().required("This field is required"),
    email: yup.string().required("This field is required"),
    Phone: yup.string().required("This field is required"),
    relation: yup.string().required("This field is required"),
});

function KinEditForm(props) {
    const { EditKin,  kinData,handleShowEditForm} = props;
console.log(kinData)
    //using useFormik
    const formik = useFormik({
        initialValues: {
            address: kinData.address,
            name:kinData.name,
            relation:kinData.relation,
            gender: kinData.gender,
            Phone: kinData.Phone,
            email: kinData.email,
        },
        validationSchema,
    });
    // console.log("data",educationData)
    function handleOnclickOption(){
        EditKin(formik.values,kinData.id);
        handleShowEditForm(false);
        formik.resetForm({})
    }
    return (
        <div>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Full name</Form.Label>
                        <input
                            type="text"
                            className="form-control mb-0"
                            autoComplete="off"
                            id="name"
                            name="name"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.name && formik.errors.name ? (
                            <div className="text-danger">
                                {formik.errors.name}
                            </div>
                        ) : null
                        }
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Gender</Form.Label>
                        <select
                            className="form-control mb-0"
                            onChange={formik.handleChange}
                            id="gender"
                            name="gender"
                            onBlur={formik.handleBlur}
                            value={formik.values.gender}
                        >
                            {Gender.map((option) => (
                                <option value={option.name}>{option.name}</option>
                            ))}
                        </select>
                        {formik.touched.gender && formik.errors.gender ? (
                            <div className="text-danger">{formik.errors.gender}</div>
                        ) : null}
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Relationship</Form.Label>
                        <select
                            className="form-control mb-0"
                            onChange={formik.handleChange}
                            id="relation"
                            name="relation"
                            onBlur={formik.handleBlur}
                            value={formik.values.relation}
                        >
                            {Relations.map((option) => (
                                <option value={option.name}>{option.name}</option>
                            ))}

                        </select>
                        {formik.touched.relation && formik.errors.relation ? (
                            <div className="text-danger">{formik.errors.relation}</div>
                        ) : null}
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Phone</Form.Label>
                        <input
                            type="number"
                            className="form-control mb-0"
                            autoComplete="off"
                            id="Phone"
                            name="Phone"
                            placeholder="eg.255 767 456 546"
                            value={formik.values.Phone}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.Phone && formik.errors.Phone ? (
                            <div className="text-danger">
                                {formik.errors.Phone}
                            </div>
                        ) : null
                        }
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Email</Form.Label>
                        <input
                            type="text"
                            className="form-control mb-0"
                            autoComplete="off"
                            id="email"
                            name="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.email && formik.errors.email ? (
                            <div className="text-danger">
                                {formik.errors.email}
                            </div>
                        ) : null
                        }
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Address</Form.Label>
                        <input
                            type="text"
                            className="form-control mb-0"
                            autoComplete="off"
                            id="address"
                            name="address"
                            value={formik.values.address}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.address && formik.errors.address ? (
                            <div className="text-danger">
                                {formik.errors.address}
                            </div>
                        ) : null
                        }
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button size="sm" disabled={!(formik.isValid && formik.dirty)} variant="outline-dark" onClick={()=>handleShowEditForm(false)}>
                    Close
                </Button>
                <Button size="sm" onClick={() => handleOnclickOption()}>
                    Save Changes
                </Button>
            </Modal.Footer>
        </div>
    )
}
const mapDispatchToProps = {
    EditKin
};

export default connect(() => ({}), mapDispatchToProps)(KinEditForm);

