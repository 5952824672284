import Axios from "axios";
import {APPROVAL_PROFILES_DATA} from "./approvalProfileActionTypes";
import {toast} from "react-toastify";
import {userToken} from "../Login";
import {Loader} from "../loader";

export function ApprovalRequest(value) {
    // console.log(image);
    const data = {
        Date_of_Birth: value.Date_birth,
        employer: value.employer,
        Number_of_Advocates: value.Number_of_Advocates,
        tin: value.tin,
        Phone: value.Phone,
        email: value.email,
        Postal_address: value.Postal_address,
        work_category: value.work_category,
        Gender: value.gender,
        Marital_status: value.marital,
        Chapter: value.chapter,
        Spouse_name: value.Spouse_name,
        Passport_no: value.Passport_no,
        National_id_no: value.National_id_no,
        health_challenges: value.health_challenges,
        specialization: value.specialization,
        user_id: userToken().userId,
        // image:image
    };
    return async (dispatch) =>
        Axios.post(window.$name + `/profile/personal/${userToken().userId}`, data, {
            // headers: {
            //     Authentication: `Bearer ${userToken().accessToken}`,
            // },
        })
            .then(function (response) {
                if (response.status === 201) {
                    dispatch(Loader(false))
                    toast.success("Your Personal details have been added,successfully");
                    dispatch(FetchApprovalRequest())

                }
            })
            .catch(function (error) {
                if (error.response) {
                    toast.error("Something is wrong,Please contact us");
                }
            });
}

export function FetchApprovalRequest() {
    return async (dispatch) =>
        Axios.get(window.$name + '/profile/list', {
        })
            .then(function (response) {
                // console.log(response.data)
                    dispatch({type: APPROVAL_PROFILES_DATA, payload: response.data});
                // console.log(response.data);
            })
            .catch(function (error) {
                if (error.response) {
                    toast.error(
                        `"${error.response.data}" Error Status  "${error.response.status}" `
                    );
                } else if (error.request) {
                    toast.error(`"${error.request}" `);
                } else {
                    toast.error(`"${error.message}" `);
                }
            });
}
