import React, {useEffect, useState} from "react";
import {Button, Form, InputGroup, Modal, Row} from "@themesberg/react-bootstrap";
import {connect, useDispatch, useSelector} from "react-redux";
import * as yup from "yup";
import {useFormik} from "formik";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAddressBook, faEnvelope} from "@fortawesome/free-solid-svg-icons";
import Axios from "axios";
import {toast} from "react-toastify";
import {userToken} from "../../../../store/Login";
import {FetchUser} from "../../../../store/AccountProfiles";
import {FetchReceipts} from "../../../../store/Receipt";

const validationSchema = yup.object().shape({
    name: yup.string().required("This field is required"),
    Phone: yup.string().required("This field is required"),
    email: yup.string().required("This field is required"),
    address: yup.string().required("This field is required"),
});

function ReceiptForm(props) {
    const {handleShow, data} = props;
    const dispatch = useDispatch()
    //using useFormik
    const formik = useFormik({
        initialValues: {
            name: data.name,
            address: data.address,
            Phone: data.Phone,
            email: data.email,
        },
        validationSchema,
    });

    const [dataValue, setData] = useState({
        logo: "",
    });
    const [SignatureData, setSignatureData] = useState({
        signature: "",
    });

    const handleImageChange = (e) => {
        let newData = {...dataValue};
        newData["logo"] = e.target.files[0];
        // console.log()
        setData(newData);
    };
    const handleChangeSignature = (e) => {
        let newData = {...SignatureData};
        newData["signature"] = e.target.files[0];
        // console.log()
        setSignatureData(newData);
    };

    function addReceiptDetails() {
        let form_data = new FormData();
        // if (data.logo)
        // console.log(dataValue.logo)
        form_data.append("signature", SignatureData.signature, SignatureData.signature.name);
        form_data.append("logo", dataValue.logo, dataValue.logo.name);
        form_data.append("email", formik.values.email);
        form_data.append("Phone", formik.values.Phone);
        form_data.append("address", formik.values.address);
        form_data.append("name", formik.values.name);

        const Request = Axios.post(window.$name + '/settings/receipt/', form_data, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        }).then((res) => {
            // console.log(res);
            if (res.status === 201) {

                toast.success("Receipt data added success fully");
                handleShow(false);
                dispatch(FetchReceipts())
            }
            return res;
        }).catch((error) => {
            return error.response;
        });
        return Request;
    }

    function editReceiptDetails() {
        let form_data = new FormData();
        // if (data.logo)
        // console.log(dataValue.logo)
        form_data.append("logo", dataValue.logo, dataValue.logo.name);
        form_data.append("signature", SignatureData.signature, SignatureData.signature.name);
        form_data.append("email", formik.values.email);
        form_data.append("Phone", formik.values.Phone);
        form_data.append("address", formik.values.address);
        form_data.append("name", formik.values.name);

        const Request = Axios.put(window.$name + `/settings/receipt/${data.id}`, form_data, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        }).then((res) => {
            // console.log(res);
            if (res.status === 200) {

                toast.success("Receipt data updated success fully");
                handleShow(false);
                dispatch(FetchReceipts())
            }
            return res;
        }).catch((error) => {
            return error.response;
        });
        return Request;
    }


    function handleOnclickOption() {

        if (data != 0) {
            editReceiptDetails()
        } else {

            addReceiptDetails()
        }
        formik.resetForm({})
    }

    return (
        <div>
            <Modal.Header>
                <h4>
                    <FontAwesomeIcon icon={faAddressBook} className="icon"/>&nbsp;
                    Add Company Receipt Information
                </h4>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Name</Form.Label>
                        <input
                            type="text"
                            className="form-control mb-0"
                            autoComplete="off"
                            id="name"
                            name="name"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.name && formik.errors.name ? (
                            <div className="text-danger">
                                {formik.errors.name}
                            </div>
                        ) : null
                        }
                    </Form.Group>
                    <Form.Group id="email" className="mb-4">
                        <Form.Label>Email</Form.Label>
                        <InputGroup>
                            <InputGroup.Text>
                                <FontAwesomeIcon icon={faEnvelope}/>
                            </InputGroup.Text>
                            <input
                                type="email"
                                className="form-control mb-0"
                                placeholder="Enter email"
                                autoComplete="off"
                                id="email"
                                name="email"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                        </InputGroup>
                        {formik.touched.email && formik.errors.email ? (
                            <div className="text-danger">
                                {formik.errors.email}
                            </div>
                        ) : null}
                    </Form.Group>
                    <Form.Group id="phone" className="mb-4">
                        <Form.Label>Phone Number</Form.Label>
                        <input
                            type="number"
                            className="form-control mb-0"
                            autoComplete="off"
                            id="Phone"
                            name="Phone"
                            placeholder="eg.255 767 456 546"
                            value={formik.values.Phone}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.Phone && formik.errors.Phone ? (
                            <div className="text-danger">
                                {formik.errors.Phone}
                            </div>
                        ) : null
                        }
                    </Form.Group>
                    <Form.Group controlId="formFile" className="mb-3">
                        <Form.Label>Company Logo</Form.Label>
                        <Form.Control
                            type="file"
                            name="logo"
                            accept="image/jpeg,image/png,image/gif"
                            onChange={(e) => {
                                handleImageChange(e);
                            }}
                        />

                    </Form.Group>
                    <Form.Group controlId="formFile" className="mb-3">
                        <Form.Label>Finance manager Signature</Form.Label>
                        <Form.Control
                            type="file"
                            name="logo"
                            accept="image/jpeg,image/png,image/gif"
                            onChange={(e) => {
                                handleChangeSignature(e);
                            }}
                        />

                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Company Address</Form.Label>
                        <textarea
                            className="form-control mb-0"
                            autoComplete="off"
                            id="address"
                            name="address"
                            value={formik.values.address}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.address && formik.errors.address ? (
                            <div className="text-danger">
                                {formik.errors.address}
                            </div>
                        ) : null
                        }
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button size="sm" variant="outline-dark" onClick={() => handleShow(false)}>
                    Close
                </Button>

                <Button
                    size="sm"
                    // disabled={!(formik.isValid && formik.dirty)}
                    onClick={() => handleOnclickOption()}>
                    Save Changes
                </Button>


            </Modal.Footer>
        </div>


    )
}

const mapDispatchToProps = {};

export default connect(() => ({}), mapDispatchToProps)(ReceiptForm);

