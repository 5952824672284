import React, {useEffect, useState} from "react"
import {
    Breadcrumb,
    Button,
    Card,
    Col,
    Form,
    InputGroup, Modal,
    Row,
    Table
} from "@themesberg/react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCalendarAlt, faCheckDouble, faHome, faSearch
} from "@fortawesome/free-solid-svg-icons";
import {connect, useDispatch, useSelector} from "react-redux";
import Moment from "moment";
import {FetchApprovalRequest} from "../../../store/ApprovalProfile";
import {Link} from "react-router-dom";
import {userToken} from "../../../store/Login";
import Axios from "axios";
import {decode as base64_decode} from "base-64";
import {toast} from "react-toastify";
import AsyncAction from "../../../AsyncAction";

function RequestApproval() {
    const dispatch = useDispatch()
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [Value, setValues] = useState({});

    function handleShow(value) {
        setValues(value)
        if (Value) {
            console.log("value", Value)
            setShow(true)
        }
    }

    useEffect(() => {
        dispatch(FetchApprovalRequest());
        // dispatch(FetchPlans());

    }, [dispatch]);

    const RequestApprovalData = useSelector((state) => state.approvalRequest);

    //Search Filter Data
    const [searchQuery, setSearchQuery] = useState("")

    function search() {
        return RequestApprovalData.filter(row => row.email.toLowerCase().indexOf(searchQuery) > -1)

    }

    const current = new Date();
    const date = current;
    const formated_date = Moment(date).toISOString()
    const redirectUrl = window.$nameweb + '/bill-list';

    // console.log(redirectUrl)

    function ChangePlan(data) {
        // console.log(data)
        const billData = {
            item_id: data.level.id,
            type: "Plan - " + data.level.name,
            amount: data.level.price,
            status: "Not paid",
            created_by: data.user_id.id,
            timestamp: formated_date,
            redirectUrl: redirectUrl,
            key: ''
        }
        Axios.post(window.$name + `/payment/create/bill/${data.user_id.id}`, billData, {
            headers: {}
        }, {})
            .then(function (response) {
                if (response.status === 201) {
                    const EmailData={
                        selcom_link:base64_decode(response.data[1].data[0].payment_gateway_url),
                        email:data.user_id.email
                    }
                    Axios.post(window.$name + `/payment/email/`, EmailData, {
                        headers: {}
                    }, {})
                        .then(function (response) {
                            if (response.status === 200) {
                                toast.success(`Member 000${data.user_id.id} has been Approved. An invoice has been sent to the member`)
                            }
                        })
                        .catch(function (error) {
                            if (error.response) {
                                if (error.response.status === 401) {
                                    toast.error(error.response.data);
                                } else {
                                    toast.error("email failed to be sent");
                                }
                            }
                        });
                    // window.location.href = base64_decode(response.data[1].data[0].payment_gateway_url);
                }
            })
            .catch(function (error) {
                if (error.response) {
                    if (error.response.status === 401) {
                        toast.error(error.response.data);
                    } else {
                        toast.error("Something is wrong,Please contact us");
                    }
                }
            });
    }

    return (
        <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="d-block mb-4 mb-md-0">
                    <Breadcrumb className="d-none d-md-inline-block"
                                listProps={{className: "breadcrumb-dark breadcrumb-transparent"}}>
                        <Breadcrumb.Item><FontAwesomeIcon icon={faHome}/></Breadcrumb.Item>
                        <Breadcrumb.Item>Home</Breadcrumb.Item>
                        <Breadcrumb.Item active>Request Approval</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                {/*<div className="btn-toolbar mb-2 mb-md-0">*/}
                {/*    <ButtonGroup>*/}
                {/*        <Button size="sm" variant="dark" onClick={() => handleShow(0)}>*/}
                {/*            Add Request Approval*/}
                {/*        </Button>*/}
                {/*        <Button variant="outline-primary" size="sm">Export</Button>*/}
                {/*    </ButtonGroup>*/}
                {/*</div>*/}
            </div>

            <div className="table-settings mb-4">
                <Row className="justify-content-between align-items-center">
                    <Col xs={6} md={6} xl={4} className="ps-md-0">
                        <h4>
                            <FontAwesomeIcon icon={faCalendarAlt} className="icon icon-xs text-dark w-30"/>
                            Request for Approval
                        </h4>
                    </Col>
                    <Col xs={6} md={6} lg={3} xl={4}>
                        <InputGroup>
                            <InputGroup.Text>
                                <FontAwesomeIcon icon={faSearch}/>
                            </InputGroup.Text>
                            <Form.Control
                                type="text"
                                placeholder="Search"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />
                        </InputGroup>
                    </Col>
                </Row>
            </div>

            <Card border="light" className="shadow-sm mb-4">
                <Card.Body className="pb-0">
                    <Table responsive className="table-centered  table-nowrap rounded mb-0">
                        <thead className="thead-light">
                        <tr>
                            <th className="border-0">#</th>
                            <th className="border-0">Name</th>
                            <th className="border-0">Phone</th>
                            <th className="border-0">Member Details</th>
                            <th className="border-0">Requested date</th>
                            <th className="border-0">Status</th>
                            <th className="border-0 text-center">Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {search(RequestApprovalData).map((index, key) => (
                                <tr>
                                    <td>
                                        {
                                            key + 1
                                        }
                                    </td>
                                    <td>
                                        <span className="row text-uppercase">{index.user_id.name}</span>
                                        <b><span className="row text-dark">ROLL NO 000{index.id}</span></b>
                                    </td>
                                    <td>{index.Phone}</td>
                                    <td>
                                        <b><span className="row text-dark">{index.level.name}</span></b>
                                        <a onClick={() => handleShow(index)} className="row text-capitalize">
                                            {index.specialization.length > 30 ?
                                                `${index.specialization.substring(0, 30)} ...` : index.specialization
                                            }</a>

                                    </td>
                                    <td>{Moment(index.created_at).format('DD-MM-YYYY')}</td>
                                    <td>
                                        {
                                            index.status === 'True' ?
                                                <span className="badge badge-lg bg-warning text-dark"> Approved </span>
                                                :
                                                <span className="badge badge-lg bg-dark text-white"> Not Approved </span>
                                        }
                                    </td>
                                    <td>
                                        {
                                            index.status !== 'True' ?
                                                <Button size="sm" onClick={() => ChangePlan(index)}
                                                        variant="outline-primary">
                                                    <FontAwesomeIcon icon={faCheckDouble}
                                                                     className="icon icon-xs text-dark w-30"/>
                                                    Approve
                                                </Button>
                                                :
                                                <span className="badge badge-lg bg-gradient-warning text-dark">
                                                  No action
                                                </span>
                                        }

                                        &nbsp;&nbsp;
                                    </td>
                                </tr>
                            )
                        )}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
            {/* <!-- Modal confirm logout --> */}
            <Modal
                show={show}
                onHide={handleClose}
            >
                <Modal.Header>
                    <h5>Member Details</h5>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <h6>Member Level</h6>
                        {Value.level ?
                            <span>
                                {Value.level.name}
                            </span> :
                            null
                        }
                    </div>

                    <div className="mt-3">
                        <h6>Specialization</h6>
                        <p>
                            {Value.specialization}
                        </p>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => handleClose()} size="sm" variant="warning">
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

const mapDispatchToProps =
    {
        FetchApprovalRequest
    };

export default connect(() => ({}), mapDispatchToProps)(RequestApproval);

