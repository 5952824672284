import React, {useEffect, useState} from "react";
import {Button, Form, Modal} from "@themesberg/react-bootstrap";
import {connect, useDispatch} from "react-redux";
import * as yup from "yup";
import {useFormik} from "formik";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAddressBook} from "@fortawesome/free-solid-svg-icons";
import {AddEvent, EditEvent, FetchEvents} from "../../../store/Events";

const validationSchema = yup.object().shape({
    type: yup.string().required("This field is required"),
    title: yup.string().required("This field is required"),
    // description: yup.string().required("This field is required"),
    // location: yup.string().required("This field is required"),
    is_free: yup.string().required("This field is required"),
    duration: yup.string().required("This field is required"),
    event_date: yup.string().required("This field is required"),
    cle_points: yup.string().required("This field is required"),
});

function AddStaffForm(props) {
    const {AddEvent, EditEvent, handleShow, data} = props;
    const dispatch = useDispatch()
    useEffect(() => {

        dispatch(FetchEvents());

    }, [dispatch]);

    // console.log(data)

    //using useFormik
    const formik = useFormik({
        initialValues: {
            id: data.id,
            type: data.type,
            title: data.title,
            description: data.description,
            location: data.location,
            is_free: data.is_free,
            amount: data.amount,
            duration: data.duration,
            event_date: data.event_date,
            cle_points: data.cle_points,
            link: data.link
        },
        validationSchema,
    });
    const [Imagedata, setData] = useState({
        image_url: "",
    });

    const handleImageChange = (e) => {
        let newData = {...Imagedata};
        newData["image_url"] = e.target.files[0];
        setData(newData);
    };

    function handleOnclickOption() {
        let form_data = new FormData();
        if (Imagedata.image_url)
            form_data.append("image_url", Imagedata.image_url, Imagedata.image_url.name);
        form_data.append("description", formik.values.description);
        form_data.append("title", formik.values.title);
        form_data.append("type", formik.values.type);
        form_data.append("location", formik.values.location);
        form_data.append("is_free", formik.values.is_free);
        form_data.append("event_date", formik.values.event_date);
        form_data.append("amount", formik.values.amount);
        form_data.append("duration", formik.values.duration);
        form_data.append("cle_points", formik.values.cle_points);
        form_data.append("link", formik.values.link);
        form_data.append("id", formik.values.id);
        if (data !== 0) {
            EditEvent(form_data, formik.values.id)
            // console.log('dsffdfdsfdsf')
            handleShow(false);
        } else {
            AddEvent(form_data)
            handleShow(false);
        }
        formik.resetForm({})
    }


    return (
        <div>
            <Modal.Header>
                <h4>
                    <FontAwesomeIcon icon={faAddressBook} className="icon"/>&nbsp;
                    {
                        data !== 0 ?
                            <span> Edit Event</span>
                            :
                            <span> Add Event</span>
                    }
                </h4>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Event Type</Form.Label>
                        <Form.Select
                            className="form-control mb-0"
                            onChange={formik.handleChange}
                            id="type"
                            name="type"
                            onBlur={formik.handleBlur}
                            value={formik.values.type}
                        >
                            <option value="">Select Event Type</option>
                            <option value="online">Webinar (Online)</option>
                            <option value="physical">Seminars (Physical)</option>
                        </Form.Select>

                        {formik.touched.type && formik.errors.type ? (
                            <div className="text-danger">{formik.errors.type}</div>
                        ) : null}
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Title</Form.Label>
                        <input
                            type="text"
                            className="form-control mb-0"
                            autoComplete="off"
                            id="title"
                            name="title"
                            value={formik.values.title}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.title && formik.errors.title ? (
                            <div className="text-danger">
                                {formik.errors.title}
                            </div>
                        ) : null
                        }
                    </Form.Group>
                    {
                        formik.values.type === 'online' ?
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Webinar (Online) Link</Form.Label>
                                <input
                                    type="text"
                                    className="form-control mb-0"
                                    autoComplete="off"
                                    id="link"
                                    name="link"
                                    value={formik.values.link}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.link && formik.errors.link ? (
                                    <div className="text-danger">
                                        {formik.errors.link}
                                    </div>
                                ) : null
                                }
                            </Form.Group>
                            : null
                    }
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Description</Form.Label>
                        <textarea
                            className="form-control mb-0"
                            autoComplete="off"
                            id="description"
                            name="description"
                            value={formik.values.description}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.description && formik.errors.description ? (
                            <div className="text-danger">
                                {formik.errors.description}
                            </div>
                        ) : null
                        }
                    </Form.Group>
                    {
                        formik.values.type !== 'online' ?
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Location</Form.Label>
                                <input
                                    type="text"
                                    className="form-control mb-0"
                                    autoComplete="off"
                                    id="location"
                                    name="location"
                                    value={formik.values.location}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.location && formik.errors.location ? (
                                    <div className="text-danger">
                                        {formik.errors.location}
                                    </div>
                                ) : null
                                }
                            </Form.Group>
                            : null
                    }
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Payment Mode</Form.Label>
                        <Form.Select
                            className="form-control mb-0"
                            onChange={formik.handleChange}
                            id="is_free"
                            name="is_free"
                            onBlur={formik.handleBlur}
                            value={formik.values.is_free}
                        >
                            <option value="">Select mode</option>
                            <option value="true">Paid</option>
                            <option value="false">Free</option>
                        </Form.Select>
                        {formik.touched.is_free && formik.errors.is_free ? (
                            <div className="text-danger">{formik.errors.is_free}</div>
                        ) : null}
                    </Form.Group>
                    {
                        formik.values.is_free === 'true' ?
                            <>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Amount</Form.Label>
                                    <input
                                        type="text"
                                        className="form-control mb-0"
                                        autoComplete="off"
                                        id="amount"
                                        name="amount"
                                        value={formik.values.amount}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                    {formik.touched.amount && formik.errors.amount ? (
                                        <div className="text-danger">
                                            {formik.errors.amount}
                                        </div>
                                    ) : null
                                    }
                                </Form.Group>
                            </>
                            : null
                    }

                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Duration</Form.Label>
                        <input
                            type="text"
                            className="form-control mb-0"
                            autoComplete="off"
                            id="duration"
                            name="duration"
                            value={formik.values.duration}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.duration && formik.errors.duration ? (
                            <div className="text-danger">
                                {formik.errors.duration}
                            </div>
                        ) : null
                        }
                    </Form.Group>
                    <Form.Group controlId="formFile" className="mb-3">
                        <Form.Label>Event Image</Form.Label>
                        <Form.Control
                            type="file"
                            name="image_url"
                            accept="image/jpeg,image/png,image/gif"
                            onChange={(e) => {
                                handleImageChange(e);
                            }}
                        />

                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Event Date</Form.Label>
                        <input
                            type="date"
                            className="form-control mb-0"
                            autoComplete="off"
                            id="event_date"
                            name="event_date"
                            value={formik.values.event_date}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.event_date && formik.errors.event_date ? (
                            <div className="text-danger">
                                {formik.errors.event_date}
                            </div>
                        ) : null
                        }
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>CLE points</Form.Label>
                        <input
                            type="text"
                            className="form-control mb-0"
                            autoComplete="off"
                            id="cle_points"
                            name="cle_points"
                            value={formik.values.cle_points}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.cle_points && formik.errors.cle_points ? (
                            <div className="text-danger">
                                {formik.errors.cle_points}
                            </div>
                        ) : null
                        }
                    </Form.Group>


                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button size="sm" variant="outline-dark" onClick={() => handleShow(false)}>
                    Close
                </Button>

                <Button
                    size="sm"
                    disabled={!(formik.isValid && formik.dirty)}
                    onClick={() => handleOnclickOption()}>
                    Save Changes
                </Button>


            </Modal.Footer>
        </div>
    )
}

const mapDispatchToProps = {
    AddEvent, EditEvent
};

export default connect(() => ({}), mapDispatchToProps)(AddStaffForm);

