import {BILL_RECEIPT_DATA} from "./BillReceiptActionTypes";

export default function billReceiptReducer(state = [], action) {
    switch (action.type) {
        case BILL_RECEIPT_DATA:
            return action.payload;
        default:
            return state;
    }
}
