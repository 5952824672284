import React, {useEffect, useState} from "react"
import {Button, Card, Modal, Table} from "@themesberg/react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faGlobe,faTrash} from "@fortawesome/free-solid-svg-icons";
import {connect, useDispatch, useSelector} from "react-redux";
import WebConfigForm from "./webconfigForm";
import AsyncAction from "../../../../AsyncAction";
import {DeleteWeb, FetchWebsite} from "../../../../store/website";

function WebConfig(props) {
    const {DeleteWeb}=props
    const dispatch=useDispatch()
    const [Show, setShow] = useState(false);
    const [Value, setId] = useState(0);
    const handleClose = () => setShow(false);
    const handleShow = (value) => {
        if (Show) {
            setShow(false);
        } else {
            setShow(true);
            setId(value)

        }
    };
    useEffect(() => {
        dispatch(FetchWebsite());

    }, [dispatch]);

    const websiteData  = useSelector((state) => state.website);
    const PageTrafficTable = () => {
        return (
            <Card border="light" className="shadow-sm ">
                <Card.Body className="pb-0">
                    <Table responsive className="table-centered text-center  table-nowrap rounded mb-0">
                        <thead className="thead-light">
                        <tr>
                            <th className="border-0">#</th>
                            <th className="border-0">Title </th>
                            <th className="border-0">Description</th>
                            <th className="border-0">website Logo</th>
                            <th className="border-0">Default Profile photo</th>
                            <th className="border-0 text-center">Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {[websiteData] .map((index)=>(
                            <tr className="text-center">
                                <td>
                                    <Card.Link href="#" className="text-primary fw-bold">{index.id}</Card.Link>
                                </td>
                                <td>{index.name}</td>
                                <td>{index.description}</td>
                                <td>
                                    <img src={index.logo} alt="Neil Portrait" className="custom-avatar"/>
                                </td>
                                <td>
                                    <img src={index.default_logo} alt="Neil Portrait" className="custom-avatar"/>
                                </td>
                                <td className="text-center">
                                    <Button size="sm" onClick={() => handleShow(index)} variant="outline-primary">
                                        <FontAwesomeIcon icon={faEdit}  className="icon icon-xs text-dark w-30"/>Edit</Button>
                                    &nbsp;&nbsp;
                                    <AsyncAction
                                        action={() => DeleteWeb(index.id)}
                                        render={({run, loading}) => (
                                            <Button
                                                size="sm"
                                                variant="outline-danger"
                                                type="submit"
                                                data-toggle="tooltip"
                                                data-placement="right"
                                                title="sign-in"
                                                onClick={run}
                                            >  <FontAwesomeIcon icon={faTrash} className="icon icon-xs text-dark w-30"/>
                                                Delete
                                            </Button>
                                        )}
                                    />
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        );
    };

    return (
        <>
            <div className="">
                <div className="d-block">
                    <div>
                        <h4> <FontAwesomeIcon icon={faGlobe} className="icon icon-xs text-dark w-30"/> Web information</h4>
                        <div className="container-fluid mb-2">
                            <Button size="sm" variant="outline-dark"  onClick={() => handleShow(0)}>
                                Add Web information
                            </Button>
                        </div>
                    </div>
                </div>
                <PageTrafficTable/>
            </div>

            {/*model for adding education */}
            <Modal show={Show} onHide={handleClose}>
                <WebConfigForm id={Value.id} data={Value} handleShow={handleShow}/>
            </Modal>
            {/*end here modal for adding education*/}
        </>
    )

}

const mapDispatchToProps = {
    FetchWebsite,DeleteWeb
};

export default connect(() => ({}), mapDispatchToProps)(WebConfig);

