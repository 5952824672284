import React from "react";
import {Image} from "@themesberg/react-bootstrap";
import NotFoundImage from "../../../assets/img/no_record.png";

export default  function NotFoundPage(){
    return(
        <div className="d-flex justify-content-center mt-4">
         <Image src={NotFoundImage}/>
        </div>
    )
}