import {EDIT_ROLES_DATA, ROLES_DATA} from "./rolesActionTypes";
export default function rolesReducer(state = [], action) {
  switch (action.type) {
    case ROLES_DATA:
      return action.payload;
    case EDIT_ROLES_DATA:
      return action.payload;
    default:
      return state;
  }
}
