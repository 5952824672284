import React, {useEffect, useState, Fragment} from "react"
import {Button, Card} from "@themesberg/react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Moment from 'moment';
import {
    faPrint,
} from "@fortawesome/free-solid-svg-icons";
import {connect, useDispatch, useSelector} from "react-redux";
import ReactDatatable from '@ashvin27/react-datatable';
import {faLeanpub} from "@fortawesome/free-brands-svg-icons";
import {FetchTrainingsByUser} from "../../../store/MyTraining";

function MyTraining(props) {
    // const {DeleteTraining} = props
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(FetchTrainingsByUser());

    }, [dispatch]);

    const TrainingData = useSelector((state) => state.Mytraining);
    const columns = [
      {
            key: "title",
            text: "Title",
            className: "id",
            align: "left",
            sortable: false,
               cell: record => {
                return (
                    <Fragment>
                            {record.training_id.title}
                    </Fragment>
                );
            }
        },
        {
            key: "type",
            text: "Type",
            className: "type",
            align: "left",
            sortable: false,
               cell: record => {
                return (
                    <Fragment>
                            {record.training_id.type}
                    </Fragment>
                );
            }
        },
        {
            key: "location",
            text: "Location",
            className: "location",
            align: "left",
            sortable: false,
              cell: record => {
                return (
                    <Fragment>
                            {record.training_id.location}
                    </Fragment>
                );
            }
        },
        {
            key: "amount",
            text: "Amount",
            className: "amount",
            align: "left",
            sortable: false,
              cell: record => {
                return (
                    <Fragment>
                            {record.bill_id.amount}
                    </Fragment>
                );
            }
        },
        {
            key: "status",
            text: "Status",
            className: "status",
            sortable: false,
            cell: record => {
                return (
                    <Fragment>
                        {record.status ==='Paid'?
                            <span className="badge-md badge bg-success">Paid</span>:
                            <span className="badge-md badge bg-danger">Not Paid</span>

                        }

                    </Fragment>
                );
            }
        },
        {
            key: "created_at",
            text: "Application Date",
            className: "created_at",
            sortable: false,
            cell: record => {
                return (
                    <Fragment>
                        { Moment(record.created_at).format('DD-MM-YYYY')}
                    </Fragment>
                );
            }
        },
    ];
    const config = {
        page_size: 5,
        length_menu: [5,10,20,50,100],
        button: {
            excel: true,
            print: true,
            extra: true,
        }
    }


    const extraButtons = [
        {
            className: "btn btn-primary buttons-pdf",
            title: "Export TEst",
            children: [
                <span>
                    <FontAwesomeIcon icon={faPrint}/>
                    {/*<i className="glyphicon glyphicon-print " aria-hidden="true"></i>*/}
                    </span>
            ],
            onClick: (event) => {
                console.log(event);
            },
        },
        {
            className: "btn btn-primary buttons-pdf",
            title: "Export TEst",
            children: [
                <span>
                   <FontAwesomeIcon icon={faPrint}/>
                    </span>
            ],
            onClick: (event) => {
                console.log(event);
            },
            onDoubleClick: (event) => {
                console.log("doubleClick")
            }
        },
    ]

    return (
        <>
            <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="d-block mb-4 mb-xl-0">
                    <div>
                        <h4><FontAwesomeIcon icon={faLeanpub} className="icon icon-xs text-dark w-30"/>
                            &nbsp;&nbsp;  Zanzibar Law Society Training
                        </h4>
                    </div>
                </div>

            </div>
            <ReactDatatable
                config={config}
                records={TrainingData}
                columns={columns}
                extraButtons={extraButtons}
            />
        </>
    )

}

const mapDispatchToProps =
    {
        FetchTrainingsByUser
    };

export default connect(() => ({}), mapDispatchToProps)(MyTraining);

