import React, {useState} from "react";
import {Button, Form, Modal} from "@themesberg/react-bootstrap";
import AsyncAction from "../../../AsyncAction";
import {useFormik} from "formik";
import * as yup from "yup";
import {EditEducation} from "../../../store/eductionProfile";
import {connect, useDispatch} from "react-redux";

const validationSchema = yup.object().shape({
    award: yup.string().required("This field is required"),
    year_of_completion: yup.string().required("This field is required"),
    school_name: yup.string().required("This field is required"),
    education_level: yup.string().required("This field is required"),
});

function EducationEditForm(props) {
    const [show, setShow] = useState(false);
    // const [showEditForm, setShowEditForm] = useState(false);
    const [value, setValues] = useState({});
  const dispatch=useDispatch();
    // const handleClose = () => setShow(false);
    // const handleCloseEdit = () => setShowEditForm(false);
    const handleShow = () => setShow(true);
    const {AddEducationProfile, EditEducation, DeleteEducation, educationData,handleShowEditForm} = props;

    //using useFormik
    const formik = useFormik({
        initialValues: {
            award:educationData.award ,
            year_of_completion: educationData.year_of_completion,
            education_level: educationData.education_level,
            school_name: educationData.school_name,
        },
        validationSchema,
    });
    // console.log("data",educationData)
    function handleOnclickOption(){
        EditEducation(formik.values,educationData.id);
        handleShowEditForm(false);
        formik.resetForm({})
    }
    return (
        <div>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Education level</Form.Label>
                        <select
                            className="form-control mb-0"
                            onChange={formik.handleChange}
                            id="education_level"
                            name="education_level"
                            onBlur={formik.handleBlur}
                            value={formik.values.education_level}
                        >
                            <option value="">Select Level</option>
                            <option value="Degree">Degree</option>
                            <option value="Masters">Masters</option>
                            <option value="Doctorate">Doctorate</option>
                            <option value="PHD">PHD</option>
                            <option value="Post graduate">Post graduate</option>
                        </select>
                        {formik.touched.education_level && formik.errors.education_level ? (
                            <div className="text-danger">{formik.errors.education_level}</div>
                        ) : null}
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>School/College/University</Form.Label>
                        <input
                            type="text"
                            className="form-control mb-0"
                            autoComplete="off"
                            id="school_name"
                            name="school_name"
                            value={formik.values.school_name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.school_name && formik.errors.school_name ? (
                            <div className="text-danger">
                                {formik.errors.school_name}
                            </div>
                        ) : null
                        }
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Year of completion</Form.Label>
                        <input
                            type="text"
                            className="form-control mb-0"
                            autoComplete="off"
                            id="year_of_completion"
                            name="year_of_completion"
                            value={formik.values.year_of_completion}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.year_of_completion && formik.errors.year_of_completion ? (
                            <div className="text-danger">
                                {formik.errors.year_of_completion}
                            </div>
                        ) : null
                        }
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Awards</Form.Label>
                        <input
                            type="text"
                            className="form-control mb-0"
                            autoComplete="off"
                            id="award"
                            name="award"
                            value={formik.values.award}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.award && formik.errors.award ? (
                            <div className="text-danger">
                                {formik.errors.award}
                            </div>
                        ) : null
                        }
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button size="sm" variant="outline-dark" onClick={()=>handleShowEditForm(false)}>
                    Close
                </Button>
                <Button
                    size="sm"
                    disabled={!(formik.isValid && formik.dirty)}
                    onClick={() => handleOnclickOption()}>
                    Save Changes
                </Button>
            </Modal.Footer>
        </div>
    )
}
const mapDispatchToProps = {
    EditEducation
};

export default connect(() => ({}), mapDispatchToProps)(EducationEditForm);

