import React, {useEffect} from "react";
import {AddEducationProfile, DeleteEducation} from "../../../store/eductionProfile";
import {connect, useDispatch, useSelector} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBook, faCalendarAlt, faMoneyBill, faTimes} from "@fortawesome/free-solid-svg-icons";
import CurrencyFormat from 'react-currency-format';
import {ApplyTraining} from "../../../store/MyTraining";
import {FetchTrainings} from "../../../store/Training";
import Moment from "moment";


function ListTraining() {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(FetchTrainings());

    }, [dispatch]);

    const eventData = useSelector((state) => state.training);
    const current = new Date();
    const date = current;
    // "%Y-%m-%dT%H:%M:%S%z")
    // 2019-02-26T09:30:46+03:00
    const formated_date= Moment(date).toISOString()
        // .format('Y-MM-DDThh:mmZ')
    // Moment.utc(date).toISOString()
    // 2016-11-10T06:24:12.958+05:00

    // ('YYYY-MM-DDThh:mmTZD')
    // console.log("date fomart is here",formated_date)
    function Apply(value){
        dispatch(ApplyTraining(value,formated_date));
    }

    return (
        <div>
            <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="d-block b-xl-0">
                    <div>
                        <h4>
                            <FontAwesomeIcon icon={faBook} className="icon icon-xs text-dark w-30"/>
                            Available Trainings
                        </h4>
                    </div>
                </div>
            </div>
            <div>
                <div id="cards_landscape_wrap-2">
                    <div className="container-fluid">
                        <div className="row">
                            {eventData.map((index) => (
                                <div
                                    key={index.id}
                                    className="col-xs-12 col-sm-6 col-md-3 col-lg-3 mb-3 mt-auto"
                                >

                                        <div className="card">
                                            <div className="text-box">
                                                <div className="image-box">
                                                    <img
                                                        className="card-img-top"
                                                        src={index.image_url}
                                                        style={{height: 160}}
                                                        alt=""
                                                    />
                                                </div>
                                                <hr/>
                                                <div className="card-body">
                                                    <h5 className="card-title text-capitalize">
                                                        {index.title.length > 33 ?
                                                            `${index.title.substring(0, 33)}...` : index.title
                                                        }</h5>
                                                    <small>
                                                        <span className='text-sm-start text-primary'> <FontAwesomeIcon
                                                            icon={faCalendarAlt}/>    &nbsp; {index.event_date}
                                                        </span>
                                                        &nbsp;
                                                        <b
                                                            className='text-sm-start text-primary'>
                                                            &nbsp;
                                                            {index.is_free === 'false' ?
                                                                <span
                                                                    className="badge badge-lg bg-gradient-warning text-dark"> Free </span>
                                                                :
                                                                <CurrencyFormat
                                                                    value={index.amount}
                                                                    displayType={'text'}
                                                                    thousandSeparator={true}
                                                                    prefix={'TZS'}
                                                                    renderText={value => <span>{value}</span>}/>
                                                            }
                                                        </b>
                                                    </small>
                                                    <p className="card-text">
                                                        {index.description.length > 68 ?
                                                            `${index.description.substring(0, 68)}...` : index.description
                                                        }
                                                        <span className="text-info">Read More</span>
                                                    </p>
                                                    <span onClick={()=>Apply(index)}>Apply</span>
                                                </div>
                                            </div>
                                        </div>

                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

const mapDispatchToProps = {
    AddEducationProfile, DeleteEducation
};

export default connect(() => ({}), mapDispatchToProps)(ListTraining);
