import {RECEIPT_DATA} from "./receiptActionTypes";

export default function receiptReducer(state = [], action) {
    switch (action.type) {
        case RECEIPT_DATA:
            return action.payload;
        default:
            return state;
    }
}
