import React, {useState} from "react";
import {Button, Card, Col, Form, Row} from "@themesberg/react-bootstrap";
import * as yup from "yup";
import {useFormik} from "formik";
import Axios from "axios";
import {userToken} from "../../../store/Login";
import AsyncAction from "../../../AsyncAction";
import classNames from "classnames";
import {AddWork} from "../../../store/workProfile";
import {connect} from "react-redux";
import EditWorkProfile from "./editWorkProfile";

const validationSchema = yup.object().shape({
    business_location: yup.string().required("This field is required"),
    employer: yup.string().required("This field is required"),
    number_of_advocates: yup.string().required("This field is required"),
    tin: yup.string().required("This field is required"),
    email: yup.string().required("This field is required"),
    address: yup.string().required("This field is required"),
    telephone: yup.string().required("This field is required"),
    category: yup.string().required("This field is required"),
    website: yup.string(),
});


function Work(props) {

    const {AddWork, workData} = props
    const [workInfo, setInfo] = useState(null);
    const [editActive, setActive] = useState(false);

    // console.log("work", workData)

    function EditWorkInformation() {
        // Axios.get(window.$name + `/profile/datf/${id}`,)
        Axios.get(window.$name + `/profile/work/information/${userToken().userId}`)
            .then(res => {
                const persons = res.data[0];
                setInfo(persons)
                if (editActive) {
                    setActive(false)
                } else {
                    setActive(true)
                }
            })
    }

    //using useFormik
    const formik = useFormik({
        initialValues: {
            business_location: "",
            employer: "",
            number_of_advocates: "",
            tin: "",
            telephone: "",
            email: "",
            address: "",
            category: "",
            website: "",
        },
        validationSchema,
    });
    return (
        <>
            <Row>
                <Col className="col-md-6 d-flex justify-content-start">
                    <h5>
                        {
                            workData.length > 0 ?
                                <>
                                    Work Information

                                </>
                                :
                                <>
                                    Add Work Information
                                </>
                        }
                    </h5>
                    &nbsp; &nbsp;
                    {
                        workData.length > 0 ?
                            <Button onClick={() => (EditWorkInformation())} size="sm" variant="outline-primary">
                                <>
                                    {
                                        editActive ?
                                            <>
                                                Back
                                            </>
                                            :
                                            <>
                                                Edit
                                            </>
                                    }
                                </>
                            </Button>
                            :
                            <>

                            </>
                    }
                </Col>
            </Row>
            {
                workInfo !== null && editActive !== false ?
                    <div>
                        <EditWorkProfile workInfo={workInfo} setActive={setActive} />
                    </div>
                    :
                    <div>
                        {
                            workData.length > 0 ?
                                <div>
                                    {
                                        workData.map((index) => (
                                            <div>
                                                <Row className="mt-2">
                                                    <Col className="col-12">
                                                        <Card border="light" className="shadow-none">
                                                            <Card.Body>
                                                                <div
                                                                    className="d-flex align-items-center justify-content-between border-bottom border-light">
                                                                    <div>
                                                                        <h6>Law firm / Employer</h6>
                                                                    </div>
                                                                    <div>
                                                                        <Card.Link href="#"
                                                                                   className="text-primary fw-bold">
                                                                            {index.employer}
                                                                        </Card.Link>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className="d-flex align-items-center justify-content-between border-bottom border-light">
                                                                    <div>
                                                                        <h6>Work category</h6>
                                                                    </div>
                                                                    <div>
                                                                        <Card.Link href="#"
                                                                                   className="text-primary fw-bold">
                                                                            {index.category}
                                                                        </Card.Link>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className="d-flex align-items-center justify-content-between border-bottom border-light">
                                                                    <div>
                                                                        <h6> TIN </h6>
                                                                    </div>
                                                                    <div>
                                                                        <Card.Link href="#"
                                                                                   className="text-primary fw-bold">
                                                                            {index.tin}
                                                                        </Card.Link>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className="d-flex align-items-center justify-content-between border-bottom border-light">
                                                                    <div>
                                                                        <h6> Business location</h6>
                                                                    </div>
                                                                    <div>
                                                                        <Card.Link href="#"
                                                                                   className="text-primary fw-bold">
                                                                            {index.business_location}
                                                                        </Card.Link>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className="d-flex align-items-center justify-content-between border-bottom border-light">
                                                                    <div>
                                                                        <h6>Number of Advocates</h6>
                                                                    </div>
                                                                    <div>
                                                                        <Card.Link href="#"
                                                                                   className="text-primary fw-bold">
                                                                            {index.number_of_advocates}
                                                                        </Card.Link>
                                                                    </div>
                                                                </div>
                                                            </Card.Body>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                                <Row className="mt-2">
                                                    <Col className="col">
                                                        <Card border="light" className="shadow-none">
                                                            <Card.Body>
                                                                <div
                                                                    className="d-flex align-items-center justify-content-between border-bottom border-light">
                                                                    <div>
                                                                        <h6>Postal address</h6>
                                                                    </div>
                                                                    <div>
                                                                        <Card.Link href="#"
                                                                                   className="text-primary fw-bold">
                                                                            {index.address}
                                                                        </Card.Link>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className="d-flex align-items-center justify-content-between border-bottom border-light">
                                                                    <div>
                                                                        <h6>Telephone</h6>
                                                                    </div>
                                                                    <div>
                                                                        <Card.Link href="#"
                                                                                   className="text-primary fw-bold">
                                                                            {index.telephone}
                                                                        </Card.Link>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className="d-flex align-items-center justify-content-between border-bottom border-light">
                                                                    <div>
                                                                        <h6> Email </h6>
                                                                    </div>
                                                                    <div>
                                                                        <Card.Link href="#"
                                                                                   className="text-primary fw-bold">
                                                                            {index.email}
                                                                        </Card.Link>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className="d-flex align-items-center justify-content-between border-bottom border-light">
                                                                    <div>
                                                                        <h6> Website</h6>
                                                                    </div>
                                                                    <div>
                                                                        <Card.Link href="#"
                                                                                   className="text-primary fw-bold">
                                                                            {index.website}
                                                                        </Card.Link>
                                                                    </div>
                                                                </div>
                                                            </Card.Body>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            </div>
                                        ))
                                    }
                                </div>
                                :
                                <div>
                                    <Card border="light" className="bg-white shadow-sm mb-4">
                                        <Card.Body>
                                            <Row>
                                                <Col md={6} className="mb-3">
                                                    <div className="form-group">
                                                        <Form.Label>Business Location</Form.Label>
                                                        <input
                                                            type="text"
                                                            className="form-control mb-0"
                                                            autoComplete="off"
                                                            id="business_location"
                                                            name="business_location"
                                                            placeholder="eg. 4th Flr Jangid Plaza, Chabruma Street,Dar es salaam"
                                                            value={formik.values.business_location}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                        />
                                                        {formik.touched.location && formik.errors.business_location ? (
                                                            <div className="text-danger">
                                                                {formik.errors.business_location}
                                                            </div>
                                                        ) : null
                                                        }
                                                    </div>
                                                </Col>
                                                <Col md={6} className="mb-3">
                                                    <div className="form-group">
                                                        <Form.Label>Law Firm/Employer</Form.Label>
                                                        <input
                                                            type="text"
                                                            className="form-control mb-0"
                                                            autoComplete="off"
                                                            id="employer"
                                                            name="employer"
                                                            placeholder="eg. ABC Attorneys"
                                                            value={formik.values.employer}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                        />
                                                        {formik.touched.employer && formik.errors.employer ? (
                                                            <div className="text-danger">
                                                                {formik.errors.employer}
                                                            </div>
                                                        ) : null
                                                        }
                                                    </div>
                                                </Col>
                                                <Col md={6} className="mb-3">
                                                    <div className="form-group">
                                                        <Form.Label>Number of Advocates at work Place</Form.Label>
                                                        <input
                                                            type="number"
                                                            className="form-control mb-0"
                                                            autoComplete="off"
                                                            id="number_of_advocates"
                                                            name="number_of_advocates"
                                                            placeholder="eg. 4"
                                                            value={formik.values.number_of_advocates}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                        />
                                                        {formik.touched.number_of_advocates && formik.errors.number_of_advocates ? (
                                                            <div className="text-danger">
                                                                {formik.errors.number_of_advocates}
                                                            </div>
                                                        ) : null
                                                        }
                                                    </div>
                                                </Col>
                                                <Col md={6} className="mb-3">
                                                    <div className="form-group">
                                                        <Form.Label>TIN</Form.Label>
                                                        <input
                                                            type="number"
                                                            className="form-control mb-0"
                                                            autoComplete="off"
                                                            id="tin"
                                                            name="tin"
                                                            placeholder="eg. 4565466"
                                                            value={formik.values.tin}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                        />
                                                        {formik.touched.tin && formik.errors.tin ? (
                                                            <div className="text-danger">
                                                                {formik.errors.tin}
                                                            </div>
                                                        ) : null
                                                        }
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>

                                                <Col md={6} className="mb-3">
                                                    <div className="form-group">
                                                        <Form.Label>Phone</Form.Label>
                                                        <input
                                                            type="number"
                                                            className="form-control mb-0"
                                                            autoComplete="off"
                                                            id="telephone"
                                                            name="telephone"
                                                            placeholder="eg.255 767 456 546"
                                                            value={formik.values.telephone}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                        />
                                                        {formik.touched.telephone && formik.errors.telephone ? (
                                                            <div className="text-danger">
                                                                {formik.errors.telephone}
                                                            </div>
                                                        ) : null
                                                        }
                                                    </div>
                                                </Col>
                                                <Col md={6} className="mb-3">
                                                    <div className="form-group">
                                                        <Form.Label>Email</Form.Label>
                                                        <input
                                                            type="email"
                                                            className="form-control mb-0"
                                                            autoComplete="off"
                                                            id="email"
                                                            name="email"
                                                            placeholder="eg. name@company.go.tz"
                                                            value={formik.values.email}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                        />
                                                        {formik.touched.email && formik.errors.email ? (
                                                            <div className="text-danger">
                                                                {formik.errors.email}
                                                            </div>
                                                        ) : null
                                                        }
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={6} className="mb-3">
                                                    <div className="form-group">
                                                        <Form.Label>Postal address</Form.Label>
                                                        <input
                                                            type="text"
                                                            className="form-control mb-0"
                                                            autoComplete="off"
                                                            id="address"
                                                            name="address"
                                                            placeholder="eg. Box 1052, dar es salaam"
                                                            value={formik.values.address}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                        />
                                                        {formik.touched.address && formik.errors.address ? (
                                                            <div className="text-danger">
                                                                {formik.errors.address}
                                                            </div>
                                                        ) : null
                                                        }
                                                    </div>
                                                </Col>
                                                <Col md={6} className="mb-3">
                                                    <div className="form-group">
                                                        <Form.Label>Work Category</Form.Label>
                                                        <Form.Select
                                                            className="form-control mb-0"
                                                            onChange={formik.handleChange}
                                                            id="category"
                                                            name="category"
                                                            onBlur={formik.handleBlur}
                                                            value={formik.values.category}
                                                        >
                                                            <option value="">Select work category</option>
                                                            <option value="Firm">Firm</option>
                                                            <option value="Association">Association</option>
                                                            <option value="Judiciary">Judiciary</option>
                                                            <option value="Member of Parliament">Member of Parliament
                                                            </option>
                                                            <option value="Financial Institution">Financial
                                                                Institution
                                                            </option>
                                                            <option value="Civil society organization">Civil society
                                                                organization
                                                            </option>
                                                            <option value="Company">Company</option>
                                                            <option value="Police Force or Prison Department">Police
                                                                Force or
                                                                Prison
                                                                Department
                                                            </option>
                                                            <option value="Office of the attorney General">Office of the
                                                                attorney
                                                                General
                                                            </option>
                                                            <option value="Political Parties">Political Parties</option>
                                                            <option value="Judge/Magistrate">Judge/Magistrate</option>
                                                            <option value="Military">Military</option>
                                                            <option value="Other">Other</option>
                                                        </Form.Select>
                                                        {formik.touched.category && formik.errors.category ? (
                                                            <div className="text-danger">{formik.errors.category}</div>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="col-12">
                                                    <div className="form-group">
                                                        <Form.Label>Website (Optional)</Form.Label>
                                                        <input
                                                            type="text"
                                                            className="form-control mb-0"
                                                            autoComplete="off"
                                                            id="website"
                                                            name="website"
                                                            placeholder="eg. company.com"
                                                            value={formik.values.website}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                        />
                                                        {formik.touched.website && formik.errors.website ? (
                                                            <div className="text-danger">
                                                                {formik.errors.website}
                                                            </div>
                                                        ) : null
                                                        }
                                                    </div>
                                                </Col>
                                            </Row>
                                            <div className="mt-2" style={{float: 'right'}}>
                                                {/*<Button disabled={!(formik.isValid && formik.dirty)} variant="outline-warning"*/}
                                                {/*        type="submit">Submit</Button>*/}
                                                <AsyncAction
                                                    action={() => AddWork(formik.values)}
                                                    render={({run, loading}) => (
                                                        <Button
                                                            type="submit"
                                                            size="sm"
                                                            data-toggle="tooltip"
                                                            data-placement="right"
                                                            title="sign-in"
                                                            variant="dark"
                                                            onClick={run}
                                                            disabled={!(formik.isValid && formik.dirty)}
                                                            className={classNames("w-100", {
                                                                "btn-loading": loading,
                                                            })}
                                                        >
                                                            Save changes
                                                        </Button>
                                                    )}
                                                />
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </div>
                        }
                    </div>
            }
        </>
    );
}

const mapDispatchToProps =
{
    AddWork
}
;

export default connect(() => (
{
}
), mapDispatchToProps)(Work);
