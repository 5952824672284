import Axios from "axios";
import {toast} from "react-toastify";
import {userToken} from "../Login";
import {FetchSpeakerById} from "../Speaker";
import {CLAIM_POINTS_DATA} from "./claimPointsActionTypes";

export function ClaimPoints(data) {
    const values ={
        status: '2',
        approved_by: 0,
        user_id: userToken().userId,
        source_id: data.event_id.id,
        speaker_points_id: data.id,
        points: data.cle_points

    }
    // console.log(value);
    return async (dispatch) =>
        Axios.post(window.$name + `/events/claim/request/${data.id}/${userToken().userId}`, values)
            .then(function (response) {
                if (response.data) {
                    toast.success("claim sent successfully");
                    dispatch(FetchSpeakerById())
                }
            })
            .catch(function (error) {
                if (error.response.data.errors) {
                    if (error.response.data.errors.user_id) {
                        toast.error("user id - " + " " + error.response.data.errors.user_id[0]);
                    } else if (error.response.data.errors.status) {
                        toast.error("status - " + " " + error.response.data.errors.status[0]);
                    } else {
                        toast.error(error.response);
                    }
                } else {
                    toast.error(error.response.data.detail);
                }
            });
}
export function FetchClaims() {
    return async (dispatch) =>
        Axios.get(window.$name + '/events/claim', {})
            .then(function (response) {
                // console.log("DATA", response.data)
                dispatch({type: CLAIM_POINTS_DATA, payload: response.data});
                // console.log(response.data);
            })
            .catch(function (error) {
                if (error.response) {
                    toast.error(
                        `"${error.response.data}" Error Status  "${error.response.status}" `
                    );
                } else if (error.request) {
                    toast.error(`"${error.request}" `);
                } else {
                    toast.error(`"${error.message}" `);
                }
            });
}
export function ApproveClaimPoints(data) {
    const values ={
        user_id: Number(userToken().userId),
        cle_points: data.points,
        sources: data.source_id,
    }
    // console.log(value);
    return async (dispatch) =>
        Axios.post(window.$name + `/events/claim/approve/save/cle/points/${userToken().userId}/${data.id}`, values)
            .then(function (response) {
                if (response.data) {
                    toast.success("claim approved successfully");
                    dispatch(FetchClaims())
                }
            })
            .catch(function (error) {
                if (error.response.data.errors) {
                    if (error.response.data.errors.user_id) {
                        toast.error("user id - " + " " + error.response.data.errors.user_id[0]);
                    }
                    else if (error.response.data.errors.cle_points) {
                        toast.error("cle_points - " + " " + error.response.data.errors.cle_points[0]);
                    } else if (error.response.data.errors.sources) {
                        toast.error("sources - " + " " + error.response.data.errors.sources[0]);
                    }
                    else {
                        toast.error(error.response);
                    }
                } else {
                    toast.error(error.response.data.detail);
                }
            });
}


