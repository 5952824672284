import Axios from "axios";
import {USER_DATA} from "./accountProfileActionTypes";
import {toast} from "react-toastify";
import {userToken} from "../Login";
// import {EDIT_PROFILE_DATA} from "./editProfile/EditProfileActionTypes";


export function FetchUser() {
    return async (dispatch) =>
        Axios.get(window.$name + `/auth/user/${userToken().userId}`, {})
            .then(function (response) {
                dispatch({type: USER_DATA, payload: response.data});
            })
            .catch(function (error) {
                if (error.response) {
                    toast.error(
                        `"${error.response.data}" Error Status  "${error.response.status}" `
                    );
                } else if (error.request) {
                    toast.error(`"${error.request}" `);
                } else {
                    toast.error(`"${error.message}" `);
                }
            });
}


// export function EditProfile(value, nextEpisode, autoPreview, history) {
//     const data = {
//         profile_name: value.profile_name,
//         maturity_rating: value.maturity_rating,
//         language: value.language,
//         autoplay_nextepisode: nextEpisode,
//         autoplay_preview: autoPreview
//     };
//     return async (dispatch) =>
//         Axios.put(`/api/v1/service/profile/${value.id}`, data, {
//             headers: {
//                 Authorization: `Bearer ${userToken()}`,
//             },
//         })
//             .then(function (response) {
//                 if (response.status === 200) {
//                     toast.success("profile Editing ,successfully");
//                     dispatch(FetchUserProfiles())
//                     dispatch({type: EDIT_PROFILE_DATA, payload: data})
//                     history.push("/who-is-watching");
//                 }
//             })
//             .catch(function (error) {
//                 if (error.response) {
//                     toast.error("Something is wrong,Please contact us");
//                 }
//             });
// }
