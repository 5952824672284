import React, {useState} from "react";
import {Button, Card, Container, Modal} from '@themesberg/react-bootstrap';

import {Link, useHistory} from 'react-router-dom';
import AsyncAction from "../../../AsyncAction";
import {Logout} from "../../../store/Login";
import {connect} from "react-redux";
import {Routes} from "../../../routes";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBan, faCheckCircle, faCircle, faCircleNotch, faSubscript} from "@fortawesome/free-solid-svg-icons";

function PlanChangeRedirect(props) {
    const {Logout} = props;
    const history = useHistory();
    const [showModel, setShowModel] = useState(true);
    const handleClose = () => setShowModel(false);

    return (
       <div>
           {/* <!-- Modal confirm logout --> */}
           <Modal
               show={showModel}

               onHide={handleClose}
           >
               <Modal.Body>
                   <div>
                       <Container>
                           <div className="card-body">
                               {/*<Row>*/}
                               <div className="text-center d-flex align-items-center justify-content-center">
                                   <div>
                                       {/*fa-light fa-circle-check*/}
                                       <Card.Link as={Link} to={Routes.DashboardOverview.path}>
                                           <FontAwesomeIcon icon={faCheckCircle} style={{width: '40%', height: '40%'}}
                                                            className="text-success"/>
                                       </Card.Link>
                                       <h2 className="text-dark mt-5">
                                           Thank <span className="fw-bolder"> You</span>
                                       </h2>
                                       <p className="lead my-4">
                                           Your payment was successful
                                       </p>
                                       <div className="row">
                                           <div className="col-6">
                                               <Link to={Routes.MemberPlan.path} style={{width: '100%'}}
                                                       onClick={handleClose}
                                                       className="btn btn-warning">
                                                  Close
                                               </Link>
                                           </div>
                                           <div className="col-6">
                                               <AsyncAction
                                                   action={() => Logout(history)}
                                                   render={({run}) => (
                                                       <Button
                                                           style={{width: '100%'}}
                                                           variant="dark"
                                                           type="submit"
                                                           onClick={run}
                                                       >
                                                           Continue
                                                       </Button>
                                                   )}
                                               />
                                           </div>
                                       </div>
                                   </div>
                               </div>

                               {/*</Row>*/}
                           </div>
                       </Container>
                   </div>
               </Modal.Body>

           </Modal>
       </div>
    );
}
const mapDispatchToProps = {
    Logout
};
export default connect(() => ({}), mapDispatchToProps)(PlanChangeRedirect);


