import {CLE_POINTS_DATA, EDIT_CLE_POINTS_DATA} from "./clePointsActionTypes";

export default function clePointsReducer(state = [], action) {
    switch (action.type) {
        case CLE_POINTS_DATA:
            return action.payload;
        case EDIT_CLE_POINTS_DATA:
            return action.payload;
        default:
            return state;
    }
}
