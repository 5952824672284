import React, {useEffect, useState} from "react";
import {Button, Form, InputGroup, Modal, Row} from "@themesberg/react-bootstrap";
import {connect, useDispatch, useSelector} from "react-redux";
import * as yup from "yup";
import {useFormik} from "formik";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAddressBook, faEnvelope} from "@fortawesome/free-solid-svg-icons";
import {AddPlan, EditPlan, FetchPlans} from "../../../../store/plan/planActions";
import Axios from "axios";
import {userToken} from "../../../../store/Login";
import {toast} from "react-toastify";
import {FetchUser} from "../../../../store/AccountProfiles";
import {FetchWebsite} from "../../../../store/website";
import {FetchEmail} from "../../../../store/Email";

const validationSchema = yup.object().shape({
    subject: yup.string().required("This field is required"),
    send_to: yup.string().required("This field is required"),
    message: yup.string().required("This field is required"),
});

function CustomEmailForm(props) {
    const {handleShow, data} = props;
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(FetchPlans());
    }, [dispatch]);
    //using useFormik
    const formik = useFormik({
        initialValues: {
            send_to: data.send_to,
            subject: data.subject,
            message: data.message
        },
        validationSchema,
    });
    const [valueLogo, setData] = useState({
        link: "",
    });
    const handleChangeLink = (e) => {
        let newData = {...valueLogo};
        newData["link"] = e.target.files[0];
        setData(newData);
    };

    function addEmail() {
        // console.log(formik.values)
        let form_data = new FormData();
        if (valueLogo.link) {
            form_data.append("link", valueLogo.link, valueLogo.link.name);
        }
        else{
            form_data.append("link", "");
        }

        form_data.append("send_to", formik.values.send_to);
        form_data.append("subject", formik.values.subject);
        form_data.append("message", formik.values.message);
        form_data.append("sent_by", userToken().userId);

        const Request = Axios.post(window.$name + '/settings/custom/email', form_data, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        }).then((res) => {
            console.log(res);
            if (res.status === 201) {

                toast.success("Email sent successfully");
                // handleShowEditForm(false);
                dispatch(FetchEmail())
            }
            return res;
        }).catch((error) => {
            return error.response;
        });
        return Request;
    }


    const sendTo = [
        {
            id: 1,
            name: "All",
            label: "All",
        },
        {
            id: 2,
            name: "updated",
            label: "Updated"
        },
        {
            id: 3,
            name: "not_updated",
            label: "Not Updated"
        },
        {
            id: 4,
            name: "active",
            label: "Active"
        },
        {
            id: 5,
            name: "not_active",
            label: "Not Active"
        }
    ]

    function handleOnclickOption() {
        addEmail()
        handleShow(false);
        formik.resetForm({})
    }

    return (
        <div>
            <Modal.Header>
                <h4>
                    <FontAwesomeIcon icon={faAddressBook} className="icon"/>&nbsp;
                    Custom email
                </h4>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Send To</Form.Label>
                        <Form.Select
                            className="form-control mb-0"
                            onChange={formik.handleChange}
                            id="send_to"
                            name="send_to"
                            onBlur={formik.handleBlur}
                            value={formik.values.send_to}
                        >

                            <option value="">Select receiver</option>
                            {sendTo.map((option) => (
                                <option value={option.name}>{option.label}</option>
                            ))}

                        </Form.Select>
                        {formik.touched.send_to && formik.errors.send_to ? (
                            <div className="text-danger">{formik.errors.send_to}</div>
                        ) : null}
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>subject</Form.Label>
                        <input
                            type="text"
                            className="form-control mb-0"
                            autoComplete="off"
                            id="subject"
                            name="subject"
                            value={formik.values.subject}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.subject && formik.errors.subject ? (
                            <div className="text-danger">
                                {formik.errors.subject}
                            </div>
                        ) : null
                        }
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Message</Form.Label>
                        <textarea
                            className="form-control mb-0"
                            autoComplete="off"
                            id="message"
                            name="message"
                            value={formik.values.message}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.message && formik.errors.message ? (
                            <div className="text-danger">
                                {formik.errors.message}
                            </div>
                        ) : null
                        }
                    </Form.Group>
                    <Form.Group controlId="formFile" className="mb-3">
                        <Form.Label>Attachment (optional)</Form.Label>
                        <Form.Control
                            type="file"
                            name="link"
                            accept="image/jpeg,image/png,image/gif"
                            onChange={(e) => {
                                handleChangeLink(e);
                            }}
                        />

                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button size="sm" variant="outline-dark" onClick={() => handleShow(false)}>
                    Close
                </Button>
                <Button
                    size="sm"
                    disabled={!(formik.isValid && formik.dirty)}
                    onClick={() => handleOnclickOption()}>
                    Send Email
                </Button>
            </Modal.Footer>
        </div>


    )
}

const mapDispatchToProps = {
    AddPlan, EditPlan
};

export default connect(() => ({}), mapDispatchToProps)(CustomEmailForm);

