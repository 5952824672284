import React, {useEffect, useState} from "react"
import {Button, Card, Modal, Table} from "@themesberg/react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faGlobe, faReceipt, faTrash} from "@fortawesome/free-solid-svg-icons";
import {connect, useDispatch, useSelector} from "react-redux";
import ReceiptForm from "./receitpForm";
import AsyncAction from "../../../../AsyncAction";
import {FetchReceipts,DeleteReceipt} from "../../../../store/Receipt";

function ReceiptConfig(props) {
    const {DeleteReceipt}=props
    const dispatch=useDispatch()
    const [Show, setShow] = useState(false);
    const [Value, setId] = useState(0);
    const handleClose = () => setShow(false);
    const handleShow = (value) => {
        if (Show) {
            setShow(false);
        } else {
            setShow(true);
            setId(value)

        }
    };
    useEffect(() => {
        dispatch(FetchReceipts());

    }, [dispatch]);

    const receiptData = useSelector((state) => state.receipt);
    const PageTrafficTable = () => {

        return (
            <Card border="light" className="shadow-sm ">
                <Card.Body className="pb-0">
                    <Table responsive className="table-centered text-center  table-nowrap rounded mb-0">
                        <thead className="thead-light">
                        <tr>
                            <th className="border-0">#</th>
                            <th className="border-0">Company Name </th>
                            <th className="border-0">Phone</th>
                            <th className="border-0">Email</th>
                            <th className="border-0">Address</th>
                            <th className="border-0">Logo</th>
                            <th className="border-0">Signature</th>
                            <th className="border-0 text-center">Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {[receiptData] .map((index,key)=>(
                            <tr className="text-center">
                                <td>
                                    <Card.Link href="#" className="text-primary fw-bold">{key + 1}</Card.Link>
                                </td>
                                <td>{index.name}</td>
                                <td>{index.Phone}</td>
                                <td>{index.email}</td>
                                <td>{index.address}</td>
                                <td>
                                    <Card.Img src={index.logo} alt="Neil Portrait"
                                                   className="custom-avatar"/>
                                </td>
                                <td>
                                    <Card.Img src={index.signature} alt="Neil Portrait"
                                                   className="custom-avatar"/>
                                </td>
                                <td className="text-center">
                                    <Button size="sm" onClick={() => handleShow(index)} variant="outline-primary">
                                        <FontAwesomeIcon icon={faEdit}  className="icon icon-xs text-dark w-30"/>Edit</Button>
                                    &nbsp;&nbsp;
                                    <AsyncAction
                                        action={() => DeleteReceipt(index.id)}
                                        render={({run, loading}) => (
                                            <Button
                                                size="sm"
                                                variant="outline-danger"
                                                type="submit"
                                                data-toggle="tooltip"
                                                data-placement="right"
                                                title="sign-in"
                                                onClick={run}
                                                // disabled={!(formik.isValid && formik.dirty)}
                                            >  <FontAwesomeIcon icon={faTrash} className="icon icon-xs text-dark w-30"/>

                                                Delete
                                            </Button>
                                        )}
                                    />
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        );
    };

    return (
        <>
            <div className="">
                <div className="d-block">
                    <div>
                        <h4> <FontAwesomeIcon icon={faReceipt} className="icon icon-xs text-dark w-30"/> Receipt information</h4>
                        <div className="container-fluid">
                            <Button size="sm" variant="outline-dark"  onClick={() => handleShow(0)}>
                                Add Receipt information
                            </Button>
                        </div>
                    </div>
                </div>
                <PageTrafficTable/>
            </div>

            {/*model for adding education */}
            <Modal show={Show} onHide={handleClose}>
                <ReceiptForm id={Value.id} data={Value} handleShow={handleShow}/>
            </Modal>
            {/*end here modal for adding education*/}
        </>
    )

}

const mapDispatchToProps = {
    FetchReceipts,DeleteReceipt
};

export default connect(() => ({}), mapDispatchToProps)(ReceiptConfig);

