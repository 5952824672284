import Axios from "axios";
import {APPLY_TRAINING_DATA} from "./MytrainingActionTypes";
import {toast} from "react-toastify";
import {userToken} from "../Login";

export function FetchTrainingsByUser() {
    return async (dispatch) =>
        Axios.get(window.$name +  `/events/training/by/user/${userToken().userId}`, {})
            .then(function (response) {
                // console.log("DATA", response.data)
                dispatch({type: APPLY_TRAINING_DATA, payload: response.data});
                // console.log(response.data);
            })
            .catch(function (error) {
                if (error.response) {
                    toast.error(
                        `"${error.response.data}" Error Status  "${error.response.status}" `
                    );
                } else if (error.request) {
                    toast.error(`"${error.request}" `);
                } else {
                    toast.error(`"${error.message}" `);
                }
            });
}

export function ApplyTraining(data,date) {
    const billData={
        item_id: data.id,
        type: "Training",
        amount: data.amount,
        status: "Not paid",
        created_by: userToken().userId,
        timestamp:date
    }
    // /events/training/by/user/54
    return async (dispatch) =>
        Axios.post(window.$name + `/payment/create/bill/${userToken().userId}`, billData, {
            headers: {}
        }, {})
            .then(function (response) {
                if (response.status === 201) {
                    // dispatch(FetchTrainings())
                    const TrainingData={
                        status: "Not paid",
                        user_id: userToken().userId,
                        training_id: data.id,
                        bill_id: response.data.id
                    }
                    Axios.post(window.$name + `/events/training/by/user/${userToken().userId}`, TrainingData, {
                        headers: {}
                    }, {})
                        .then(function (response) {
                            if (response.status === 201) {
                                dispatch(FetchTrainingsByUser())
                                toast.success("Training application ,successfully your will receive a control number for payment");
                                // dispatch({type: APPLY_TRAINING_DATA, payload: response.data})
                            }
                        })
                        .catch(function (error) {
                            if (error.response) {
                                toast.error("Something is wrong,Please contact us");
                            }
                        });
                }
            })
            .catch(function (error) {
                if (error.response) {
                    toast.error("Something is wrong,Please contact us");
                }
            });
}