import {EDIT_EDUCATION_PROFILES_DATA, EDUCATION_PROFILES_DATA} from "./educationProfileActionTypes";
export default function educationProfileReducer(state = [], action) {
  switch (action.type) {
    case EDUCATION_PROFILES_DATA:
      return action.payload;
    case EDIT_EDUCATION_PROFILES_DATA:
      return action.payload;
    default:
      return state;
  }
}
