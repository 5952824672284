import React, {useEffect, useState} from "react"
import {
    Breadcrumb,
    Button,
    ButtonGroup,
    Card,
    Col,
    Form,
    InputGroup,
    Modal,
    Row,
    Table
} from "@themesberg/react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faAddressBook,
    faEdit,
    faEye,
    faHome,
    faLock,
    faSearch,
    faTrash,
    faUser
} from "@fortawesome/free-solid-svg-icons";
import {pageTraffic} from "../../../data/tables";
import AddRolesForm from "./rolesAddForm";
import {useDispatch, useSelector} from "react-redux";
import {FetchRoless} from "../../../store/roles";

function Roles() {
    const dispatch = useDispatch();
    const [Show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => {
        if (Show) {

            setShow(false);
        } else {
            setShow(true);
        }
    };
    useEffect(() => {
        dispatch(FetchRoless());

    }, [dispatch]);

    const rolesData = useSelector((state) => state.roles);
    const [searchQuery, setSearchQuery] = useState("")
    function search() {
        return rolesData.filter(row => row.display_name.toLowerCase().indexOf(searchQuery) > - 1)

    }
    return (
        <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <Breadcrumb className="d-none d-md-inline-block"
                            listProps={{className: "breadcrumb-dark breadcrumb-transparent"}}>
                    <Breadcrumb.Item><FontAwesomeIcon icon={faHome}/></Breadcrumb.Item>
                    <Breadcrumb.Item>Settings</Breadcrumb.Item>
                    <Breadcrumb.Item active>Roles & permission</Breadcrumb.Item>
                </Breadcrumb>
                <div className="btn-toolbar mb-2 mb-md-0">
                    <ButtonGroup>
                        <Button size="sm" variant="dark" onClick={() => handleShow()}>
                            Add Roles
                        </Button>
                        <Button variant="outline-primary" size="sm">Export</Button>
                    </ButtonGroup>
                </div>
            </div>

            <div className="table-settings mb-4">
                <Row className="justify-content-between align-items-center">
                    <Col xs={6} md={6} xl={4} className="ps-md-0">
                        <h4><FontAwesomeIcon icon={faLock} className="icon icon-xs text-dark w-30"/> User Roles and
                            Permission</h4>
                    </Col>
                    <Col xs={6} md={6} lg={3} xl={4}>
                        <InputGroup>
                            <InputGroup.Text>
                                <FontAwesomeIcon icon={faSearch}/>
                            </InputGroup.Text>
                            <Form.Control
                                type="text"
                                placeholder="Search"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />
                        </InputGroup>
                    </Col>
                </Row>
            </div>
            <Card border="light" className="shadow-sm mb-4">
                <Card.Body className="pb-0">
                    <Table responsive className="table-centered text-start  table-nowrap rounded mb-0">
                        <thead className="thead-light">
                        <tr>
                            <th className="border-0">#</th>
                            <th className="border-0">Name</th>
                            <th className="border-0">Displayed Name</th>
                            <th className="border-0">Permission</th>
                            <th className="border-0 text-center">Actions</th>
                        </tr>
                        </thead>
                        {
                            search(rolesData).length <= 0 ?
                                <tbody>

                                <tr className="text-center">
                                    <h3>No data available.....</h3>
                                </tr>
                                </tbody>
                                :
                                <tbody>
                                {search(rolesData).map((index) => (
                                        <tr className="text-start">
                                            <td>
                                                <Card.Link href="#" className="text-primary fw-bold">{index.id}</Card.Link>
                                            </td>
                                            <td className="fw-bold">
                                                {index.name}
                                            </td>
                                            <td>
                                                {index.display_name}
                                            </td>
                                            <td>
                                                <ul>
                                                    {JSON.parse(index.permission).map((p) => (
                                                        <li
                                                            key={p}
                                                        >
                                                            {p}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </td>
                                            <td className="text-center">
                                                <Button size="sm" variant="outline-warning">
                                                    <FontAwesomeIcon icon={faEye}
                                                                     className="icon icon-xs text-dark w-30"/> View</Button>
                                                &nbsp;&nbsp;
                                                <Button size="sm" variant="outline-primary">
                                                    <FontAwesomeIcon icon={faEdit}
                                                                     className="icon icon-xs text-dark w-30"/>Edit</Button>
                                                &nbsp;&nbsp;
                                                <Button size="sm" variant="outline-danger">
                                                    <FontAwesomeIcon icon={faTrash}
                                                                     className="icon icon-xs text-dark w-30"/>Delete
                                                </Button>
                                            </td>
                                        </tr>
                                    )
                                )}
                                </tbody>
                        }


                    </Table>
                </Card.Body>
            </Card>

            {/*model for adding education */}
            <Modal size={"xl"} show={Show} onHide={handleClose}>
                <AddRolesForm handleShow={handleShow}/>
            </Modal>
            {/*end here modal for adding education*/}
        </>
    )

}

export default Roles