import {EDIT_EVENT_DATA, EVENT_DATA, EVENT_SPEAKER_DATA} from "./eventActionTypes";
export default function eventReducer(state = [], action) {
  switch (action.type) {
    case EVENT_DATA:
      return action.payload;
    case EDIT_EVENT_DATA:
      return action.payload;
    case EVENT_SPEAKER_DATA:
      return action.payload;
    default:
      return state;
  }
}
