import React, {useEffect, useState} from "react";
import SimpleBar from 'simplebar-react';
import {Link, useHistory, useLocation} from "react-router-dom";
import {CSSTransition} from 'react-transition-group';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faBusinessTime,
    faCalendarAlt,
    faChartPie,
    faCog,
    faFileAlt,
    faGift,
    faMicrophone,
    faMicrophoneAlt,
    faSignOutAlt,
    faTimes,
    faUser,
    faUsers,
    faUserShield
} from "@fortawesome/free-solid-svg-icons";
import {Accordion, Badge, Button, Card, Dropdown, Image, Modal, Nav, Navbar, Row} from '@themesberg/react-bootstrap';

import {Routes} from "../routes";
import logo from "../assets/img/logo/logo.png";
import {connect, useDispatch, useSelector} from "react-redux";
import {FetchUser} from "../store/AccountProfiles";
import {FetchPersonalProfiles} from "../store/personalProfile";
import {faUserCircle} from "@fortawesome/free-regular-svg-icons";
import {Logout} from "../store/Login";
import AsyncAction from "../AsyncAction";
import {FetchWebsite} from "../store/website";
import CashPaymentRequest from "../pages/dashboard/Billing/CashPaymentRequest";

//    "@themesberg/react-bootstrap": "^1.4.1",
function SideBar(props) {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(FetchWebsite());
        dispatch(FetchUser());
        dispatch(FetchPersonalProfiles());

    }, [dispatch]);
    const {Logout} = props;
    const location = useLocation();
    const {pathname} = location;
    const history = useHistory();
    const [show, setShow] = useState(false);
    const showClass = show ? "show" : "";
    const personalData = useSelector((state) => state.personalProfile);
    const userProfile = useSelector((state) => state.userData);
    const rolesData = useSelector((state) => state.permission[0]);

    const WebsiteData = useSelector((state) => state.website);
    const isAllowed = JSON.parse(rolesData.permission);
    // const isAllowed = ['Read profile', 'Browse user', 'Add plan','Add event','Browse plan', 'Browse event','Browse bill','Browse profile'];

    const onCollapse = () => setShow(!show);

    const [showModel, setShowModel] = useState(false);
    const handleClose = () => setShowModel(false);

    function LogoutUser() {
        setShowModel(true)
    }

    const CollapsableNavItem = (props) => {
        const {eventKey, title, icon, children = null} = props;
        // console.log("icon neeeeeded",icon)
        const defaultKey = pathname.indexOf(eventKey) !== -1 ? eventKey : "";

        return (

            <Accordion as={Nav.Item} defaultActiveKey={defaultKey}>
                <Accordion.Item eventKey={eventKey}>
                    <Accordion.Button as={Nav.Link} className="d-flex justify-content-between align-items-center">
                       <span>
                         <span className="sidebar-icon"><FontAwesomeIcon icon={icon}/> </span>
                         <span className="sidebar-text">{title} </span>
                       </span>
                    </Accordion.Button>
                    <Accordion.Body className="multi-level">
                        <Nav className="flex-column">
                            {children}
                        </Nav>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>

        );
    };

    const NavItem = (props) => {
        const {
            title,
            link,
            external,
            target,
            icon,
            image,
            badgeText,
            isCheck,
            badgeBg = "secondary",
            badgeColor = "primary"
        } = props;
        const classNames = badgeText ? "d-flex justify-content-start align-items-center justify-content-between" : "";
        const navItemClassName = link === pathname ? "active" : "";
        const linkProps = external ? {href: link} : {as: Link, to: link};
        // console.log(isCheck)
        return (
            <>
                {
                    isCheck ?

                        <Nav.Item className={navItemClassName} onClick={() => setShow(false)}>

                            <Nav.Link {...linkProps} target={target} className={classNames}>
          <span>
            {icon ? <span className="sidebar-icon"><FontAwesomeIcon icon={icon}/> </span> : null}
              {image ? <Image src={image} width={20} height={20} className="sidebar-icon svg-icon"/> : null}

              <span className="sidebar-text">{title}</span>


          </span>
                                {badgeText ? (
                                    <Badge pill bg={badgeBg} text={badgeColor}
                                           className="badge-md notification-count ms-2">{badgeText}</Badge>
                                ) : null}
                            </Nav.Link>

                        </Nav.Item>

                        : null
                }
            </>
        );
    };

    return (
        <>
            <Navbar expand={false} collapseOnSelect variant="dark" className="navbar-theme-primary px-4 d-md-none">
                <Navbar.Toggle as={Button} aria-controls="main-navbar" onClick={onCollapse}>
                    <span className="navbar-toggler-icon"/>
                </Navbar.Toggle>
                <Navbar.Brand className="me-lg-5">
                    <Dropdown as={Nav.Item}>
                        <Dropdown.Toggle as={Nav.Link} className="pt-1 px-0">
                            <div className="media d-flex align-items-center">
                                {userProfile.image_url != null ?
                                    <Image src={userProfile.image_url}
                                           className="user-avatar md-avatar rounded-circle"/>
                                    :
                                    <Image src={WebsiteData.default_logo}
                                           className="user-avatar md-avatar rounded-circle"/>
                                }

                                <div className="media-body ms-2 text-dark align-items-center d-none d-lg-block">
                                    <span className="mb-0 font-small fw-bold">{userProfile.name}</span>
                                </div>
                            </div>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="user-dropdown dropdown-menu-right mt-2">
                            <Dropdown.Item className="fw-bold">
                                <Card.Link as={Link} to={Routes.Profile.path} className="text-gray-700">
                                    <FontAwesomeIcon icon={faUserCircle} className="me-2"/> My Profile
                                </Card.Link>
                            </Dropdown.Item>
                            {/*<Dropdown.Item className="fw-bold">*/}
                            {/*    <FontAwesomeIcon icon={faEnvelopeOpen} className="me-2"/> Messages*/}
                            {/*</Dropdown.Item>*/}
                            <Dropdown.Item className="fw-bold">
                                <Card.Link as={Link} to={Routes.ResetPassword.path} className="text-gray-700">
                                    <FontAwesomeIcon icon={faUserShield} className="me-2"/> Change Password
                                </Card.Link>
                            </Dropdown.Item>

                            <Dropdown.Divider/>

                            <Dropdown.Item className="fw-bold" onClick={() => LogoutUser()}>
                                <FontAwesomeIcon icon={faSignOutAlt} className="text-danger me-2"/>
                                Logout
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Navbar.Brand>
            </Navbar>
            <CSSTransition timeout={300} in={show} classNames="sidebar-transition">
                <SimpleBar className={`collapse ${showClass} sidebar d-md-block bg-primary text-white`}>
                    <div className="sidebar-inner px-4 pt-3">
                        <div
                            className="user-card d-flex d-md-none align-items-center justify-content-between justify-content-md-center pb-4">
                            <div className="d-flex align-items-center">
                                <div className="user-avatar xl-avatar me-4">
                                    <Image src={logo} className="card-img-top rounded-circle border-white"/>
                                </div>
                                <div className="d-block mt-2">
                                    <h3 className="text-center font-monospace ">WAKILI</h3>
                                </div>
                            </div>
                            <Nav.Link className="collapse-close d-md-none" onClick={onCollapse}>
                                <FontAwesomeIcon icon={faTimes}/>
                            </Nav.Link>
                        </div>

                        <div>
                            <Row>
                                <div className="d-flex justify-content-center mb-4">
                                    {userProfile.image_url != null ?
                                        <Image src={userProfile.image_url} className="avatar-custom rounded-circle"/>
                                        :
                                        <Image src={WebsiteData.default_logo} className="avatar-custom rounded-circle"/>
                                    }
                                </div>
                                <br/>
                                <Card.Title
                                    className="text-uppercase d-flex justify-content-center text-center">{userProfile.name}</Card.Title>
                            </Row>
                            <Row>
                                {
                                    personalData.length > 0 ?
                                        <div className="text-white">
                                            {personalData.map((index) => (
                                                <div className="text-center">
                                                    <Card.Subtitle className="fw-normal">Member # &nbsp;
                                                        <span
                                                            className="badge bg-warning badge-lg">  <b
                                                            style={{color: 'black'}}>000{index.id}</b></span>
                                                    </Card.Subtitle>
                                                    <Card.Text className="mb-1">{index.Chapter} Chapter</Card.Text>
                                                    <Card.Text className="mb-1">MEMBER</Card.Text>
                                                    <Card.Text className="mb-1">{index.Date_of_Birth}</Card.Text>
                                                </div>
                                            ))}
                                        </div>
                                        :
                                        <div/>
                                }
                            </Row>
                        </div>
                        <hr/>
                        <Nav className="flex-column pt-3 pt-md-0">
                            <NavItem isCheck={isAllowed.includes('Read profile')} title="Dashboard"
                                     link={Routes.DashboardOverview.path} icon={faChartPie}/>
                            <NavItem isCheck={isAllowed.includes('Browse profile')} title="My Profile"
                                     link={Routes.Profile.path} icon={faUser}/>
                            <NavItem isCheck={isAllowed.includes('Browse seminars')} title="Seminars"
                                     link={Routes.seminar.path} icon={faUser}/>
                            <NavItem isCheck={isAllowed.includes('Browse fee')} title="Annual fee"
                                     link={Routes.annualFee.path} icon={faUser}/>
                            {
                                isAllowed.includes('Browse bill') || isAllowed.includes('Browse user') || isAllowed.includes('Browse subscription') || isAllowed.includes('Browse invoice') ?
                                    <CollapsableNavItem eventKey="billing/" title="Payments" icon={faFileAlt}>
                                        <NavItem isCheck={isAllowed.includes('Browse user')} title="Bills"
                                                 link={Routes.BillList.path}/>
                                        <NavItem isCheck={isAllowed.includes('Browse bill')} title="Invoices"
                                                 link={Routes.InvoiceList.path}/>
                                        <NavItem isCheck={isAllowed.includes('Browse bill')} title="Receipts"
                                                 link={Routes.BillingReceipt.path}/>
                                        <NavItem isCheck={isAllowed.includes('Browse user')} title="Cash payment"
                                                 link={Routes.CashPayment.path}/>
                                    </CollapsableNavItem>
                                    : null
                            }

                            {
                                isAllowed.includes('Browse plan') || isAllowed.includes('Browse subscription') ?

                                    <CollapsableNavItem eventKey="billing/" title="Membership" icon={faUser}>
                                        <NavItem isCheck={isAllowed.includes('Browse user')} title="Plans"
                                                 link={Routes.MemberPlan.path}/>
                                        <NavItem isCheck={isAllowed.includes('Browse subscription')}
                                                 title="Subscription" link={Routes.Subscription.path}/>
                                        <NavItem isCheck={isAllowed.includes('Browse user')} title="Approval Request"
                                                 link={Routes.Approval.path}/>
                                    </CollapsableNavItem>
                                    : null
                            }

                            {
                                isAllowed.includes('Add event') || isAllowed.includes('Browse event') ?

                                    <CollapsableNavItem eventKey="settings/" title="Event"
                                                        icon={faCalendarAlt}>
                                        <NavItem isCheck={isAllowed.includes('Add event')} title="Add Events"
                                                 link={Routes.Event.path} icon={faCalendarAlt}/>
                                        <NavItem isCheck={isAllowed.includes('Browse event')} title="View event"
                                                 link={Routes.ListEvent.path} icon={faCalendarAlt}/>
                                        <NavItem isCheck={isAllowed.includes('Browse event')} title="My Events"
                                                 link={Routes.MyEvent.path} icon={faCalendarAlt}/>
                                        <NavItem isCheck={isAllowed.includes('Browse event')} title="Speakers"
                                                 link={Routes.SpeakerList.path} icon={faMicrophone}/>
                                        <NavItem isCheck={isAllowed.includes('Browse event')} title="Assigned"
                                                 link={Routes.SpeakerAssigned.path} icon={faMicrophoneAlt}/>
                                        <NavItem isCheck={isAllowed.includes('Browse event')} title="Invitation"
                                                 link={Routes.invitation.path} icon={faMicrophoneAlt}/>
                                    </CollapsableNavItem>
                                    : null
                            }
                            <NavItem isCheck={isAllowed.includes('Browse user')} title="Claim Points"
                                     link={Routes.Claims.path} icon={faBusinessTime}/>

                            <NavItem isCheck={isAllowed.includes('Browse event')} title="CLE points"
                                     link={Routes.ClePoints.path} icon={faGift}/>
                            {
                                isAllowed.includes('Add user') || isAllowed.includes('Add user') || isAllowed.includes('Add user') ?

                                    <CollapsableNavItem eventKey="users/" title="Users" icon={faUsers}>
                                        <NavItem isCheck={isAllowed.includes('Browse user')} title="View users"
                                                 link={Routes.Staff.path}/>
                                        <NavItem isCheck={isAllowed.includes('Add plan')} title="View user roles"
                                                 link={Routes.Roles.path}/>
                                        {/*<NavItem isCheck={isAllowed.includes('Add plan')} title="Plans"*/}
                                        {/*         link={Routes.Plan.path}/>*/}
                                    </CollapsableNavItem>
                                    : null
                            }
                            <NavItem isCheck={isAllowed.includes('Browse user')} title="Settings"
                                     link={Routes.Setting.path} icon={faCog}/>
                            <Button onClick={() => LogoutUser()} variant="warning" size="sm"
                                    className="upgrade-to-pro"><FontAwesomeIcon icon={faSignOutAlt}
                                                                                className="me-1"/> Logout</Button>

                        </Nav>
                    </div>
                </SimpleBar>
            </CSSTransition>
            {/* <!-- Modal confirm logout --> */}
            <Modal
                show={showModel}
                onHide={handleClose}
            >
                <Modal.Body>
                    Are you sure want to logout ?
                </Modal.Body>
                <Modal.Footer>
                    <Button size="sm" variant="danger" onClick={handleClose}>
                        Close
                    </Button>
                    <AsyncAction
                        action={() => Logout(history)}
                        render={({run}) => (
                            <Button
                                size="sm" variant="success"
                                type="submit"
                                onClick={run}
                            >
                                Accept
                            </Button>
                        )}
                    />
                </Modal.Footer>
            </Modal>
        </>
    );
}

const mapDispatchToProps = {
    Logout, FetchPersonalProfiles
};
export default connect(() => ({}), mapDispatchToProps)(SideBar);


