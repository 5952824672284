import React from "react";
import {
    Container,
    Nav,
    Navbar,
} from '@themesberg/react-bootstrap';

// import NOTIFICATIONS_DATA from "../data/notifications";
// import Profile3 from "../assets/img/zls_default.png";
// import AsyncAction from "../AsyncAction";
import {Logout} from "../store/Login";
import {connect} from "react-redux";
import {useHistory} from "react-router-dom";
import {FetchPersonalProfiles} from "../store/personalProfile";
import NavBarDropdown from "./NavBarDropdown";


function NavBar() {
    // const {auth, Logout, personalProfile} = props
    // console.log(auth);
    const history = useHistory();
    // console.log(history)
    // const [notifications, setNotifications] = useState(NOTIFICATIONS_DATA);
    // const areNotificationsRead = notifications.reduce((acc, notif) => acc && notif.read, true);
    //
    // const markNotificationsAsRead = () => {
    //     setTimeout(() => {
    //         setNotifications(notifications.map(n => ({...n, read: true})));
    //     }, 300);
    // };

    return (
        <>
            <Navbar variant="dark" expanded className="ps-0 pe-2 pb-0">
                <Container fluid className="px-0 d-none d-lg-block">
                    <div className="d-flex justify-content-between w-100">
                        <div className="d-flex align-items-center">
                            {/*<h4 className="text-dark">ZLS MEMBER</h4>*/}
                        </div>
                        <Nav className="align-items-center">
                         <NavBarDropdown/>
                        </Nav>
                    </div>
                </Container>
            </Navbar>

        </>

    );
}

const mapStateToProps = (state) => ({
    auth: state.auth.isLogin,
    personalProfile: state.personalProfile
});
const mapDispatchToProps = {
    Logout, FetchPersonalProfiles
};
export default connect(mapStateToProps, mapDispatchToProps)(NavBar);

