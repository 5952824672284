import Axios from "axios";
import {EDIT_PLAN_DATA, PLAN_DATA} from "./planActionTypes";
import {toast} from "react-toastify";
import {Logout, userToken} from "../Login";
import {FetchEducationProfiles} from "../eductionProfile";
import {FetchTrainingsByUser} from "../MyTraining";

// import {EDIT_PROFILE_DATA} from "../editProfile/EditProfileActionTypes";

export function AddPlan(value) {
    const data = {
        name: value.name,
        plan_id: value.plan_id,
        role_id: value.role_id,
        description: value.description,
        features: value.features,
        price: value.price,
        default: value.default,

    };
    return async (dispatch) =>
        Axios.post(window.$name + '/subscription/plan', data, {
            // headers: {
            //     Authentication: `Bearer ${userToken().accessToken}`,
            // },
        })
            .then(function (response) {
                if (response.status === 201) {
                    toast.success("Plan have been added,successfully");
                    dispatch(FetchPlans())
                }
            })
            .catch(function (error) {
                if (error.response) {
                    toast.error("Something is wrong,Please contact us");
                }
            });
}

export function FetchPlans() {
    return async (dispatch) =>
        Axios.get(window.$name + '/subscription/plan', {})
            .then(function (response) {
                console.log("DATA", response.data)
                dispatch({type: PLAN_DATA, payload: response.data});
                // console.log(response.data);
            })
            .catch(function (error) {
                if (error.response) {
                    toast.error(
                        `"${error.response.data}" Error Status  "${error.response.status}" `
                    );
                } else if (error.request) {
                    toast.error(`"${error.request}" `);
                } else {
                    toast.error(`"${error.message}" `);
                }
            });
}

export function EditPlan(value) {
    // console.log(value)
    const data = {
        id: value.id,
        role_id: value.role_id,
        name: value.name,
        default: value.default,
        description: value.description,
        features: value.features,
        plan_id: value.plan_id,
        price: value.price
    };
    return async (dispatch) =>
        Axios.put(window.$name + `/subscription/plan/detail/${value.id}`, data, {
            headers: {}
        }, {})
            .then(function (response) {
                // console.log(response.data)
                if (response.status === 200) {
                    dispatch(FetchPlans())
                    toast.success("Plan Editing ,successfully");
                    // dispatch({type: EDIT_EDUCATION_PROFILES_DATA, payload: response.data})
                }
            })
            .catch(function (error) {
                if (error.response) {
                    toast.error("Something is wrong,Please contact us");
                }
            });
}

export function DeletePlan(id) {

    return async (dispatch) =>
        Axios.delete(window.$name + `/subscription/plan/detail/${id}`, {
            headers: {},
        }, {})
            .then(function (response) {
                if (response.status === 204) {
                    toast.success("Plan Deleting ,successfully");
                    dispatch(FetchPlans())
                }
            })
            .catch(function (error) {
                if (error.response) {
                    toast.error("Something is wrong,Please contact us");
                }
            });
}


