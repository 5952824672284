import Axios from "axios";
import {WEBSITE_DATA} from "./websiteActionTypes";
import {toast} from "react-toastify";

export function FetchWebsite() {
    return async (dispatch) =>
        Axios.get(window.$name + '/settings/web/', {
        })
            .then(function (response) {
                // console.log(response.data)
                if (response.status === 200) {
                    dispatch({type: WEBSITE_DATA, payload: response.data[0]});
                }
                // console.log(response.data);
            })
            .catch(function (error) {
                if (error.response) {
                    toast.error(
                        `"${error.response.data}" Error Status  "${error.response.status}" `
                    );
                } else if (error.request) {
                    toast.error(`"${error.request}" `);
                } else {
                    toast.error(`"${error.message}" `);
                }
            });
}

export function DeleteWeb(id) {

    return async (dispatch) =>
        Axios.delete(window.$name + `/settings/web/${id}`, {
            headers: {},
        }, {})
            .then(function (response) {
                if (response.status === 204) {
                    toast.success("Website Details Deleted ,successfully");
                    dispatch(FetchWebsite())
                }
            })
            .catch(function (error) {
                if (error.response) {
                    toast.error("Something is wrong,Please contact us");
                }
            });
}