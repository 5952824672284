import React, {useEffect, useState, Fragment} from "react"
import {Breadcrumb, Button, Card, Modal, Table} from "@themesberg/react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faAddressBook,
    faCalendarAlt, faChalkboardTeacher,
    faEdit,
    faHome,
    faPrint,
    faTrain,
    faTrash
} from "@fortawesome/free-solid-svg-icons";
import {connect, useDispatch, useSelector} from "react-redux";
import AsyncAction from "../../../AsyncAction";
import {DeleteTraining, FetchTrainings} from "../../../store/Training";
import EventForm from "./EventForm";
import ReactDatatable from '@ashvin27/react-datatable';
import {FetchEvents} from "../../../store/Events";
import {faLeanpub} from "@fortawesome/free-brands-svg-icons";
import TrainingForm from "./TrainingForm";

function Training(props) {
    const {DeleteTraining} = props
    const dispatch = useDispatch()
    const [Show, setShow] = useState(false);
    const [Value, setId] = useState(0);
    const handleClose = () => setShow(false);
    const handleShow = (value) => {
        if (Show) {
            setShow(false);
        } else {
            setShow(true);
            setId(value)

        }
    };
    useEffect(() => {
        dispatch(FetchTrainings());

    }, [dispatch]);

    const TrainingData = useSelector((state) => state.training);
    const columns = [
        {
            key: "title",
            text: "Title",
            className: "title",
            align: "left",
            sortable: false,
        },
        {
            key: "type",
            text: "Type",
            className: "type",
            align: "left",
            sortable: false
        },
        // {
        //     key: "description",
        //     text: "Description",
        //     className: "description",
        //     align: "left",
        //     sortable: false,
        // },
        {
            key: "location",
            text: "Location",
            className: "location",
            align: "left",
            sortable: false,
        },
        {
            key: "amount",
            text: "Amount",
            className: "amount",
            align: "left",
            sortable: false,
        },
        {
            key: "duration",
            text: "Duration",
            className: "duration",
            align: "left",
            sortable: false,
        },
        {
            key: "training_date",
            text: "Training Date",
            className: "training_date",
            align: "left",
            sortable: false,
        },
        {
            key: "image_url",
            text: "Image",
            className: "image_url",
            sortable: false,
            cell: record => {
                return (
                    <Fragment>
                        <Card.Img src={record.image_url} alt="Neil Portrait" className=" avatar-custom rounded-circle mx-auto"/>
                    </Fragment>
                );
            }
        },
        {
            key: "action",
            text: "Action",
            className: "action",
            width: 100,
            align: "left",
            sortable: false,
            cell: record => {
                return (
                    <Fragment>
                        <Button size="sm" onClick={() => handleShow(record)} variant="outline-primary">
                            <FontAwesomeIcon icon={faEdit} className="icon icon-xs text-dark w-30"/>Edit</Button>
                        &nbsp;&nbsp;
                        <AsyncAction
                            action={() => DeleteTraining(record)}
                            render={({run, loading}) => (
                                <Button
                                    size="sm"
                                    variant="outline-success"
                                    type="submit"
                                    data-toggle="tooltip"
                                    data-placement="right"
                                    title="sign-in"
                                    onClick={run}
                                >
                                    <FontAwesomeIcon icon={faTrash} className="icon icon-xs text-dark w-30"/>
                                    Publish
                                </Button>
                            )}
                        />
                        &nbsp;&nbsp;
                        <AsyncAction
                            action={() => DeleteTraining(record.id)}
                            render={({run, loading}) => (
                                <Button
                                    size="sm"
                                    variant="outline-danger"
                                    type="submit"
                                    data-toggle="tooltip"
                                    data-placement="right"
                                    title="sign-in"
                                    onClick={run}
                                    // disabled={!(formik.isValid && formik.dirty)}
                                > <FontAwesomeIcon icon={faTrash} className="icon icon-xs text-dark w-30"/>
                                    Delete
                                </Button>
                            )}
                        />
                    </Fragment>
                );
            }
        }
    ];
    const config = {
        page_size: 5,
        length_menu: [5,10,20,50,100],
        button: {
            excel: true,
            print: true,
            extra: true,
        }
    }


    const extraButtons = [
        {
            className: "btn btn-primary buttons-pdf",
            title: "Export TEst",
            children: [
                <span>
                    <FontAwesomeIcon icon={faPrint}/>
                    {/*<i className="glyphicon glyphicon-print " aria-hidden="true"></i>*/}
                    </span>
            ],
            onClick: (event) => {
                console.log(event);
            },
        },
        {
            className: "btn btn-primary buttons-pdf",
            title: "Export TEst",
            children: [
                <span>
                   <FontAwesomeIcon icon={faPrint}/>
                    </span>
            ],
            onClick: (event) => {
                console.log(event);
            },
            onDoubleClick: (event) => {
                console.log("doubleClick")
            }
        },
    ]

    return (
        <>
            <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="d-block mb-4 mb-xl-0">
                    <div>
                        <h4><FontAwesomeIcon icon={faLeanpub} className="icon icon-xs text-dark w-30"/>
                            &nbsp;&nbsp;  Zanzibar Law Society Training
                        </h4>
                        <div className="container-fluid">
                            <Button size="sm" variant="outline-dark" onClick={() => handleShow(0)}>
                                Add Training
                            </Button>
                            &nbsp;&nbsp;
                            <Button size="sm" variant="outline-danger">
                                Bulk Delete
                            </Button>
                        </div>
                    </div>
                </div>

            </div>
            <ReactDatatable
                config={config}
                records={TrainingData}
                columns={columns}
                extraButtons={extraButtons}
            />
            {/*model for adding education */}
            <Modal show={Show} onHide={handleClose}>
                <TrainingForm id={Value.id} data={Value} handleShow={handleShow}/>
            </Modal>
            {/*end here modal for adding education*/}
        </>
    )

}

const mapDispatchToProps =
    {
        FetchTrainings, DeleteTraining
    };

export default connect(() => ({}), mapDispatchToProps)(Training);

