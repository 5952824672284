import Axios from "axios";
import {DASHBOARD_DATA} from "./DashboardActionTypes";
import {toast} from "react-toastify";
import {userToken} from "../Login";

export function FetchDashboard() {
    return async (dispatch) =>
        Axios.get(window.$name +  '/dashboard/statistics', {})
            .then(function (response) {
                dispatch({type: DASHBOARD_DATA, payload: response.data});
            })
            .catch(function (error) {
                console.log("statistics")
                // toast.error("Statistics load failed");
            });
}
export function FetchUserDashboard() {
    return async (dispatch) =>
        Axios.post(window.$name +  '/dashboard/user', {
            user_id:userToken().userId
        })
            .then(function (response) {
                dispatch({type: DASHBOARD_DATA, payload: response.data});
            })
            .catch(function (error) {
                console.log("statistics")
                // toast.error("Statistics load failed");
            });
}
