import {EDIT_WORK_DATA, WORK_DATA} from "./workActionTypes";

export default function workReducer(state = [], action) {
    switch (action.type) {
        case WORK_DATA:
            return action.payload;
        case EDIT_WORK_DATA:
            return action.payload;
        default:
            return state;
    }
}
