import React, {useEffect, useState} from "react"
import {Breadcrumb,  Card, Col, Modal, Nav, Row, Tab, Table} from "@themesberg/react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faGlobe,
    faHome, faPager, faReceipt, faServer, faUserCog
} from "@fortawesome/free-solid-svg-icons";
import {connect, useDispatch} from "react-redux";
import {DeletePlan, FetchPlans} from "../../../store/plan/planActions";
import Plan from "./plan";
import WebConfig from "./Website/webConfig";
import ReceiptConfig from "./receipt/receiptConfig";
import CustomEmail from "./Email/CustomEmail";


function SettingIndex(props) {

    const {DeletePlan}=props
    const dispatch=useDispatch()

    return (
        <>
            <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="d-block mb-4 mb-xl-0">
                    <Breadcrumb className="d-none d-md-inline-block"
                                listProps={{className: "breadcrumb-dark breadcrumb-transparent"}}>
                        <Breadcrumb.Item><FontAwesomeIcon icon={faHome}/></Breadcrumb.Item>
                        <Breadcrumb.Item>Home</Breadcrumb.Item>
                        <Breadcrumb.Item active>Settings</Breadcrumb.Item>
                    </Breadcrumb>
                    <div>
                    </div>
                </div>

            </div>

        <Tab.Container defaultActiveKey="plan">
            <Row>
                <Col className="col-12">
                    <Nav className="nav-tabs bg-white">
                        <Nav.Item>
                            <Nav.Link eventKey="plan" className="mb-sm-3 mb-md-0">
                                <FontAwesomeIcon icon={faServer}
                                                 className="me-1"/> Membership Plan
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="receipt" className="mb-sm-3 mb-md-0">
                                <FontAwesomeIcon icon={faReceipt}
                                                 className="me-1"/> Receipt settings
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="website" className="mb-sm-3 mb-md-0">
                                <FontAwesomeIcon icon={faGlobe}
                                                 className="me-1"/> website settings
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="email" className="mb-sm-3 mb-md-0">
                                <FontAwesomeIcon icon={faPager}
                                                 className="me-1"/>Custom Email
                            </Nav.Link>
                        </Nav.Item>
                        {/*<Nav.Item>*/}
                        {/*    <Nav.Link eventKey="work" className="mb-sm-3 mb-md-0">*/}
                        {/*        <FontAwesomeIcon icon={faPager}*/}
                        {/*                         className="me-1"/> website settings*/}
                        {/*    </Nav.Link>*/}
                        {/*</Nav.Item>*/}
                    </Nav>
                </Col>
                <Col className="col-12">
                    <Tab.Content>
                        <Tab.Pane eventKey="plan" className="py-4">
                           <Plan/>
                        </Tab.Pane>
                        <Tab.Pane eventKey="receipt" className="py-4">
                            <ReceiptConfig/>
                        </Tab.Pane>
                        <Tab.Pane eventKey="website" className="py-4">
                            <WebConfig/>
                        </Tab.Pane>
                        <Tab.Pane eventKey='email' className="py-4">
                            <CustomEmail/>
                        </Tab.Pane>
                    </Tab.Content>
                </Col>
            </Row>
        </Tab.Container>
        </>
    )

}

const mapDispatchToProps = {
    FetchPlans,DeletePlan
};

export default connect(() => ({}), mapDispatchToProps)(SettingIndex);

