import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheck, faCheckDouble, faCog, faHome, faSearch} from '@fortawesome/free-solid-svg-icons';
import {
    Col,
    Row,
    Form,
    Button,
    ButtonGroup,
    Breadcrumb,
    InputGroup,
    Dropdown,
    Card,
    Table
} from '@themesberg/react-bootstrap';
import {Logout} from "../../../store/Login";
import {FetchPersonalProfiles} from "../../../store/personalProfile";
import {connect, useDispatch, useSelector} from "react-redux";
import {pageTraffic} from "../../../data/tables";
import {Routes} from "../../../routes";
import {Link} from "react-router-dom";
import {FetchPlans} from "../../../store/plan/planActions";
import {FetchBillByUser} from "../../../store/Bills";
import Grid from "@mui/material/Grid";
import Moment from "moment";
import CurrencyFormat from "react-currency-format";
import {decode as base64_decode} from "base-64";
import {forEach} from "@themesberg/react-bootstrap/lib/cjs/ElementChildren";
import {FetchBillByStatus} from "../../../store/BillReceipt";
import BillTable from "./BillTable";

function UnpaidList(props) {
    const dispatch = useDispatch();
    const {user_id} = props;
    useEffect(() => {
        dispatch(FetchBillByStatus(user_id, 'Not paid'));

    }, [dispatch]);
    
    const billInvoicesData = useSelector((state) => state.billReceipt);

    return (
        <>
            <BillTable BillData={billInvoicesData}/>
        </>
    );
};

const mapDispatchToProps = {
    Logout, FetchPersonalProfiles
};
export default connect(() => ({}), mapDispatchToProps)(UnpaidList);





