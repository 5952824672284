import React, {useState} from "react";
import {Button, Form, Modal} from "@themesberg/react-bootstrap";
import {useFormik} from "formik";
import * as yup from "yup";
import {userToken} from "../../../store/Login";
import Axios from "axios";
import {toast} from "react-toastify";
import {FetchUser} from "../../../store/AccountProfiles";
import {useDispatch} from "react-redux";

const validationSchema = yup.object().shape({
    name: yup.string().required("This field is required"),
    email: yup.string().required("This field is required"),
    // image_url: yup.string().required("This field is required"),
});

export function EditProfileForm(props) {

    const {profileData, handleShowEditForm} = props;
    const dispatch = useDispatch()
    // console.log(profileData)
    //using useFormik
    const formik = useFormik({
            initialValues: {
                name: profileData.name,
                username: profileData.username,
                email: profileData.email,
                id: userToken().userId,
                image_url: profileData.image_url

            }
        },
        validationSchema,
    )
    const [data, setData] = useState({
        image_url: "",
    });

    const handleImageChange = (e) => {
        let newData = {...data};
        newData["image_url"] = e.target.files[0];
        setData(newData);
    };

    function uploadImage() {
        let form_data = new FormData();
        if (data.image_url)
            form_data.append("image_url", data.image_url, data.image_url.name);
        form_data.append("email", formik.values.email);
        form_data.append("username", formik.values.username);
        // form_data.append("image_url", data.image_url);
        form_data.append("name", formik.values.name);

        const Request = Axios.patch(window.$name + `/auth/user/${userToken().userId}`, form_data, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        }).then((res) => {
            console.log(res);
            if (res.status === 200) {

                toast.success("Profile update success fully");
                handleShowEditForm(false);
                dispatch(FetchUser())
            }
            return res;
        }).catch((error) => {
            return error.response;
        });
        return Request;
    }

    return (
        <div>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Full name</Form.Label>
                        <input
                            type="text"
                            className="form-control mb-0"
                            autoComplete="off"
                            id="name"
                            name="name"
                            disabled
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.name && formik.errors.name ? (
                            <div className="text-danger">
                                {formik.errors.name}
                            </div>
                        ) : null
                        }
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Email</Form.Label>
                        <input
                            type="text"
                            className="form-control mb-0"
                            autoComplete="off"
                            id="email"
                            name="email"
                            disabled
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.email && formik.errors.email ? (
                            <div className="text-danger">
                                {formik.errors.email}
                            </div>
                        ) : null
                        }
                    </Form.Group>
                    <Form.Group controlId="formFile" className="mb-3">
                        <Form.Label>My Image</Form.Label>
                        <Form.Control
                            type="file"
                            name="image_url"
                            accept="image/jpeg,image/png,image/gif"
                            onChange={(e) => {
                                handleImageChange(e);
                            }}
                        />

                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button size="sm" variant="outline-dark" onClick={() => handleShowEditForm(false)}>
                    Close
                </Button>
                <Button size="sm" onClick={() => uploadImage()}>
                    Save Changes
                </Button>
            </Modal.Footer>
        </div>
    )
}

